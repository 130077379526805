/* import __COLOCATED_TEMPLATE__ from './currency.hbs'; */
import Component from '@glimmer/component';

export default class TransfersInternationalOutAmounCurrencyFieldCurrencyComponent extends Component {
  get formattedCurrencyName() {
    let { currency, isoCodeOnly } = this.args;
    let { currencyCode, currencyDisplayName } = currency;

    return isoCodeOnly || !currencyDisplayName || currencyCode === currencyDisplayName
      ? currencyCode
      : `${currencyCode} - ${currencyDisplayName}`;
  }
}
