/* import __COLOCATED_TEMPLATE__ from './invoice-number-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { importAsset } from 'qonto/helpers/import-asset';

const NUMBERING_PREVIEW_IMAGES = {
  de: '/illustrations/receivable-invoices/invoice-number-preview-static-de.svg',
  en: '/illustrations/receivable-invoices/invoice-number-preview-static-en.svg',
  es: '/illustrations/receivable-invoices/invoice-number-preview-static-es.svg',
  fr: '/illustrations/receivable-invoices/invoice-number-preview-static-fr.svg',
  it: '/illustrations/receivable-invoices/invoice-number-preview-static-en.svg',
};

export default class InvoiceNumberFormComponent extends Component {
  @service errors;
  @service segment;
  @service localeManager;

  @tracked displayErrorMessage = false;

  constructor() {
    super(...arguments);

    this.oldNumberingPattern = this.config.numberingPattern;
    this.oldNextNumber = this.config.nextNumber;
  }

  get config() {
    return this.args.config;
  }

  get nextInvoiceNumber() {
    let prefix = this.config.numberingPattern.replace(
      /\(YYYY\)|\(AAAA\)|\(JJJJ\)/g,
      new Date().getFullYear()
    );

    return prefix + this.config.nextNumber;
  }

  get previewImageUrl() {
    return importAsset([NUMBERING_PREVIEW_IMAGES[this.localeManager.locale]]);
  }

  @action updateNextNumber(number) {
    this.config.nextNumber = number;
    this.config.nextNumber = number.replace(/[^0-9]/g, '');
  }

  saveAutomaticSettingsTask = dropTask(async () => {
    this.config.numberingMode = 'automatic';

    this.segment.track('invoice_number-activation-screen_numbering-method-chosen', {
      method: 'automatic',
    });

    await this.saveOrganizationSettingsTask.perform();
  });

  saveManualSettingsTask = dropTask(async () => {
    this.config.numberingMode = 'manual';
    this.config.numberingPattern = this.oldNumberingPattern;
    this.config.nextNumber = this.oldNextNumber;

    this.segment.track('invoice_number-activation-screen_numbering-method-chosen', {
      method: 'manual',
    });

    await this.saveOrganizationSettingsTask.perform();
  });

  saveOrganizationSettingsTask = dropTask(async () => {
    await this.config.validate();
    let isNextNumberValid = this.config.validations.attrs.nextNumber.isValid;
    this.displayErrorMessage = !isNextNumberValid;

    if (!isNextNumberValid) {
      return;
    }

    try {
      await this.config.save();
      this.args.transitionToNext?.();
      this.args.save?.();
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}
