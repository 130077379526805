/* import __COLOCATED_TEMPLATE__ from './insight-pill.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from 'design-system-kit';

import { INSIGHTS_ANIMATION_DURATION } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesInsightPill extends Component {
  tag = Tag;

  @service abilities;
  @service intl;

  animationDuration = INSIGHTS_ANIMATION_DURATION;

  get hideMissingAmounts() {
    return this.args.isLoading || !this.args.missingAmounts;
  }

  get missingAmountsMessage() {
    let canUpdate = this.abilities.can('update supplierInvoice');
    let missingAmounts = this.intl.formatNumber(this.args.missingAmounts);

    return canUpdate
      ? this.intl.t('supplier-invoices.cockpit.tooltip-label', {
          count: missingAmounts,
        })
      : this.intl.t('supplier-invoices.cockpit.accountants.tooltip-label', {
          count: missingAmounts,
        });
  }
}
