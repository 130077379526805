/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';

export default class PlaceholderComponent extends Component {
  get showStatusPlaceholder() {
    return (
      [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived].includes(
        this.args.status?.split(',')[0]
      ) && variation('feature-invoices-prioritize-payments')
    );
  }
}
