/* import __COLOCATED_TEMPLATE__ from './document-collection-banner.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

export default class DocumentCollectionBanner extends Component {
  @service intl;

  get isDocumentCollectionCreated() {
    return this.args.documentCollectionProcess.status === 'created';
  }

  get isDocumentCollectionPendingUpload() {
    return this.args.documentCollectionProcess.status === 'pending_upload';
  }

  get isDocumentCollectionPendingReview() {
    return this.args.documentCollectionProcess.status === 'pending_review';
  }

  get isTask1Completed() {
    let { status } = this.args.updateProcess;

    return status === 'pending_review' && this.isDocumentCollectionPendingUpload;
  }

  get texts() {
    let { status } = this.args.updateProcess;

    let commonTexts = {
      header: this.intl.t('periodic-review.document-upload.banner-manual-confirm.header'),
      subtitle: this.intl.t('periodic-review.document-upload.banner-manual-confirm.subtitle'),
      'task-2': this.intl.t('periodic-review.document-upload.banner-manual-confirm.task-2'),
    };

    if (this.isDocumentCollectionPendingUpload) {
      if (status === 'pending_review') {
        return {
          ...commonTexts,
          'task-1': this.intl.t(
            'periodic-review.document-upload.banner-manual-confirm.task-1-complete'
          ),
          cta: this.intl.t('periodic-review.document-upload.banner-manual-confirm.documents-cta'),
        };
      }

      return {
        header: this.intl.t('periodic-review.document-upload.banner-automatic-confirm.title'),
        subtitle: this.intl.t('periodic-review.document-upload.banner-automatic-confirm.subtitle'),
        cta: this.intl.t('periodic-review.document-upload.banner-automatic-confirm.cta'),
      };
    }

    if (status === 'pending_review' && this.isDocumentCollectionPendingReview) {
      return {
        header: this.intl.t('organizations.profile.company_profile.manual-validation.title'),
        subtitle: this.intl.t('organizations.profile.company_profile.manual-validation.subtitle'),
      };
    }

    if ((status === 'created' || status === 'pending_review') && this.isDocumentCollectionCreated) {
      return {
        ...commonTexts,
        'task-1': this.intl.t('periodic-review.document-upload.banner-manual-confirm.task-1'),
        cta: this.intl.t('periodic-review.document-upload.banner-manual-confirm.data-cta'),
      };
    }

    return {};
  }

  get bannerIllustrationSrc() {
    let { status } = this.args.updateProcess;

    if (this.isDocumentCollectionPendingUpload) {
      return '/illustrations/company-profile/upload-document.svg';
    }

    if (status === 'pending_review' && this.isDocumentCollectionPendingReview) {
      return '/illustrations/submitted-info.svg';
    }

    return '/illustrations/settings/kyu-periodic.svg';
  }

  submitTask = task(async () => {
    await this.args.submitTask.unlinked().perform();
  });
}
