/* import __COLOCATED_TEMPLATE__ from './imported.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';

import { Tag } from 'design-system-kit';
import { task } from 'ember-concurrency';

import { SENTRY_IGNORE_HTTP_STATUSES } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

import ReceivableInvoicesSidebarSharedComponent from './sidebar-shared';

export default class ReceivableInvoicesInvoiceModalSidebarImportedComponent extends ReceivableInvoicesSidebarSharedComponent {
  tag = Tag;

  @service modals;
  @service router;
  @service segment;

  get isNegativeAmount() {
    return this.amount?.startsWith('-');
  }

  updateValue(value) {
    let numericValue = parseFloat(value.toString().replace('-', ''));
    if (numericValue > 0) {
      return this.isNegativeAmount ? value.slice(1) : `-${value}`;
    }
    return value;
  }

  updateAmountTask = task(async () => {
    let updatedAmounts = {
      amount_due: this.updateValue(this.amount),
      subtotal: this.updateValue(this.invoicingDocument.subtotal),
      vat_amount: this.updateValue(this.invoicingDocument.vatAmount),
    };

    let toastMessage = this.isNegativeAmount
      ? this.intl.t('receivable-invoices.import.edit.modal.mark-as-invoice.success')
      : this.intl.t('receivable-invoices.import.edit.modal.mark-as-credit-note.success');

    if (this.isNegativeAmount) {
      this.segment.track('invoice_imported_mark-as-invoice_clicked');
    } else {
      this.segment.track('invoice_imported_mark-as-credit-note_clicked');
    }
    try {
      await this.invoicingDocument.updateImportedInvoiceAmount(updatedAmounts);
      this.toastFlashMessages.toastSuccess(toastMessage);
    } catch (error) {
      this.handleError(error);
    }
  });

  get amount() {
    return this.invoicingDocument.amountDue;
  }

  get shouldDisplayMarkAsButtons() {
    return this.invoicingDocument.status !== 'paid' && Boolean(Number(this.amount));
  }

  @action openDeleteModal() {
    this.segment.track('invoice_imported_delete_clicked');
    this.modals.open(
      'receivable-invoices/delete-imported-invoice-modal',
      {
        title: this.intl.t('receivable-invoices.delete-modal.title'),
        description: this.intl.t('receivable-invoices.delete-modal.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.delete-modal.cta.delete'),
        confirmTask: this.deleteInvoiceTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  }

  @action
  redirectToReviewDuplicatesModal(event) {
    event.preventDefault();
    this.modal = this.modals.open('receivable-invoices/review-duplicates-modal', {
      invoiceId: this.invoicingDocument.id,
      isFullScreenModal: true,
    });
    this.segment.track('invoice_imported_review-invoices_clicked');
  }

  deleteInvoiceTask = task(async closeModal => {
    try {
      await this.invoicingDocument.destroyRecord();
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
  }
}
