/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight, ProgressBar } from 'design-system-kit';
import { reads } from 'macro-decorators';

const GRID_ITEMS = 6;

const GRID_ROWS = GRID_ITEMS / 2;

export default class RemuneratedAccountMainComponent extends Component {
  progressBar = ProgressBar;

  badgeHighlight = BadgeHighlight;

  @service intl;

  @reads('args.accountDetails') accountDetails;
  @reads('args.accountName') accountName;

  get isGermanCompany() {
    return this.accountDetails.isTaxApplicable;
  }

  get tableData() {
    // We are interested only in the first 6 interest rates array elements for the table display
    let selectedInterestRates = this.accountDetails.interestRates.slice(0, GRID_ITEMS);
    return selectedInterestRates.reduce(
      // We divide the array since row will have left and right elements in such a way that we will have 3 symmetric rows
      (result, current, index, array) => {
        if (index < GRID_ROWS) {
          // Create an object with 'left' and 'right' properties containing the current and next elements
          let left = { ...current };
          let right = { ...array[index + GRID_ROWS] };
          left.rate = this.formatRate(left.rate);
          right.rate = this.formatRate(right.rate);
          result.push({
            left,
            right,
          });
        }
        return result;
      },
      []
    );
  }

  formatRate(rate) {
    return this.intl.formatNumber(rate / 100, {
      minimumFractionDigits: parseFloat(rate) === 0 ? 0 : 2,
      style: 'percent',
    });
  }

  get maxMaturityEarnings() {
    let { value, currency } = this.accountDetails.maxMaturityEarnings;
    return {
      value: parseFloat(value),
      currency,
    };
  }
  get maxMaturityRate() {
    return this.accountDetails.interestRates.at(-1).rate / 100;
  }

  get startDate() {
    return this.accountDetails.startDate;
  }

  get endDate() {
    return this.accountDetails.endDate;
  }

  get maxMaturity() {
    return this.accountDetails.maxMaturity;
  }

  get currentMaturity() {
    return this.accountDetails.currentMaturity;
  }
}
