/* import __COLOCATED_TEMPLATE__ from './matched-section.hbs'; */
import Component from '@glimmer/component';

export default class SupplierInvoicesDetailsSidebarMatchedSectionComponent extends Component {
  filterGroup = {
    conditional: 'and',
    expressions: [
      {
        property: 'attachment_ids',
        values: [this.args.invoice.attachment.id],
        operator: 'in',
      },
    ],
  };
}
