/* import __COLOCATED_TEMPLATE__ from './rule-trigger-field.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class RuleTriggerFieldComponent extends Component {
  @service intl;

  @tracked selectedOperator = this.triggerOptions.find(
    option => this.args.rule.operator === option.value
  );

  get triggerOptions() {
    return [
      {
        value: 'on',
        label: this.intl.t('payment-reminders.form.timing.option.on'),
        clearable: false,
      },
      {
        value: 'before',
        label: this.intl.t('payment-reminders.form.timing.option.before'),
        clearable: true,
      },
      {
        value: 'after',
        label: this.intl.t('payment-reminders.form.timing.option.after'),
        clearable: true,
      },
    ];
  }

  @action
  updateTrigger(option) {
    let prevOption = this.args.rule.operator;

    this.args.rule.operator = option.value;
    this.selectedOperator = option;
    this.args.rule.offsetDays = null;

    if (this.args.onUpdateTrigger) {
      this.args.onUpdateTrigger(prevOption, option.value);
    }
  }

  @action
  updateTriggerOffsetDays(offsetDays) {
    this.args.rule.offsetDays = offsetDays;
  }
}
