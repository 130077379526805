/* import __COLOCATED_TEMPLATE__ from './payment-purpose.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PAYMENT_PURPOSE_TYPES } from 'qonto/constants/transfers';
import { getPaymentPurposeLabel } from 'qonto/utils/transfers';

export default class TransfersFxPaymentPurposeComponent extends Component {
  @service intl;

  @tracked paymentPurposeOptions = [];

  constructor() {
    super(...arguments);

    if (this.args.paymentPurposeCodes && this.isStructured) {
      this.paymentPurposeOptions = this.args.paymentPurposeCodes.map(code => ({
        code,
        label: getPaymentPurposeLabel({
          code,
          country: this.args.country,
          currency: this.args.currency,
          intlService: this.intl,
        }),
      }));
    }
  }

  get isStructured() {
    return this.args.paymentPurposeType === PAYMENT_PURPOSE_TYPES.STRUCTURED;
  }

  get isUnstructured() {
    return this.args.paymentPurposeType === PAYMENT_PURPOSE_TYPES.UNSTRUCTURED;
  }

  get selectedPaymentPurpose() {
    return this.paymentPurposeOptions.find(option => option.code === this.args.paymentPurpose);
  }
}
