export default {
  "subtitle": "cbT",
  "green-text": "cbz",
  "balance-subtitle": "cba",
  "options": "cbH",
  "shadow": "cbO",
  "menu-item": "cbm body-2",
  "close-account": "cbp",
  "footer": "cbx",
  "progress": "cbw",
  "progress-bar": "cbN",
  "progress-bar-text": "cbv",
  "progress-active": "cbo"
};
