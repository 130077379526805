/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { Tag } from 'design-system-kit';

import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';

export default class TransfersRequestTableRequesterRowComponent extends Component {
  tag = Tag;

  get status() {
    let { status, displayedStatus } = this.args.item;

    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status],
    };
  }

  get requestComponentName() {
    let type = dasherize(this.args.item.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }
}
