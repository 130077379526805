export default {
  "form": "qIs",
  "form-section": "qIF",
  "form-title": "qIg title2",
  "divider": "qID",
  "row": "qIV",
  "col": "qIL",
  "actions": "qIT",
  "buttons": "qIz"
};
