export default {
  "header-cell": "qFw",
  "header-content": "qFN caption-bold",
  "active": "qFv",
  "align-right": "qFo",
  "empty": "qFn",
  "align-checkbox": "qFi",
  "col-5": "qFA",
  "col-9": "qFY",
  "emitted-at-header-tag": "qFh"
};
