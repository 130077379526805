/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { calculateVatAmount } from 'qonto/utils/transfers';

export default class TransfersSepaDetailsComponent extends Component {
  @service featuresManager;

  operationTypes = Object.values(SCHEDULE_OPERATION_TYPES);

  get organization() {
    return this.args.sepaTransfer.get('organization');
  }

  @action
  handleFocusOut() {
    this._updateVatAmount();
  }

  @action
  preventScroll(event) {
    event.preventDefault();
  }

  _updateVatAmount() {
    let { sepaTransfer } = this.args;

    if (!sepaTransfer) return;

    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      this.args.sepaTransfer.vatAmount = calculateVatAmount(amount, vatRate);
    }
  }
}
