export default {
  "modal": "qXW",
  "section": "qXu",
  "header": "qCS",
  "title": "qCc",
  "separator-title": "qCq",
  "icon": "qCZ",
  "formats": "qCR",
  "columns": "qCQ",
  "column-list": "qCe",
  "draggable-column": "qCB",
  "column-field": "qCE",
  "column-selector": "qCd",
  "column-with-error": "qCI",
  "handle": "qCt",
  "label-color": "qCM",
  "disclaimer": "qCP"
};
