export default {
  "wrapper": "cAL",
  "tabs": "cAT",
  "tabs-nav": "cAz",
  "tabs-panel": "cAa",
  "tabs-panel-wrapper": "cAH",
  "list-head": "cAO",
  "list": "cAm",
  "footer": "cAp",
  "reward-amount": "cAx",
  "rewarded": "cAw",
  "registered": "cAN",
  "disclaimer": "cAv",
  "empty-state-wrapper": "cAo",
  "empty-state": "cAn",
  "illustration": "cAi",
  "spinner": "cAA",
  "total-earned": "cAY"
};
