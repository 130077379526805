export default {
  "row": "cgj",
  "terminated": "cgW",
  "status-avatar": "cgu",
  "amount": "cDS body-1",
  "item-info": "cDc",
  "date-cell": "cDq",
  "cell": "cDZ body-2",
  "status-icon": "cDR",
  "amount-cell": "cDQ",
  "item-name-cell": "cDe",
  "item-name-container": "cDB"
};
