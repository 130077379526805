export default {
  "account-description": "cHJ",
  "account-identification": "cHs",
  "account-avatar": "cHF",
  "account-name": "cHg",
  "account-balance": "cHD body-2",
  "status": "cHV",
  "external-accounts-disclaimer": "cHL",
  "failed-connection": "cHT"
};
