/* import __COLOCATED_TEMPLATE__ from './amount-currency-field.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { groupCurrencies } from 'qonto/utils/currency';

export default class TransfersInternationalOutAmountCurrencyComponent extends Component {
  @service intl;

  get currencies() {
    let allCurrencies = this.args.currencies;
    let [popularCurrencies, otherCurrencies] = groupCurrencies(allCurrencies);

    return popularCurrencies.length
      ? [
          {
            groupName: this.intl.t('international-out.quote.currencies.popular-label'),
            options: popularCurrencies,
          },
          {
            groupName: this.intl.t('international-out.quote.currencies.other-label'),
            options: otherCurrencies,
          },
        ]
      : allCurrencies;
  }

  get hasMultipleCurrencies() {
    return this.args.currencies.length > 1;
  }

  get selectedCurrency() {
    return this.args.currencies.find(({ currencyCode }) => currencyCode === this.args.currency);
  }

  get selectedCurrencyCode() {
    return this.args.currency ? this.selectedCurrency.currencyCode : '';
  }

  @action
  onCurrencyUpdate({ currencyCode }) {
    this.args.onCurrencyUpdate(currencyCode);
  }
}
