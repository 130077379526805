export default {
  "request-expense-report-validation": "cWS",
  "account-selector": "cWc",
  "has-error": "cWq",
  "actions": "cWZ",
  "action-btn": "cWR",
  "action-btn-success": "cWQ",
  "warnings": "cWe",
  "top-border": "cWB",
  "account-selector-subtitle": "cWE",
  "action-btn-right": "cWd",
  "funds-disclaimer": "cWI",
  "account-options": "cWt",
  "account-balance": "cWM"
};
