export default {
  "wrapper": "qgo",
  "title": "qgn",
  "activity-tag": "qgi",
  "select": "qgA",
  "tooltip": "qgY",
  "date-picker": "qgh",
  "internal-notes": "qgf",
  "scheduled": "qgK"
};
