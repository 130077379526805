export default {
  "iban": "qqE",
  "checkbox": "qqd",
  "toggle-content": "qqI",
  "beneficiary-name": "qqt",
  "category": "qqM",
  "beneficiary-email": "qqP",
  "beneficiary-form": "qql",
  "beneficiary-email-container": "qqX"
};
