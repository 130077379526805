export default {
  "header": "qdR",
  "subtitle": "qdQ body-2",
  "date": "qde",
  "subtitle-error": "qdB",
  "card": "qdE",
  "card-header": "qdd",
  "line-placeholder": "qdI",
  "post-closing-list": "qdt",
  "post-closing-container": "qdM",
  "post-closing-list-element": "qdP",
  "free-trial-disclaimer": "qdl",
  "description": "qdX body-2",
  "mw-100": "qdC"
};
