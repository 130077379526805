/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { MileageTableCell } from 'qonto/react/components/mileage/table/cell';

export default class ReimbursementsTableItemPendingComponent extends Component {
  mileageTableCell = MileageTableCell;
  spinner = Spinner;

  @service intl;
  @service organizationManager;
  @service segment;
  @service modals;
  @service attachmentsManager;
  @service notifierCounterManager;

  get requestAmount() {
    let { request } = this.args;
    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
    }
  }

  get mainAccount() {
    return this.args.request.bankAccount ?? this.organizationManager.currentAccount;
  }

  get rejectTooltipText() {
    return this.isRequestedByCurrentUser
      ? this.intl.t('reimbursements.tooltip.cancel')
      : this.intl.t('reimbursements.tooltip.reject');
  }

  get isRequestedByCurrentUser() {
    let currentUserId = this.organizationManager.membership.id;
    let initiatorId = this.args.request.get?.('initiator.id') || this.args.request.initiator.id;

    return currentUserId === initiatorId;
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  onBankAccountSelect(account) {
    this.args.onBankAccountSelect(this.args.request, account);
  }

  @action
  onBankAccountSelectClick(event) {
    this.segment.track('bank_account_picker_opened', {
      origin: 'reimbursements',
    });

    event.stopPropagation();
  }

  @action
  onRowClick(id) {
    if (this.onRequestApprove.isRunning || this.onRequestReject.isRunning) {
      return;
    }

    this.args.highlightItem(id);
  }

  onRequestReject = dropTask(async () => {
    await this.args.onRequestReject(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  onRequestApprove = dropTask(async () => {
    if (!this.args.request.bankAccount) {
      this.args.request.bankAccount = this.mainAccount;
    }
    await this.args.onRequestApprove(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  handleOpenFilePreviewModal = dropTask(async event => {
    event.stopPropagation();
    await this.args.request.get('attachments');

    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: this.args.request.attachmentsFiles[0],
      model: this.args.request,
      download: this.attachmentsManager.downloadAttachment,
    });
  });

  get showAttachment() {
    return this.args.request.attachmentIds?.length > 0;
  }
}
