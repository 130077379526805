export default {
  "container": "ciP",
  "content": "cil",
  "input-row": "ciX",
  "email-row": "ciC",
  "button-row": "cik",
  "btn-edit": "ciJ",
  "input-column": "cis",
  "form-btn-row": "ciF",
  "border-bottom": "cig",
  "avatar-input": "ciD"
};
