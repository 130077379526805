export default {
  "section-container": "cWP",
  "disclaimer-section": "cWl",
  "trip-title": "cWX caption-bold mb-16",
  "google-link-container": "cWC",
  "link-icon": "cWk",
  "trip-details": "cWJ body-2",
  "trip-details-icon": "cWs",
  "trip-details-title": "cWF",
  "subtitle": "cWg caption-bold mb-16",
  "disclaimer": "cWD",
  "visualizer": "cWV"
};
