export default {
  "transfer-header": "qFI",
  "infobox": "qFt header-section",
  "proof-section": "qFM",
  "proof-link": "qFP",
  "download-icon": "qFl",
  "cancel-button": "qFX",
  "request-refund-button": "qFC",
  "refund-requested-indicator": "qFk",
  "recall-indicator": "qFJ",
  "payment-purpose": "qFs",
  "payment-purpose-label": "qFF",
  "repeat-infobox": "qFg qFt header-section",
  "additional-infobox": "qFD qFt header-section",
  "repeat-transfer-action": "qFV"
};
