export default {
  "credit-note-sidebar": "cxT",
  "body": "cxz",
  "box": "cxa",
  "box-header": "cxH",
  "footer": "cxO",
  "footer-button": "cxm",
  "sidebar-box": "cxp",
  "border-top": "cxx",
  "row": "cxw",
  "related-invoice-title": "cxN caption-bold",
  "einvoice-rejected": "cxv"
};
