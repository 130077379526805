/* import __COLOCATED_TEMPLATE__ from './warning-membership.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class PopupSecurityUpdateEmailWarningMembershipComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service modals;
  @service userManager;
  @service sentry;

  activateTask = dropTask(async () => {
    let { close } = this.args;
    let { userHasPassword, requestOTPTask } = this.args.data;
    let { pendingEmail } = this.userManager.currentUser;

    try {
      await requestOTPTask.perform(pendingEmail, true);
    } catch (error) {
      close();
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }

      return;
    }

    close();
    await this.modals.open('popup/security/email-verification', {
      userHasPassword,
      newEmailAddress: pendingEmail,
    });
  });
}
