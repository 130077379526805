/* import __COLOCATED_TEMPLATE__ from './budget.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';
import { TrackedWeakMap } from 'tracked-built-ins';

import { NO_PERIOD_ID } from 'qonto/constants/budget';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class TransactionsSidebarDetailsBudgetComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service sentry;
  @service subscriptionManager;

  @tracked _selectedOption = new TrackedWeakMap();

  get options() {
    return this.args.periodOptionsTaskInstance.value?.options;
  }

  get selected() {
    return this.selectedOption?.name;
  }

  get selectedOption() {
    if (this._selectedOption.has(this.args.periodOptionsTaskInstance)) {
      return this._selectedOption.get(this.args.periodOptionsTaskInstance);
    } else {
      return this.args.periodOptionsTaskInstance.value?.selectedOption;
    }
  }

  get subscriptionStatus() {
    return this.subscriptionManager.currentSubscription.status;
  }

  onOptionSelectTask = restartableTask(async option => {
    let oldSelectedOption = this._selectedOption.get(this.args.periodOptionsTaskInstance);
    this._selectedOption.set(this.args.periodOptionsTaskInstance, option);
    try {
      await this.args.onPeriodSelection?.(option);
      this.segment.track(
        option.id === NO_PERIOD_ID
          ? 'budget-allocation_no-budget_selected'
          : 'budget-allocation_budget_selected'
      );
    } catch (error) {
      this._selectedOption.set(this.args.periodOptionsTaskInstance, oldSelectedOption);
      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
