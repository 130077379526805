export default {
  "row": "cfA body-1",
  "active": "cfY",
  "cell": "cfh",
  "empty": "cff",
  "ellipsis": "cfK",
  "text-secondary": "cfG",
  "transfer-type": "cfr",
  "transfer-type__icon": "cfb",
  "quick-actions": "cfy",
  "amount": "cfU",
  "cell-approve": "cfj",
  "account-select": "cfW",
  "row-compact": "cfu",
  "cell-amount": "cKS",
  "cell-content-receipt": "cKc body-1",
  "cell-date": "cKq",
  "row-sidebar": "cKZ",
  "hidden": "cKR",
  "animated": "cKQ",
  "fadein-item": "cKe",
  "animated-cell": "cKB",
  "note": "cKE"
};
