/* import __COLOCATED_TEMPLATE__ from './check.hbs'; */
import Component from '@glimmer/component';

import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import cmc7Splitter from 'qonto/utils/cmc7-splitter';

export default class TransactionsSidebarHeaderCheck extends Component {
  Date = Date;

  get checkCmc7() {
    return cmc7Splitter(this.args.highlightedItem.subject.get('cmc7'), ' ');
  }
}
