export default {
  "header": "cjF",
  "header-type": "cjg",
  "close": "cjD",
  "close-icon": "cjV",
  "header-infos": "cjL",
  "title": "cjT body-1",
  "declined": "cjz",
  "canceled": "cja",
  "approved": "cjH",
  "pending": "cjO",
  "date": "cjm body-2",
  "container": "cjp",
  "picto": "cjx",
  "picto-status": "cjw",
  "general": "cjN",
  "amount": "cjv",
  "counterparty": "cjo body-2",
  "initiator": "cjn",
  "initiator-avatar": "cji",
  "avatar-image": "cjA",
  "initiator-id": "cjY",
  "name": "cjh body-2",
  "role": "cjf caption-bold",
  "infobox": "cjK",
  "account-infobox": "cjG cjK",
  "beneficiary-warning": "cjr caption-bold",
  "dates-wrapper": "cjb",
  "dates": "cjy",
  "recurrence": "cjU caption-bold",
  "arrow": "cjj",
  "notify-checkbox": "cjW"
};
