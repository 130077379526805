/* import __COLOCATED_TEMPLATE__ from './theme-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import APPEARANCE from 'qonto/constants/appearance-setting';

export default class ThemeSelector extends Component {
  @tracked groupValue = this.args.currentAppearance;

  APPEARANCE = APPEARANCE;

  @action
  onCheck(value) {
    this.groupValue = value;
    this.args.setAppearance(value);
  }
}
