/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { LottiePlayer } from 'design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import fuzzysort from 'fuzzysort';

import { initializeQuickActions, modifierKey } from 'qonto/constants/qonto-pilot';
import { DISCLAIMER_TYPES } from 'qonto/constants/transfers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { isMac } from 'qonto/utils/is-mac';

import { TRANSFER_FLOW_ORIGIN } from '../../constants/transfers';
import parseConfirmResponse from '../../utils/parse-confirm-response';

const QPILOT_CREATE_TRANSFER_STEPS = {
  QUICK_ACTIONS: 'quick_actions', // the first step
  PROMPT: 'prompt', // the step where we ask the user to insert the prompt
  PROMPT_EVALUATION: 'prompt_evaluation', // when the user submit the prompt and the LLM tries to parse it
  TRANSFER_DETAILS: 'transfer_details', // when a transfer model is created and is ready for the user review and submission
  TRANSFER_CREATION: 'transfer_creation', // when the "confirm" of the transfer is in progress
  TRANSFER_CREATED: 'transfer_created', // when the "confirm" of the transfer is completed and the transfer is created
};

const ERROR_MESSAGES = {
  [DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS]: 'transfers.warnings.insufficient_funds',
};

export default class QontoPilotComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service qontoPilotService;
  @service router;
  @service sentry;
  @service segment;
  @service organizationManager;
  @service beneficiariesManager;
  @service userManager;
  @service intl;
  @service sensitiveActions;
  @service flowLinkManager;
  @service store;
  @service toastFlashMessages;

  @tracked userInput;
  @tracked transfer;
  @tracked errorMessage;
  @tracked quickActions;

  steps = QPILOT_CREATE_TRANSFER_STEPS;
  @tracked currentStep = this.args.data.action;

  mentionTrigger = '@';

  defaultQuickActions = initializeQuickActions(getOwner(this));

  modifierKey = modifierKey(isMac());

  get feedbackUrl() {
    let { currentUser } = this.userManager;

    return this.intl.t('qonto-pilot.modal.feedback', {
      userid: currentUser?.id,
      email: currentUser?.email,
    });
  }

  get flattenedQuickActions() {
    return Object.values(this.quickActions).flat();
  }

  @action
  handleActionClick(quickAction) {
    this.segment.track('qontopilot_quick-action_selected', {
      quickAction: quickAction.key,
    });
    this.qontoPilotService.addRecentQuickAction(quickAction.key);
    if (quickAction.key === 'task-sepa') {
      this.handleInstantSepaTransferActionClick();
      return;
    }
    if (quickAction.isEnabled && typeof quickAction.action === 'function') {
      this.close();
      quickAction.action();
      document.querySelector('#mentionable-input')?.focus();
    }
  }

  @action
  handleInstantSepaTransferActionClick() {
    next(this, () => {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
    });
  }

  @action
  close({ cleanCache = true } = {}) {
    if (this.submitPromptTask.isRunning) {
      return;
    }
    this.removeConfirmEvent();
    if (cleanCache) {
      this.store.unloadRecord(this.transfer);
    }
    this.args.close();
  }

  @action
  closeModalAndNavigateToTransfer() {
    this.router.transitionTo('transfers.past', {
      queryParams: { highlight: this.transfer.id },
    });
    this.close();
  }

  @action
  handleEdit() {
    this.segment.track('qontopilot_transfer_edit-details');
    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'details',
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.QONTO_PILOT,
      },
    });
    this.close({ cleanCache: false });
  }

  constructor() {
    super(...arguments);
    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);
    this.quickActions = this.defaultQuickActions;
  }

  get beneficiaries() {
    return this.store.peekAll('beneficiary');
  }

  loadBeneficiariesTask = task(async () => {
    try {
      await this._loadBeneficiaries();
    } catch (error) {
      this.sentry.captureException(
        new Error(`Beneficiary load triggered an error`, { cause: error })
      );
    }
  });

  _loadBeneficiaries() {
    let organizationId = this.organizationManager.organization.id;
    return this.beneficiariesManager.loadSepaBeneficiaries(organizationId);
  }

  @action
  onKeyDown(event) {
    this.errorMessage = null;
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      event.preventDefault();
      let delta = event.key === 'ArrowDown' ? 1 : -1;
      let flattenedActions = [];
      let groupIndexes = [];
      let groupStartIndexes = {};
      let totalLength = 0;
      let currentIndex = -1;

      Object.keys(this.quickActions).forEach((group, groupIndex) => {
        let groupActions = this.quickActions[group];
        groupStartIndexes[groupIndex] = totalLength;
        totalLength += groupActions.length;

        groupActions.forEach(action => {
          flattenedActions.push(action);
          groupIndexes.push(groupIndex);
          if (action.isFocused) {
            currentIndex = flattenedActions.length - 1;
          }
        });
      });

      let newIndex = currentIndex + delta;

      if (newIndex < 0 || newIndex >= flattenedActions.length) {
        return;
      }

      newIndex = (newIndex + flattenedActions.length) % flattenedActions.length;

      let newGroupIndex = groupIndexes[newIndex];
      let newActionIndex = newIndex - groupStartIndexes[newGroupIndex];

      let updatedQuickActions = {};
      Object.keys(this.quickActions).forEach((group, groupIndex) => {
        updatedQuickActions[group] = this.quickActions[group].map((action, actionIndex) => ({
          ...action,
          isFocused: groupIndex === newGroupIndex && actionIndex === newActionIndex,
        }));
      });

      this.quickActions = updatedQuickActions;
    }
  }

  @action
  onInputChange(event) {
    this.userInput = event.target.value;
    if (this.userInput.length === 0) {
      this.quickActions = this.defaultQuickActions;
      return;
    }

    Object.keys(this.defaultQuickActions).forEach(key => {
      let results = fuzzysort.go(this.userInput, this.defaultQuickActions[key], { key: 'copy' });
      let updatedActions = results.map(result => ({ ...result.obj }));
      this.quickActions = {
        ...this.quickActions,
        [key]: updatedActions,
      };
    });
  }

  @action
  onSubmit(value) {
    this.submitPromptTask.perform(value).catch(ignoreCancelation);
  }

  submitPromptTask = dropTask(async value => {
    try {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT_EVALUATION;
      let result = await this.qontoPilotService.processUserInput(value);
      if (typeof result === 'string' || result === null) {
        this.errorMessage = result || this.intl.t('qonto-pilot.modal.error.wrong-prompt');
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
      } else {
        this.segment.track('qontopilot_transfer-request_made', {
          amount: result.amount,
          beneficiary: result.beneficiary.get('name'),
        });
        this.errorMessage = null;
        this.transfer = result;
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
        document.activeElement.blur();
        this.setConfirmEvent();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  confirmTransferTask = dropTask(async () => {
    await this.sensitiveActions.runTask.perform(this.confirmAndSaveTransfer, this.transfer);
  });

  confirmAndSaveTransfer = dropTask(async transfer => {
    this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATION;
    try {
      let confirmationResult = await transfer.confirm();
      if (confirmationResult.errors.length === 0) {
        this.transfer = await transfer.save();
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED;
        this.segment.track('qontopilot_transfer_confirmed', {
          amount: transfer.amount,
          beneficiary: transfer.beneficiary.get('name'),
        });
      } else {
        let confirmResponseErrors = parseConfirmResponse(confirmationResult, this.intl);
        this.#displayConfirmErrors(confirmResponseErrors.confirmErrors);
      }
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
      }
    } finally {
      transfer.removeIdempotencyHeader();
    }
  });

  @action
  confirmEvent(event) {
    let tagName = document.activeElement.tagName.toLowerCase();
    let isEditable = document.activeElement.isContentEditable;

    if (event.key === 'Enter' && tagName !== 'input' && tagName !== 'textarea' && !isEditable) {
      if (this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED) {
        this.closeModalAndNavigateToTransfer();
        return;
      }
      this.confirmTransferTask.perform().catch(ignoreCancelation);
    }
  }

  setConfirmEvent() {
    this.removeConfirmEvent();
    document.addEventListener('keydown', this.confirmEvent);
  }

  removeConfirmEvent() {
    document.removeEventListener('keydown', this.confirmEvent);
  }

  #displayConfirmErrors(confirmErrors) {
    let errorsToDisplay = confirmErrors.map(errorCode => ERROR_MESSAGES[errorCode]);

    errorsToDisplay.forEach(errorMessage => {
      this.toastFlashMessages.toastError(this.intl.t(errorMessage));
    });

    this.close({ cleanCache: true });
  }

  get displayPromptLoading() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT_EVALUATION;
  }

  get displayTransferDetails() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
  }

  get displayButtons() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
  }

  get displayMFA() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATION;
  }

  get displaySuccessScreen() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED;
  }

  get displaySelectKeyControl() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.QUICK_ACTIONS;
  }

  get displayQuickActions() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.QUICK_ACTIONS;
  }

  get displayPromptInput() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT && !this.displaySuccessScreen;
  }

  get displayBeneficiary() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
  }
}
