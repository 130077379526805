/* import __COLOCATED_TEMPLATE__ from './de-freelancers-flex-kyb.hbs'; */
import Component from '@glimmer/component';

import splitText from 'qonto/utils/split-text';

export default class DeFreelancersFlexTopbarComponent extends Component {
  get splitMessage() {
    let text = this.args.content.message;
    return splitText(text);
  }

  get content() {
    return {
      type: this.args.content.type,
      message: null,
    };
  }
}
