export default {
  "payment-details": "cvq",
  "label-payment-conditions": "cvZ",
  "dropdown-content": "cvR",
  "dropdown-option": "cvQ",
  "activate-sdd-container": "cve",
  "activate-sdd-header": "cvB",
  "selector-toggle-sdd": "cvE",
  "selector-toggle": "cvd",
  "new-badge": "cvI",
  "new-badge-activation": "cvt"
};
