/* import __COLOCATED_TEMPLATE__ from './pagopa-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class TransactionsSidebarPagopaDetailsComponent extends Component {
  @service modals;
  @service intl;

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.attachmentModal && !this.attachmentModal.result) {
      this.attachmentModal.close();
    }
  }

  get modalTitle() {
    return this.intl.t('transactions.sidebar.bookkeeping.bookkeeping-details');
  }

  @action
  handleShowAttachment(selectedFile) {
    this.attachmentModal = this.modals.open(
      'attachments/attachment-viewer-modal',
      {
        isFullScreenModal: true,
        hasSidebar: true,
        title: this.modalTitle,
        transaction: this.args.transaction,
        selectedFile,
        onSaveLabel: (...args) => this.args.saveLabel(...args),
        onSelectTag: (...args) => this.args.selectTag(...args),
        onSave: (...args) => this.args.saveTransaction(...args),
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }
}
