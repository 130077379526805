export default {
  "row": "qTZ body-1",
  "active": "qTR",
  "cell": "qTQ",
  "empty": "qTe",
  "ellipsis": "qTB",
  "text-secondary": "qTE",
  "transfer-type": "qTd",
  "transfer-type__icon": "qTI",
  "quick-actions": "qTt",
  "amount": "qTM",
  "cell-approve": "qTP",
  "cell-type": "qTl",
  "cell-requester": "qTX",
  "account-select": "qTC",
  "row-compact": "qTk",
  "row-sidebar": "qTJ",
  "cell-amount": "qTs",
  "hidden": "qTF",
  "animated": "qTg",
  "fadein-item": "qTD",
  "animated-cell": "qTV"
};
