/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class TransactionsSidebarHeaderComponent extends Component {
  @service organizationManager;
  @service store;

  get showAccountName() {
    let { highlightedItem: transaction } = this.args;

    return (
      this.organizationManager.organization.hasMultipleBankAccounts && transaction.bankAccount?.name
    );
  }

  get isUserInitiator() {
    let { highlightedItem: transaction } = this.args;

    if (transaction.initiator?.id) {
      let { id: userId } = this.organizationManager.membership;
      let { id: initiatorId } = transaction.initiator;

      return userId === initiatorId;
    }
    return false;
  }

  get isExternalCheckTransaction() {
    let { isExternalTransaction, operationMethod } = this.args.highlightedItem;

    return isExternalTransaction && operationMethod === 'cheque';
  }

  get shouldDisplayKycDisclaimer() {
    let { highlightedItem: transaction } = this.args;
    if (!transaction.isTransfer) {
      return false;
    }
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;
      return !kycSubmitted || kycPending;
    }

    let initiator = this.findInitiatorMembership();
    if (initiator) return !initiator.kycSubmitted || initiator.kycPending;

    return false;
  }

  findInitiatorMembership() {
    let { highlightedItem: transaction } = this.args;

    let initiatorMembership = this.store.peekAll('membership').filter(({ id }) => {
      return transaction.initiator?.id === id;
    });

    if (initiatorMembership.length > 0) {
      let [initiator] = initiatorMembership;
      return initiator;
    }
    return null;
  }

  get disclaimerLevel() {
    if (this.isUserInitiator) {
      return this.isUserKycSubmittedAndPending ? 'info' : 'warning';
    }

    return 'info';
  }

  get isUserKycSubmittedAndPending() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;
      return kycSubmitted && kycPending;
    }

    return false;
  }
}
