/* import __COLOCATED_TEMPLATE__ from './currencies-dropdown.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { groupCurrencies } from 'qonto/utils/currency';
import { getSupportedCurrencies } from 'qonto/utils/receivable-invoicing';

export default class ReceivableInvoicesCurrenciesDropdown extends Component {
  @service intl;
  allCurrencies = getSupportedCurrencies(this.intl);
  defaultCurrency = {
    currencyCode: 'EUR',
    currencyDisplayName: this.intl.t(`currency.EUR`),
    countryCode: 'EU',
    keywords: ['EUR', 'EU', this.intl.t(`currency.EUR`)],
    suggestionPriority: null,
    currencyCodeWithName: `EUR - ${this.intl.t(`currency.EUR`)}`,
  };

  @tracked selectedCurrency = this.correctSelectedCurrency;

  get currencies() {
    let [popularCurrencies, otherCurrencies] = groupCurrencies(this.allCurrencies);

    return popularCurrencies.length
      ? [
          {
            groupName: this.intl.t(
              'receivable-invoices.customer-creation.invoice-currency.dropdown.popular'
            ),
            options: popularCurrencies,
          },
          {
            groupName: this.intl.t(
              'receivable-invoices.customer-creation.invoice-currency.dropdown.all'
            ),
            options: otherCurrencies,
          },
        ]
      : this.allCurrencies;
  }

  get correctSelectedCurrency() {
    return (
      this.allCurrencies.find(
        ({ currencyCode }) => currencyCode === this.args.customer?.currency
      ) ?? this.defaultCurrency
    );
  }

  @action
  changeCurrency(currency) {
    this.args.onChange?.(currency.currencyCode);
    this.args.customer.currency = currency.currencyCode;
    this.selectedCurrency = currency;
  }
}
