export default {
  "content": "cyZ",
  "balance": "cyR",
  "duration": "cyQ",
  "green-text": "cye",
  "balance-subtitle": "cyB",
  "balance-duration": "cyE",
  "footer": "cyd",
  "progress": "cyI",
  "progress-bar": "cyt",
  "progress-bar-text": "cyM",
  "progress-active": "cyP"
};
