/* import __COLOCATED_TEMPLATE__ from './credit-note-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from 'design-system-kit';

export default class ReceivableInvoicesCreditNoteModalComponent extends Component {
  spinner = Spinner;

  @service router;
  @service organizationManager;
  @service intl;
  @service segment;

  get title() {
    return this.intl.t('receivable-invoices.credit-note-modal.credit-note-title.manual', {
      creditNoteNumber: this.creditNote?.number,
    });
  }

  get creditNote() {
    return this.args.data.fetchTask.lastSuccessful?.value.creditNote;
  }

  get logo() {
    let { isDefaultAvatar, picture } = this.organizationManager.organization;
    return isDefaultAvatar ? null : picture;
  }

  get receivableInvoice() {
    return this.args.data.fetchTask.lastSuccessful?.value.receivableInvoice;
  }

  get isPdfPreviewWithNoToolbar() {
    return this.creditNote?.pdfPreviewIframeUrl && this.args.data.isPdfPreviewWithNoToolbar;
  }

  @action goBack() {
    this.segment.track('credit_note_options_escape');
    this.router.transitionTo('receivable-invoices.index');
  }

  @action handleEscape(e) {
    if (e.key === 'Escape') {
      this.goBack();
    }
  }
}
