export default {
  "referral-invite": "cAh",
  "referral": "cAf",
  "illustration": "cAK",
  "steps": "cAG",
  "step": "cAr",
  "share-referral": "cAb",
  "choice": "cAy",
  "line": "cAU",
  "email": "cAj",
  "email-input": "cAW",
  "input-field--error": "cAu",
  "error-message": "cYS",
  "external-link-icon": "cYc"
};
