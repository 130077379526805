/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight, BadgeStatus } from 'design-system-kit';

import { PRICE_PLANT_TRAITS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SubscriptionPricePlanCardComponent extends Component {
  badgeHighlight = BadgeHighlight;
  badgeStatus = BadgeStatus;

  @service segment;

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;
  PRICE_PLANT_TRAITS = PRICE_PLANT_TRAITS;

  get priceByRecurrence() {
    let { recurrence, pricePlan } = this.args;
    let price =
      recurrence === 'monthly'
        ? pricePlan['monthly-price-vat-excluded']
        : pricePlan['annual-price-vat-excluded'] / 12;
    return price;
  }

  get billingRecurrence() {
    let { recurrence, pricePlan } = this.args;
    let price =
      recurrence === 'monthly'
        ? pricePlan['monthly-billing-recurrence']
        : pricePlan['annual-billing-recurrence'];
    return price;
  }

  get cardState() {
    if (this.args.isActive) {
      return 'active';
    }

    if (this.traitName === PRICE_PLANT_TRAITS.popular) {
      return 'selected';
    }

    return null;
  }

  get status() {
    let { isActive, pricePlan } = this.args;
    if (isActive) {
      return 'active';
    }
    if (pricePlan.tag) {
      return 'selected';
    }

    return null;
  }

  @action
  handleOverviewButtonClick() {
    let {
      pricePlan,
      currentPricePlanCode,
      recurrence,
      isFreeTrial,
      isInitialTrial,
      initialTrialEndDate,
    } = this.args;
    this.args.confirmPlanTask
      .perform(pricePlan.code, recurrence, pricePlan.traits)
      .catch(ignoreCancelation);

    let trial_state = TRACKING_TRIAL_STATE.NONE;
    if (isFreeTrial) {
      trial_state = TRACKING_TRIAL_STATE.FREE_TRIAL;
    }
    if (isInitialTrial) {
      trial_state = TRACKING_TRIAL_STATE.INITIAL_TRIAL;
    }

    this.segment.track('plans_plan_clicked', {
      current_plan: currentPricePlanCode,
      target_plan: pricePlan.code,
      trial_state,
      end_date: initialTrialEndDate,
      recurrence,
      origin: undefined,
    });
  }

  get pricePlanContext() {
    let { recurrence, pricePlan } = this.args;
    return recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
      ? pricePlan.monthly_price
      : pricePlan.annual_price;
  }

  get isCtaDisabled() {
    return (
      this.args.isActive ||
      (this.args.confirmPlanTask.isRunning &&
        this.args.selectedPricePlanCode === this.args.pricePlan.code)
    );
  }

  #getTraitsByRecurrence() {
    return this.args.pricePlan.traits.filter(trait => trait.recurrence === this.args.recurrence);
  }

  get traitName() {
    let traitsByRecurrence = this.#getTraitsByRecurrence();
    return traitsByRecurrence?.[0]?.name;
  }

  get traitTitle() {
    let traitsByRecurrence = this.#getTraitsByRecurrence();
    return traitsByRecurrence?.[0]?.title;
  }
}
