export default {
  "header-cell": "qPw",
  "header-content": "qPN caption-bold",
  "active": "qPv",
  "empty": "qPo",
  "align-right": "qPn",
  "type": "qPi",
  "requester": "qPA",
  "request-date": "qPY",
  "amount": "qPh",
  "status": "qPf"
};
