/* import __COLOCATED_TEMPLATE__ from './unit-search-trigger.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { EINVOICING_UNIT_CODES } from 'qonto/constants/receivable-invoice';

const PREDEFINED_VALUE = 'predefined_value';
const CUSTOM_VALUE = 'custom';

export default class UnitSearchTriggerComponent extends Component {
  @service intl;
  @service segment;

  @tracked typedCustomUnit = false;
  @tracked valueDisplayedOnTyping = '';

  @action
  onChange({ target }) {
    // a string with only whitespace won't be sent to BE as it will only take space in the pdf
    if (target.value.trim().length > 0) {
      this.args.onChange?.(target.value);
    } else {
      this.args.onChange?.('');
    }

    // in the case the user types anything === to a unit code, the value should not be
    // already updated with the translation of the code, so tracking the text typed will prevent this from happening
    this.valueDisplayedOnTyping = target.value;
  }

  get valueDisplayed() {
    // while typing and focus in, the typed text will be displayed
    if (this.valueDisplayedOnTyping.length > 0) {
      return this.valueDisplayedOnTyping;
    } else if (EINVOICING_UNIT_CODES.includes(this.args.value)) {
      // on focus out, the translations of the code will be displayed
      return this.intl.t(`unit.${this.args.value}`);
    } else {
      // on focus out the custom unit will be displayed
      return this.args.value;
    }
  }

  @action
  onFocusCustom() {
    this.args.select?.actions.open();
  }

  @action
  onFocusout() {
    // reset the typed text to empty string
    this.valueDisplayedOnTyping = '';

    if (!this.typedCustomUnit && this.args?.value?.length > 0) {
      let type = EINVOICING_UNIT_CODES.includes(this.args.value) ? PREDEFINED_VALUE : CUSTOM_VALUE;

      this.segment.track('invoice-creation_item_unit_filled', { type });

      // the event will be recorded only one time per item each time the form will be opened
      this.typedCustomUnit = true;
    }
  }

  @action
  onMouseUpCustom(event) {
    event.stopPropagation();
    this.args.select?.actions.open();
  }
}
