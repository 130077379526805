/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from 'design-system-kit';
import { dropTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import { INVOICE_STATUSES, SOURCE_TYPES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class SupplierInvoicesDetailsComponent extends Component {
  spinner = Spinner;

  @service abilities;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service notifierManager;
  @service segment;
  @service sentry;
  @service supplierInvoicesManager;

  constructor() {
    super(...arguments);

    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.ANALYZED, this, 'refreshModel');
  }

  willDestroy() {
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.ANALYZED, this, 'refreshModel');

    super.willDestroy();
  }

  get canDelete() {
    return (
      !this.args.isLoading &&
      this.abilities.can('delete supplierInvoice') &&
      ![INVOICE_STATUSES.scheduled, INVOICE_STATUSES.pending].includes(this.args.invoice?.status) &&
      !this.args.invoice?.selfInvoiceId
    );
  }

  get canArchive() {
    return (
      this.abilities.can('update supplier-invoice') &&
      this.args.invoice?.status === INVOICE_STATUSES.toReview &&
      variation('feature-invoices-prioritize-payments')
    );
  }

  get canUnarchive() {
    return (
      this.abilities.can('update supplier-invoice') &&
      this.args.invoice?.status === INVOICE_STATUSES.archived &&
      variation('feature-invoices-prioritize-payments')
    );
  }

  @action
  handleDelete() {
    this.modals.open('popup/destructive', {
      title: this.intl.t('supplier-invoices.delete-modal.title'),
      description: this.intl.t('supplier-invoices.delete-modal.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.deleteInvoiceTask,
    });
  }

  get showEditForm() {
    let { isLoading } = this.args;
    if (this.abilities.can('update supplierInvoice')) {
      return (
        !isLoading &&
        [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice?.status)
      );
    }
  }

  get importedBy() {
    let { initiator, source } = this.args.invoice || {};
    if (initiator) {
      return this.intl.t('supplier-invoices.preview.imported-by.team-member', {
        teamMember: initiator.fullName,
      });
    }

    if (source === SOURCE_TYPES.E_INVOICING) {
      return this.intl.t('supplier-invoices.preview.imported-by.e-invoicing');
    }

    return this.intl.t('supplier-invoices.preview.imported-by.whitelist');
  }

  get subtitle() {
    if (this.args.invoice) {
      let datetime = this.intl.formatTime(this.args.invoice.createdAt, { format: 'long' });
      let importedOn = this.intl.t('supplier-invoices.preview.imported-on', {
        datetime,
      });
      return `${importedOn} ${this.importedBy}`;
    }
  }

  @action
  handleDownload() {
    window.open(this.args.attachment.downloadUrl);
    this.segment.track('supplier-invoices_download_clicked');
  }

  @action
  refreshModel(eventPayload) {
    let id = this.supplierInvoicesManager.getSupplierInvoiceIdFromAnalyzedEvent(eventPayload);
    if (!this.args.isLoading && id === this.args.invoice?.id) {
      this.args.reload();
    }
  }

  deleteInvoiceTask = dropTask(async close => {
    let { invoice } = this.args;

    this.segment.track('supplier-invoices_delete_clicked', {
      action_type: 'single_item',
      is_non_financial_document: invoice.isAttachmentNonFinancial,
    });

    try {
      await this.supplierInvoicesManager.deleteInvoiceTask.perform(invoice);
      close({ isDeletion: true });
      this.args.onClose();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  updateForm(form) {
    this.form = form;
  }

  @action
  handleArchive(id) {
    this.segment.track('supplier-invoices_archive_clicked', {
      origin: 'invoice_detail',
      action_type: 'single_item',
    });

    this.modals.open('supplier-invoices/archive-popup', { id });
  }

  @action
  handleUnarchive(id) {
    this.segment.track('supplier-invoices_unarchive_clicked', { origin: 'invoice_detail' });

    this.modals.open('supplier-invoices/unarchive-popup', { id });
  }

  handleClose = dropTask(async ({ isDeletion }) => {
    await timeout(250);

    if (this.form?.isDirty && !isDeletion) {
      let result = await this.modals.open('popup/confirmation', {
        title: this.intl.t('supplier-invoices.edit.modal.save-modal.title'),
        description: this.intl.t('supplier-invoices.edit.modal.save-modal.description'),
        cancel: this.intl.t('btn.discard'),
        confirm: this.intl.t('btn.save_settings'),
      });

      if (result === 'confirm') {
        try {
          await this.form.submit({ partial: true });
          this.toastFlashMessages.toastSuccess(
            this.intl.t('supplier-invoices.success-toast.changes-saved')
          );
          this.args.onClose();
        } catch (error) {
          // ignore form validation errors
          if (error.name !== 'FormValidationError' && error.status !== 400) {
            throw error;
          }
        }
      } else if (result === 'cancel') {
        this.args.onClose();
      }
    } else {
      this.args.onClose();
    }
  });
}
