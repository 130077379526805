export default {
  "section-container": "cGg",
  "disclaimer-section": "cGD",
  "trip-title": "cGV caption-bold mb-16",
  "google-link-container": "cGL",
  "link-icon": "cGT",
  "trip-details": "cGz body-2",
  "trip-details-icon": "cGa",
  "trip-details-title": "cGH",
  "subtitle": "cGO caption-bold mb-16",
  "disclaimer": "cGm",
  "visualizer": "cGp"
};
