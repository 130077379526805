/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { INVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReviewDuplicatesModalItem extends Component {
  tag = Tag;

  @service intl;
  @service store;
  @service toastFlashMessages;
  @service sentry;

  openAttachmentInNewWindow = dropTask(async () => {
    try {
      let attachment = await this.store.findRecord('attachment', this.args.duplicate.attachment.id);
      window.open(attachment.file.fileUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get status() {
    let { status, displayedStatus } = this.args.duplicate;
    let statusColors = INVOICING_STATUS_COLORS;

    return {
      text: displayedStatus,
      color: statusColors[status],
    };
  }
}
