/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from 'design-system-kit';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';

export default class TransactionsTableItem extends Component {
  tag = Tag;

  @service intl;
  @service userManager;
  @service organizationManager;
  @service featuresManager;
  @service segment;

  get hasExternalAccounts() {
    return this.organizationManager.organization?.hasExternalAccounts;
  }

  get isAttachmentStatusHidden() {
    let { item } = this.args;
    return item.status === 'declined' || item.operationMethod === 'biller' || item.isPagoPaPayment;
  }

  get isSelected() {
    return this.args.selectedItems.includes(this.args.item);
  }

  get status() {
    let statuses = {
      completed: this.intl.t('transactions.status.completed'),
      pending: this.intl.t('transactions.status.pending'),
      canceled: this.intl.t('transactions.status.canceled'),
      declined: this.intl.t('transactions.status.declined'),
      reversed: this.intl.t('transactions.status.reversed'),
    };

    let { item: transaction } = this.args;

    let transactionStatus =
      transaction.isCheckTransaction && !transaction.bankAccount.isExternalAccount
        ? transaction.subject.get('status')
        : transaction.status;

    return transaction.canceled ? statuses.canceled : statuses[transactionStatus];
  }

  get shouldDisplayName() {
    let { name: cardHolderName } = this.args.item.methodLabel;
    let { fullName } = this.userManager.currentUser;
    let { membershipsCount } = this.organizationManager.organization;
    let { employee, owner } = this.organizationManager.membership;

    if (owner) {
      return true;
    }

    return membershipsCount > 1 ? cardHolderName !== fullName && !employee : false;
  }

  get attachmentStatus() {
    let item = this.args.item;

    if (item.attachmentRequired) {
      if (item.attachmentLost) {
        return {
          status: 'lost',
          color: 'red',
          copy: this.intl.t('transactions.receipt.status.lost'),
        };
      }

      if (item.attachmentCount) {
        return {
          status: 'attached',
          color: 'green',
          copy: this.intl.t('transactions.receipt.status.attached'),
        };
      }

      return {
        status: 'missing',
        color: 'gray',
        copy: this.intl.t('transactions.receipt.status.missing'),
      };
    }

    return {
      status: 'notrequired',
      color: 'green',
      copy: this.intl.t('transactions.receipt.status.not-required'),
    };
  }

  get isDisabled() {
    let { item } = this.args;

    if (item.subjectType === 'DirectDebitCollection' && !item.bankAccount.isExternalAccount) {
      return (
        item.status === DIRECT_DEBIT_COLLECTION_STATUS.CANCELED ||
        item.status === DIRECT_DEBIT_COLLECTION_STATUS.DECLINED
      );
    }

    return item.declined || item.reversed;
  }

  get isDisputingChargeback() {
    let { item } = this.args;

    return item.chargebackDisputingTypes.length > 0;
  }

  @action
  selectWithKeyboard(event) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.args.onSelectItem();
    }
  }

  @action
  rowClick() {
    let { item } = this.args;
    let { bankAccount } = item;

    let params = bankAccount
      ? {
          account_type: bankAccount.accountType,
          account_bank_system: bankAccount.coreBankingSystem,
        }
      : undefined;

    this.segment.track('transaction_details_opened', params);
  }
}
