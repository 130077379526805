/* import __COLOCATED_TEMPLATE__ from './item-search-trigger.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ItemSearchTriggerComponent extends Component {
  @service segment;

  inputValueAfterUnfocused = this.args.value;

  @action
  onChange({ target }) {
    this.args.onChange?.(target.value);
  }

  @action
  onFocusCustom() {
    this.args.select?.actions.open();
  }

  @action
  onFocusout(event) {
    let optionsArray = this.args.select?.options;
    let { value } = event.target;

    if (optionsArray?.find(option => option.id === event.relatedTarget?.id)) {
      return;
    } else if (value && value !== this.inputValueAfterUnfocused) {
      this.segment.track('invoice_item-title_filled');
    }

    this.inputValueAfterUnfocused = value;

    this.args.onFocusout?.();
  }

  @action
  onMouseDownCustom(event) {
    event.stopPropagation();
    this.args.select?.actions.open();
  }
}
