export default {
  "transfer-form": "qVa",
  "transfer-form-title": "qVH",
  "transfer-form-subtitle": "qVO",
  "form": "qVm",
  "form-footer": "qVp",
  "footer-btn": "qVx",
  "header": "qVw",
  "main-settings": "qVN",
  "additional-settings": "qVv",
  "form-container": "qVo"
};
