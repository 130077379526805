export default {
  "request-transfer-validation": "qZd",
  "account-selector": "qZI",
  "has-error": "qZt",
  "actions": "qZM",
  "action-btn": "qZP",
  "warnings": "qZl",
  "top-border": "qZX",
  "account-selector-subtitle": "qZC",
  "action-btn-right": "qZk",
  "funds-disclaimer": "qZJ",
  "account-options": "qZs",
  "account-balance": "qZF",
  "request-multi-transfer-validation": "qZg",
  "wrapper": "qZD",
  "warning-message-row": "qZV",
  "multi-transfer-align": "qZL",
  "button-approve": "qZT",
  "button-approve-spinner": "qZz",
  "button-decline": "qZa"
};
