/* import __COLOCATED_TEMPLATE__ from './submit-banner.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from 'design-system-kit';
import { task } from 'ember-concurrency';

export default class SubmitBanner extends Component {
  badgeStatus = BadgeStatus;

  @service('intl') intl;
  get bannerIllustrationSrc() {
    let { legallyRequired, needsReview } = this.args.updateProcess;
    if (legallyRequired) {
      return '/illustrations/settings/kyu-periodic.svg';
    } else if (needsReview) {
      return '/illustrations/settings/kyu-submit.svg';
    } else {
      return '/illustrations/settings/kyu-confirm.svg';
    }
  }

  submitTask = task(async () => {
    await this.args.submitTask.unlinked().perform();
  });

  get isBannerVisible() {
    let { legallyRequired, needsReview } = this.args.updateProcess;

    return !legallyRequired && needsReview;
  }

  get disclaimerText() {
    let { legallyRequired } = this.args.updateProcess;

    if (legallyRequired) {
      return this.intl.t('organizations.profile.company-profile.banner-confirm.error-message');
    }

    return this.intl.t('organizations.profile.company-profile.banner-review.error-message');
  }

  get texts() {
    let { legallyRequired, needsReview } = this.args.updateProcess;

    if (legallyRequired) {
      return {
        title: this.intl.t('organizations.profile.company-profile.banner-confirm.title'),
        body: this.intl.t('organizations.profile.company-profile.banner-confirm.subtitle'),
        button: this.intl.t('organizations.profile.company-profile.banner-confirm.button.confirm'),
      };
    }

    if (needsReview) {
      return {
        title: this.intl.t('organizations.profile.company-profile.banner-review.title'),
        badge: this.intl.t('organizations.profile.company-profile.banner-review.tag'),
        body: this.intl.t('organizations.profile.company-profile.banner-review.subtitle'),
        button: this.intl.t('organizations.profile.company-profile.banner-review.button.submit'),
      };
    }

    return {
      title: this.intl.t('organizations.profile.company-profile.banner-changes.title'),
      body: this.intl.t('organizations.profile.company-profile.banner-changes.subtitle'),
      button: this.intl.t('organizations.profile.company-profile.banner-changes.button.confirm'),
    };
  }
}
