export default {
  "fullscreen-modal": "cOp",
  "creation-form-modal": "cOx",
  "links-container": "cOw",
  "link-field": "cON",
  "link": "cOv",
  "add-link": "cOo",
  "remove-link": "cOn",
  "flex-row": "cOi",
  "flex-item": "cOA",
  "unit-trigger": "cOY",
  "dynamic-placeholders": "cOh"
};
