export default {
  "wrapper": "cpc",
  "per-page-container": "cpq",
  "options": "cpZ",
  "option": "cpR",
  "active": "cpQ",
  "description": "cpe",
  "description-per-page": "cpB",
  "summary": "cpE"
};
