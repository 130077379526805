/* import __COLOCATED_TEMPLATE__ from './kyc-missing-id-fourthline.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class TopbarKycMissingIdComponent extends Component {
  @service organizationManager;

  @reads('organizationManager.membership.personalInfoProvided')
  personalInfoProvided;
}
