/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class SelectorCheckbox extends Component {
  get isChecked() {
    return this.args.selected.find(id => id === this.args.item.id);
  }

  @action
  check(event) {
    event.stopPropagation();
    this.args.onSelect(this.args.item);
  }
}
