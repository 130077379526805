/* import __COLOCATED_TEMPLATE__ from './declined.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { INSTANT_DECLINED_REASONS } from 'qonto/constants/transfers';
import { canDeclinedInstantTransferBeRetried } from 'qonto/utils/transfers';

export default class TransfersSepaSettlementDeclinedComponent extends Component {
  @service intl;

  get illustrationPath() {
    return this.canBeRetried
      ? '/illustrations/transfers/instant-declined-retry.svg'
      : '/illustrations/transfers/error-right.svg';
  }

  get declinedReasonDescription() {
    let declinedReasonDescriptions = {
      [INSTANT_DECLINED_REASONS.ACCOUNT_UNREACHABLE]: this.intl.t(
        'transfers.modals.instant.error.unreachable-beneficiary.description'
      ),
      [INSTANT_DECLINED_REASONS.INST_BENEFICIARY_INVALID]: this.intl.t(
        'transfers.modals.instant.error.instant-invalid-beneficiary.description'
      ),
      [INSTANT_DECLINED_REASONS.INST_BENEFICIARY_NOT_SEPA]: this.intl.t(
        'transfers.modals.instant.error.invalid-beneficiary.description'
      ),
      [INSTANT_DECLINED_REASONS.CREDITOR_TIMEOUT]: this.intl.t(
        'transfers.modals.instant.error.declined.description'
      ),
      [INSTANT_DECLINED_REASONS.FORMAT_REJECTED]: this.intl.t(
        'transfers.modals.instant.error.declined.description'
      ),
      [INSTANT_DECLINED_REASONS.PAYMENT_FAILED]: this.intl.t(
        'transfers.modals.instant.error.declined.description'
      ),
      [INSTANT_DECLINED_REASONS.INST_PROCESSING_ERROR]: this.intl.t(
        'transfers.modals.instant.error.processing-error.description'
      ),
      [INSTANT_DECLINED_REASONS.DISPOSITION_ERROR]: this.intl.t(
        'transfers.modals.instant.error.regulatory-error.description'
      ),
      [INSTANT_DECLINED_REASONS.OPERATIONAL_ERROR]: htmlSafe(
        this.intl.t('transfers.modals.instant.error.regulatory-error.description')
      ),
      [INSTANT_DECLINED_REASONS.SCREENING_REJECTED]: this.intl.t(
        'transfers.modals.instant.error.regulatory-error.description'
      ),
      [INSTANT_DECLINED_REASONS.INSUFFICIENT_FUNDS]: this.intl.t(
        'transfers.modals.instant.error.not-enough-funds.description'
      ),
    };

    return (
      declinedReasonDescriptions[this.args.declinedReason] ||
      this.intl.t('transfers.modals.instant.error.generic.description')
    );
  }

  get canBeRetried() {
    return canDeclinedInstantTransferBeRetried(this.args.declinedReason);
  }
}
