export default {
  "row": "qgk",
  "col": "qgJ",
  "header": "qgs",
  "block": "qgF",
  "col-5": "qgg",
  "col-6": "qgD",
  "col-9": "qgV",
  "col-12": "qgL",
  "hide": "qgT"
};
