export default {
  "row": "qlc",
  "active": "qlq",
  "dot": "qlZ",
  "red": "qlR",
  "green": "qlQ",
  "yellow": "qle",
  "align-right": "qlB",
  "animated": "qlE",
  "fadein-item": "qld",
  "cell": "qlI body-2",
  "cell-content": "qlt",
  "amount": "qlM body-1",
  "subtitle": "qlP caption",
  "status": "qll",
  "cell-amount": "qlX"
};
