export default {
  "row": "cmH",
  "cell": "cmO",
  "title": "cmm",
  "internal-note": "cmp",
  "unit": "cmx",
  "price": "cmw",
  "quick-actions": "cmN",
  "empty": "cmv",
  "checkbox": "cmo"
};
