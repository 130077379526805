export default {
  "header-cell": "cmk",
  "checkbox-cell": "cmJ",
  "title-col": "cms",
  "internal-note-col": "cmF",
  "unit-col": "cmg",
  "price-col": "cmD",
  "header-content": "cmV caption-bold",
  "active": "cmL",
  "empty": "cmT"
};
