/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { BadgeStatus } from 'design-system-kit';

import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { STATUS } from 'qonto/constants/transfers';
export default class TransfersTableItem extends Component {
  badgeStatus = BadgeStatus;

  @service intl;

  get isTransferCancelledOrDeclined() {
    let transfer = this.args.item;

    if (transfer.instant) return false;
    return transfer.isCanceled || transfer.isDeclined;
  }

  get lastDate() {
    let transfer = this.args.item;

    if (transfer.instant) {
      return transfer.createdAt;
    }

    if (transfer.isStanding) {
      if (transfer.lastRecursionDate) return transfer.lastRecursionDate;
    } else if (transfer.scheduledDate) {
      return transfer.scheduledDate;
    }
  }

  get nextDate() {
    let transfer = this.args.item;

    let nextDate = transfer.isStanding
      ? transfer.nextRecursionDate
      : dayjs(transfer.scheduledDate).toDate();

    if (
      nextDate &&
      !transfer.instant &&
      !transfer.isDeclined &&
      !transfer.isCanceled &&
      !transfer.isCompleted
    ) {
      return nextDate;
    } else if (transfer.instant && transfer.isProcessing) {
      return transfer.createdAt;
    }
  }

  get statusLevel() {
    switch (this.args.item.status) {
      case STATUS.COMPLETED:
        return 'validated';
      case STATUS.CANCELED:
      case STATUS.DECLINED:
        return 'error';
      case STATUS.PENDING:
      case STATUS.STANDING_PROCESSING:
        return 'waiting';
      case STATUS.ACCOUNT_CANCELED:
      case STATUS.BENEFICIARY_ACCOUNT_CANCELED:
      case STATUS.KYB_ONHOLD:
      case STATUS.PENDING_REVIEW:
      case STATUS.PENDING_SEIZURE:
      case STATUS.PROCESSING:
      case STATUS.VALIDATED:
        return 'in-progress';
      default:
        return 'in-progress';
    }
  }

  get statusText() {
    switch (this.args.item.status) {
      case STATUS.ACCOUNT_CANCELED:
        return this.intl.t('transfers.status.account-canceled');
      case STATUS.BENEFICIARY_ACCOUNT_CANCELED:
        return this.intl.t('transfers.status.beneficiary-account-canceled');
      case STATUS.CANCELED:
        return this.intl.t('transfers.status.canceled');
      case STATUS.COMPLETED:
        return this.intl.t('transfers.status.completed');
      case STATUS.DECLINED:
        return this.intl.t('transfers.status.declined');
      case STATUS.KYB_ONHOLD:
        return this.intl.t('transfers.status.kyb-onhold');
      case STATUS.PENDING:
        return this.intl.t('transfers.status.pending');
      case STATUS.PENDING_REVIEW:
        return this.intl.t('transfers.status.pending-review');
      case STATUS.PENDING_SEIZURE:
        return this.intl.t('transfers.status.pending-seizure');
      case STATUS.PROCESSING:
        return this.intl.t('transfers.status.processing');
      case STATUS.VALIDATED:
        return this.intl.t('transfers.status.validated');
      case STATUS.STANDING_PROCESSING:
        return this.intl.t('transfers.status.standing-processing');
    }
  }

  get transferListType() {
    let {
      item: { operationType },
    } = this.args;
    switch (operationType) {
      case SCHEDULE_OPERATION_TYPES.YEARLY:
        return this.intl.t('transfers.frequency.standing_yearly');
      case SCHEDULE_OPERATION_TYPES.HALF_YEARLY:
        return this.intl.t('transfers.frequency.standing_half_yearly');
      case SCHEDULE_OPERATION_TYPES.QUARTERLY:
        return this.intl.t('transfers.frequency.standing_quarterly');
      case SCHEDULE_OPERATION_TYPES.MONTHLY:
        return this.intl.t('transfers.frequency.standing_monthly');
      case SCHEDULE_OPERATION_TYPES.WEEKLY:
        return this.intl.t('transfers.frequency.standing_weekly');
      default:
        return '-';
    }
  }
}
