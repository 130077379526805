export default {
  "item": "cnv",
  "details": "cno",
  "flex": "cnn",
  "header": "cni cnn",
  "flex-no-gap": "cnA cnn",
  "file-name": "cnY",
  "label": "cnh body-2",
  "self-icon": "cnf",
  "with-separator": "cnK",
  "dimmed": "cnG"
};
