/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TransferMultiBeneficiary extends Component {
  @service modals;
  @service attachmentsManager;

  @tracked isEmailDirty = false;

  _memoizedIsForceOpened = false;

  get activityTag() {
    return this.args.transfer.activityTagCode || this.args.transfer.activityTag;
  }

  get displayWarning() {
    let error = this.args.transfer.validations.error;
    if (error) {
      let isEmailErrorDisplayed = error.type === 'format' && error.attribute === 'email';
      return isEmailErrorDisplayed ? this.isEmailDirty : true;
    }
    return false;
  }

  @action addAttachment(file) {
    this.attachmentsManager.addAttachment(false, this.args.transfer, file);
  }

  @action removeAttachment(file) {
    this.attachmentsManager.removeAttachment(false, this.args.transfer, file);
  }

  @action cancelUploadAttachment(file) {
    this.attachmentsManager.cancelUploadAttachment(this.args.transfer, file);
  }

  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      model: this.args.transfer,
      download: null,
    });
  }

  get isForceOpened() {
    let result = false;

    if (this.args.transfer.get('didValidate')) {
      if (this.args.transfer.validations.errors.length) result = true;
      if (this.args.transfer.validations.isValidating) result = this._memoizedIsForceOpened;
    }

    // eslint-disable-next-line ember/no-side-effects
    this._memoizedIsForceOpened = result;

    return result;
  }

  @action
  handleEmailFocusOut(event) {
    this.isEmailDirty = Boolean(event.target.value);
  }
}
