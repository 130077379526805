/* import __COLOCATED_TEMPLATE__ from './column.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class SupplierInvoicesTableHeaderColumnComponent extends Component {
  get style() {
    let { width, align } = this.args.column;
    let colWidth = width ? `width:${width};` : '';
    let textAlign = align || 'left';

    return htmlSafe(`${colWidth}text-align:${textAlign};`);
  }
}
