export default {
  "attachment-viewer": "cKC",
  "hide-sidebar": "cKk",
  "sidebar": "cKJ",
  "header": "cKs",
  "header-top": "cKF",
  "header-icon": "cKg",
  "header-amount": "cKD",
  "infobox": "cKV",
  "vat": "cKL",
  "validation": "cKT"
};
