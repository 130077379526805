/* import __COLOCATED_TEMPLATE__ from './radio-group-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import isFunction from 'qonto/utils/is-function';

export default class TransfersInternationalOutDynamicFormRadioGroupComponent extends Component {
  get selectedOption() {
    let { field, value } = this.args;
    return field.valuesAllowed.find(option => option.key === value);
  }

  get shouldRefreshOnChange() {
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onSelect(selectedOption) {
    let { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, selectedOption.key);

    if (this.shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}
