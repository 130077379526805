/* import __COLOCATED_TEMPLATE__ from './empty.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class TransfersRequestTableEmptyComponent extends Component {
  lottiePlayer = LottiePlayer;

  animationReady = false;
}
