/* import __COLOCATED_TEMPLATE__ from './confirm-modal.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { task, waitForQueue } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { DISCLAIMER_TYPES, SEPA_MODAL_CLOSE_REASONS } from 'qonto/constants/transfers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TransfersInternalConfirmModalComponent extends Component {
  @service intl;
  @service router;
  @service segment;
  @service sensitiveActions;
  @service organizationManager;

  cancelButtonId = `cancel-btn-${guidFor(this)}`;
  submitButtonId = `submit-btn-${guidFor(this)}`;

  constructor() {
    super(...arguments);

    this._trackOnInit();
    this.setFocus();
  }

  @tracked showErrors = false;

  get disclaimers() {
    let { warnings, errors, role } = this.args.data;
    return this._getInternalDisclaimers({ warnings, errors, role });
  }

  get transferDate() {
    let { scheduledDate } = this.args.data.transfer;

    return dateToken({
      date: scheduledDate,
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.COMPACT,
    });
  }

  get displayKycDisclaimer() {
    return this.organizationManager.membership.kycPending;
  }

  async setFocus() {
    await waitForQueue('afterRender');
    if (this.args.data.disabledConfirm) {
      document.getElementById(this.cancelButtonId).focus();
    } else {
      document.getElementById(this.submitButtonId).focus();
    }
  }

  @action
  handleClick() {
    this.sensitiveActions.runTask.perform(this.confirmTask).catch(ignoreCancelation);
    this.trackOnSubmit();
  }

  _trackOnInit() {
    let { disabledConfirm, transfer } = this.args.data;
    let trackOptions = {
      confirm_clickable: !disabledConfirm,
    };

    if (!isEmpty(this.disclaimers)) {
      trackOptions.general_warnings = this.disclaimers.map(({ type }) => type);
    }

    this.segment.track(this._chooseTrackingEvent(transfer, 'displayed'), trackOptions);
  }

  _chooseTrackingEvent(transfer, action) {
    return transfer.isNew
      ? `transfer_internal_create_summary_${action}`
      : `transfer_edit_summary_${action}`;
  }

  @action
  trackOnSubmit() {
    let { transfer } = this.args.data;

    this.segment.track(this._chooseTrackingEvent(transfer, 'confirmed'), {
      origin: this.router?.currentRoute?.queryParams?.origin,
    });
  }

  confirmTask = task(async () => {
    await this.args.data.confirmTransferTask.linked().perform();
    this.args.close(SEPA_MODAL_CLOSE_REASONS.CONFIRM_TRANSFER);
  });
  @action
  handleClose() {
    this.segment.track('transfer_create_summary_cancel');
    this.args.close();
  }

  _getInternalDisclaimers({ warnings = [], errors = [], role }) {
    let disclaimers = [
      {
        type: DISCLAIMER_TYPES.ALREADY_ISSUED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.already_issued'),
      },
      {
        type: DISCLAIMER_TYPES.KYB_WAITING,
        level: 'warning',
        message: this.intl.t('transfers.warnings.kyb_waiting'),
      },
      {
        type: DISCLAIMER_TYPES.TOP_UP_REQUIRED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.top_up_required'),
      },
      {
        type: DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS,
        level: 'error',
        message:
          role === ROLES.MANAGER
            ? this.intl.t('transfers.warnings.manager.insufficient_funds')
            : this.intl.t('transfers.warnings.insufficient_funds'),
      },
    ];
    let selectDisclaimer = disclaimer =>
      [...warnings, ...errors].find(warning => warning === disclaimer.type);
    return disclaimers.filter(selectDisclaimer);
  }
}
