/* import __COLOCATED_TEMPLATE__ from './invoice-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

import { COMMON_CREDIT_PERIODS, COMMON_QUOTE_EXPIRY_PERIODS } from './due-date-selector';

export default class InvoiceDetailsComponent extends Component {
  @service intl;
  @service segment;
  @service organizationManager;
  @service router;

  @tracked showHeaderTextField = false;

  selectedPeriod;

  get origin() {
    // storing the current route, to be used when transitioning to the invoicing-settings route
    return this.router?.currentRoute;
  }

  get formOrigin() {
    // storing params if any (document id), to be used when transitioning to the invoicing-settings route
    let { currentRoute } = this.router;
    let params = currentRoute?.params?.id;

    // when transitioning to the invoicing-settings route, the query obj will contained the origin route
    // as the transition.from is not always available
    return { origin: this.origin?.name, params };
  }

  get detailsTitle() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.invoice-details.title');
    }
    return this.intl.t('receivable-invoices.invoice-creation.invoice-details.title');
  }

  get automaticNumberLabel() {
    if (this.args.isQuote) {
      return this.intl.t(
        'receivable-invoices.quote-creation.invoice-details.automatic-invoice-number.placeholder'
      );
    }
    return this.intl.t(
      'receivable-invoices.invoice-creation.invoice-details.automatic-invoice-number.label'
    );
  }

  get manualNumberLabel() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.invoice-details.quote-number.label');
    }
    return this.intl.t('receivable-invoices.invoice-creation.invoice-details.invoice-number.label');
  }

  get manualNumberPlaceholder() {
    if (this.args.isQuote) {
      return this.intl.t(
        'receivable-invoices.quote-creation.invoice-details.quote-number.placeholder'
      );
    }
    return this.intl.t(
      'receivable-invoices.invoice-creation.invoice-details.invoice-number.placeholder'
    );
  }

  get automaticNumber() {
    return this.args.isQuote
      ? this.args.settings?.quoteNextNumberFormatted
      : this.args.settings?.invoiceNextNumberFormatted;
  }

  get numberErrorMessage() {
    let errors = this.args.document.errors.get('number') || [];
    if (errors.length === 0) return null;
    if (
      errors.some(
        e =>
          e.message === 'invoice_number_already_exists' ||
          e.message === 'quote_number_already_exists' ||
          e.message === 'invalid'
      )
    ) {
      if (this.args.settings?.numberingMode === 'automatic') {
        if (this.args.isQuote) {
          return this.intl.t(
            'receivable-invoices.quote-creation.invoice-details.automatic-invoice-number.try-again-error'
          );
        }

        return this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.automatic-invoice-number.try-again-error'
        );
      }
      if (this.args.isQuote) {
        return this.intl.t(
          'receivable-invoices.quote-creation.invoice-settings.numbering.try-again-error'
        );
      }

      if (errors[0].message === 'invalid') {
        return this.intl.t('self-billing.errors.invoice-numbering.pattern-conflict');
      }

      return this.intl.t(
        'receivable-invoices.invoice-creation.invoice-settings.numbering.try-again-error'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
  }

  get issueDate() {
    return this.args.document.issueDate;
  }

  get showPerformanceDate() {
    return !this.args.isQuote;
  }

  get performanceDate() {
    return this.args.document.performanceDate;
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get performanceDateLabel() {
    if (this.legalCountry === 'DE') {
      return this.intl.t(
        'receivable-invoices.invoice-creation.invoice-details.performance-date.de-label'
      );
    }
    return this.intl.t(
      'receivable-invoices.invoice-creation.invoice-details.performance-date.label'
    );
  }

  get isPerformanceDateClearable() {
    if (this.legalCountry === 'DE') {
      return false;
    }
    return true;
  }

  get dueOrExpiryDateLabel() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.invoice-details.expiry-date.label');
    }
    return this.intl.t('receivable-invoices.invoice-creation.invoice-details.due-date.label');
  }

  get displayHeaderTextFieldContainer() {
    return !this.args.isDEInvoicing;
  }

  get shouldShowHeader() {
    return this.args.document.header ? this.args.document.header : this.showHeaderTextField;
  }

  @action
  onPeriodChange(period) {
    this.selectedPeriod = period;
  }

  @action updateNumber(number) {
    this.args.document.number = number;
    this.args.document.number = number.replace(/[^\w/\-?:().,'+]/g, '');
  }

  @action updateIssueDate(newIssueDate) {
    if (this.args.isQuote) {
      return this._updateQuoteIssueDate(newIssueDate);
    }
    return this._updateInvoiceIssueDate(newIssueDate);
  }

  @action updatePerformanceDate(date) {
    this.args.document.performanceDate = date;

    if (date) {
      this.segment.track('invoice_performance-date', { action: 'filled' });
    } else if (date === null) {
      this.segment.track('invoice_performance-date', { action: 'cleared' });
    }
  }

  @action
  handleClickShowHeaderTextField() {
    this.showHeaderTextField = true;
    this.segment.track('invoice_header-message', { action: 'add' });
  }

  @action
  handleCloseHeaderTextField() {
    this.showHeaderTextField = false;
    this.args.document.header = '';
    this.segment.track('invoice_header-message', { action: 'close' });
  }

  _updateQuoteIssueDate(newIssueDate) {
    let { expiryDate } = this.args.document;
    let isCommonExpiryPeriod = COMMON_QUOTE_EXPIRY_PERIODS.includes(this.selectedPeriod);

    if (!dayjs(newIssueDate).isValid()) {
      this.args.document.issueDate = undefined;
      return;
    }

    if (!isCommonExpiryPeriod && dayjs(newIssueDate).isAfter(expiryDate, 'day')) {
      this.args.document.expiryDate = undefined;
    }

    if (isCommonExpiryPeriod) {
      this.args.document.expiryDate = dayjs(newIssueDate)
        .add(this.selectedPeriod, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
    }

    this.args.document.issueDate = newIssueDate;
  }

  _updateInvoiceIssueDate(newIssueDate) {
    let { dueDate } = this.args.document;
    let isCommonCreditPeriod = COMMON_CREDIT_PERIODS.includes(this.selectedPeriod);

    if (!dayjs(newIssueDate).isValid()) {
      this.args.document.issueDate = undefined;
      return;
    }

    if (!isCommonCreditPeriod && dayjs(newIssueDate).isAfter(dueDate, 'day')) {
      this.args.document.dueDate = undefined;
    }

    if (isCommonCreditPeriod) {
      this.args.document.dueDate = dayjs(newIssueDate)
        .add(this.selectedPeriod, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
    }

    this.args.document.issueDate = newIssueDate;
  }
}
