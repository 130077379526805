export default {
  "container": "cvs",
  "text-content": "cvF",
  "text-wrapper": "cvg",
  "actions-container": "cvD",
  "illustration": "cvV",
  "feature-tour-link": "cvL",
  "link-illustration": "cvT",
  "lottie": "cvz"
};
