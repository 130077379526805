export default {
  "insight-pill": "qIK",
  "insight-pill-figure": "qIG",
  "insight-pill-renderer": "qIr",
  "loading": "qIb",
  "counter": "qIy",
  "error": "qIU",
  "insight-pill-label": "qIj",
  "insight-pill-subtitle": "qIW caption",
  "hidden": "qIu",
  "missing-amounts-warning": "qtS",
  "missing-amounts": "qtc"
};
