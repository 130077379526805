export default {
  "wrapper": "qqx",
  "beneficiary": "qqw",
  "name": "qqN body-1",
  "beneficiary-email": "qqv body-2",
  "dropdown-icon": "qqo",
  "trusted": "qqn",
  "account-number": "qqi flex body-2",
  "account-icon": "qqA",
  "m-chip": "qqY",
  "dropdown": "qqh body-2",
  "delete-option": "qqf",
  "beneficiary-name": "qqK"
};
