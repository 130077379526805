export default {
  "mileage-validation": "cre",
  "actions": "crB",
  "action-btn": "crE",
  "action-btn-right": "crd",
  "request-mileage-validation": "crI",
  "account-selector": "crt",
  "has-error": "crM",
  "funds-disclaimer": "crP",
  "account-options": "crl",
  "account-balance": "crX",
  "warnings": "crC"
};
