export default {
  "item": "qtJ",
  "details": "qts",
  "flex": "qtF",
  "header": "qtg qtF",
  "flex-no-gap": "qtD qtF",
  "file-name": "qtV",
  "label": "qtL body-2",
  "self-icon": "qtT",
  "with-separator": "qtz",
  "dimmed": "qta"
};
