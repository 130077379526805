export default {
  "insight-pill": "qIx",
  "interactive": "qIw",
  "focus": "qIN",
  "subtitle": "qIv caption",
  "hidden": "qIo",
  "missing-amounts-warning": "qIn",
  "totals": "qIi",
  "counter": "qIA title-3",
  "loading": "qIY",
  "error": "qIh",
  "title": "qIf title-4"
};
