/* import __COLOCATED_TEMPLATE__ from './rule.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { OPERATOR } from 'qonto/constants/reminders-configuration';
import { emailTemplateForOperator } from 'qonto/routes/clients/client/reminders-configuration/route';

export const CLEARABLE_OPERATORS = [OPERATOR.BEFORE, OPERATOR.AFTER];

export default class RuleComponent extends Component {
  @service intl;

  @tracked templates = [...(this.args.defaultTemplates || [])];

  constructor() {
    super(...arguments);

    this.setupTemplate();
  }

  get title() {
    return this.intl.t('payment-reminders.reminder-item.title', {
      index: (this.args.index ?? 0) + 1,
    });
  }

  get description() {
    let { rule } = this.args;
    let { operator, offsetDays } = rule;

    let hasClearableOperator = CLEARABLE_OPERATORS.includes(operator);

    if (!operator || (hasClearableOperator && !offsetDays)) {
      return '';
    }

    return this.intl.t('payment-reminders.reminder-item.rule', {
      operator,
      offset: offsetDays,
    });
  }

  get isExpanded() {
    return this.args.expanded ?? true;
  }

  get isForcedExpanded() {
    return this.args.displayErrors && this.args.rule.validations.isInvalid;
  }

  applyTemplate() {
    let { rule } = this.args;
    let { operator } = rule;

    let templateToApply = emailTemplateForOperator(this.templates, operator);

    if (!templateToApply) {
      return;
    }

    rule.setProperties(templateToApply);
  }

  saveTemplate(operator) {
    let indexToSave = this.templates.findIndex(
      ({ operator: templateOperator }) => templateOperator === operator
    );

    let newTemplate = {
      emailTitle: this.args.rule.emailTitle,
      emailBody: this.args.rule.emailBody,
    };

    if (indexToSave >= 0) {
      this.templates[indexToSave] = {
        ...this.templates[indexToSave],
        ...newTemplate,
      };
    } else {
      this.templates.push({ ...newTemplate, operator });
    }
  }

  setupTemplate() {
    let { rule } = this.args;

    if (rule.emailTitle || rule.emailBody) {
      this.saveTemplate(this.args.rule.operator);

      return;
    }

    next(() => {
      this.applyTemplate();
    });
  }

  @action
  onUpdateRuleTrigger(prevOption) {
    if (prevOption) {
      this.saveTemplate(prevOption);
    }

    this.applyTemplate();
  }
}
