export default {
  "wrapper": "qXN",
  "section-wrapper": "qXv",
  "header": "qXo",
  "title": "qXn body-1",
  "close-icon": "qXi",
  "section": "qXA",
  "section-title": "qXY caption-bold",
  "labels": "qXh",
  "labels-title": "qXf",
  "section-checkbox": "qXK",
  "grow": "qXG scroll",
  "label": "qXr",
  "label-spacing": "qXb",
  "buttons": "qXy"
};
