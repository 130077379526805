/* import __COLOCATED_TEMPLATE__ from './org-deactivation.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class DeactivationRequirementsComponent extends Component {
  @service organizationManager;

  get routeName() {
    let { hasKycKybUpdateFeature } = this.organizationManager.organization;

    return hasKycKybUpdateFeature ? 'settings.company-profile' : 'settings.organization-profile';
  }
}
