export default {
  "qonto-pilot-modal": "cpz",
  "content": "cpa",
  "header": "cpH",
  "transition-state-container": "cpO",
  "lottie": "cpm",
  "prompt-input": "cpp",
  "buttons": "cpx",
  "footer": "cpw caption",
  "divider": "cpN",
  "illustration": "cpv",
  "icon": "cpo",
  "quick-actions-container": "cpn",
  "action": "cpi body-2",
  "action-title": "cpA",
  "disabled": "cpY",
  "action-tag": "cph caption-bold",
  "hotkey": "cpf body-1",
  "footer-action": "cpK",
  "grow": "cpG",
  "footer-feedback-link": "cpr",
  "buttons-container": "cpb",
  "transfer-details-container": "cpy",
  "error-container": "cpU",
  "option-iban": "cpj",
  "actions": "cpW",
  "title": "cpu",
  "group-title": "cxS caption-bold",
  "no-result": "cxc",
  "lottie-illustration": "cxq",
  "beta-badge": "cxZ",
  "hotkeys": "cxR"
};
