export default {
  "section": "coG",
  "header-section": "cor",
  "header-title": "cob",
  "header-item": "coy",
  "item": "coU",
  "center-align": "coj",
  "content-gap": "coW",
  "cta-footer": "cou",
  "section-content": "cnS"
};
