export default {
  "wrapper": "qqU",
  "header": "qqj mb-48",
  "form-title": "qqW title-2 mb-16",
  "form-subtitle": "qqu body-2",
  "form": "qZS",
  "section": "qZc mb-48",
  "section-title": "qZq title-3 mb-16",
  "form-footer": "qZZ",
  "footer-btn": "qZR",
  "transfer-form": "qZQ"
};
