export default {
  "details": "qdi",
  "details-header": "qdA",
  "details-content": "qdY",
  "details-footer": "qdh",
  "invoice-status-container": "qdf body-2",
  "invoice-payment-date": "qdK",
  "overdue": "qdG",
  "details-list": "qdr",
  "details-list-item": "qdb",
  "details-list-item-label": "qdy body-2",
  "details-list-item-value": "qdU",
  "description": "qdj",
  "description-value": "qdW",
  "edit-button": "qdu body-2",
  "edit-icon": "qIS"
};
