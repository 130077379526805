export default {
  "custom-period-wrapper": "cLz",
  "fadeIn": "cLa",
  "custom-period-description": "cLH body-2",
  "header": "cLO",
  "arrow-left": "cLm",
  "body": "cLp",
  "period-wrapper": "cLx",
  "back-button": "cLw",
  "footer": "cLN"
};
