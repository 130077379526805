/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesTableHeaderComponent extends Component {
  @service intl;
  @service abilities;

  get showInvoiceSelector() {
    return (
      variation('feature-invoices-prioritize-payments') &&
      this.abilities.can('update supplier-invoice') &&
      this.args.status === INVOICE_STATUSES.toReview
    );
  }

  get paidAndArchived() {
    return [
      {
        name: 'supplier_name',
        label: this.intl.t('supplier-invoices.table.header.supplier-name'),
        sortable: true,
        width: '32%',
      },
      !variation('feature-invoices-prioritize-payments') && {
        name: 'due_date',
        label: this.intl.t('supplier-invoices.table.header.due-date'),
        sortable: true,
        width: '24%',
      },
      {
        name: 'payment_date',
        label: this.intl.t('supplier-invoices.table.header.payment-date'),
        sortable: true,
        width: '24%',
      },
      variation('feature-invoices-prioritize-payments') && {
        name: 'status',
        label: this.intl.t('supplier-invoices.table.header.status'),
        sortable: true,
        width: '24%',
      },
      {
        name: 'total_amount',
        label: this.intl.t('supplier-invoices.table.header.amount'),
        sortable: true,
        width: '10%',
        align: 'right',
      },
      {
        name: 'quick_actions',
        ariahidden: 'true',
      },
    ].filter(Boolean);
  }

  get columnTypes() {
    return {
      to_review: [
        this.showInvoiceSelector && {
          name: 'selector',
          type: 'checkbox',
        },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '32%',
        },
        {
          name: 'file_name',
          label: this.intl.t('supplier-invoices.table.header.file-name'),
          sortable: true,
          width: '24%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '24%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ].filter(Boolean),
      pending: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: this.abilities.can('load transfer request') ? '32%' : '44%',
        },
        ...(this.abilities.can('load transfer request')
          ? [
              {
                name: 'requester',
                label: this.intl.t('request.table.header.requester'),
                sortable: true,
                width: '24%',
              },
            ]
          : []),
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: this.abilities.can('load transfer request') ? '24%' : '36%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      scheduled: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '32%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '24%',
        },
        {
          name: 'scheduled_date',
          label: this.intl.t('supplier-invoices.table.header.operation-date'),
          sortable: true,
          width: '24%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      paid: this.paidAndArchived,
      archived: this.paidAndArchived,
    };
  }

  get columns() {
    return this.columnTypes[this.args.status?.split(',')[0]];
  }

  get sortField() {
    return this.args.sortBy?.split(':')[0];
  }

  get sortOrder() {
    return this.args.sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  }

  @action
  changeSortBy(name) {
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    return this.args.changeSortBy?.(`${name}:${sortOrder}`);
  }
}
