export default {
  "custom-export-loading": "qCG",
  "title": "qCr",
  "description": "qCb",
  "section": "qCy",
  "placeholder-avatar": "qCU",
  "_with-avatar": "qCj",
  "_with-list": "qCW",
  "list-item": "qCu",
  "radio": "qkS",
  "header": "qkc",
  "header-line": "qkq"
};
