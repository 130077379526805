export default {
  "wrapper": "cbn",
  "card-header": "cbi",
  "subtitle-caption": "cbA",
  "estimated-earnings": "cbY",
  "progress": "cbh",
  "progress-bar": "cbf",
  "progress-bar-text": "cbK",
  "subtitle": "cbG",
  "badge": "cbr",
  "core-information": "cbb",
  "secondary-information": "cby",
  "remuneration-table": "cbU",
  "name": "cbj title-3",
  "separator": "cbW",
  "remuneration-line": "cbu",
  "german-disclaimer": "cyS",
  "table": "cyc",
  "table-right": "cyq"
};
