export default {
  "vat-lines": "qHy",
  "vat-line": "qHU",
  "vat-input": "qHj",
  "vat-rate": "qHW",
  "vat-option-button": "qHu",
  "disabled": "qOS",
  "vat-option-tooltip": "qOc",
  "remove-label": "qOq",
  "remove-button": "qOZ",
  "vat-total": "qOR",
  "vat-total-amount": "qOQ",
  "add-vat-line": "qOe",
  "with-vat-lines": "qOB"
};
