export default {
  "gap-32": "cwL",
  "fieldset": "cwT",
  "date-picker": "cwz",
  "with-performance-date": "cwa",
  "mt-4": "cwH",
  "clients": "cwO",
  "dropdown-option": "cwm",
  "dropdown-content": "cwp",
  "select-client": "cwx",
  "client-option": "cww",
  "icon-tooltip": "cwN",
  "payment-details": "cwv",
  "dates-container": "cwo",
  "automatic-number-label": "cwn",
  "label": "cwi",
  "numbering-container": "cwA",
  "fix-width": "cwY",
  "customer-error": "cwh",
  "amount-text": "cwf",
  "header-text-field-container": "cwK",
  "header-text-field": "cwG",
  "header-text-field-close-button": "cwr",
  "mt-16": "cwb"
};
