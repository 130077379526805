export default {
  "company-logo": "cFy",
  "list-item": "cFU",
  "link": "cFj",
  "title-wrapper": "cFW",
  "title": "cFu body-2",
  "subtitle": "cgS caption",
  "truncated-text": "cgc",
  "image-placeholder": "cgq"
};
