/* import __COLOCATED_TEMPLATE__ from './show-members.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import Component from '@glimmer/component';

import { ROLES } from 'qonto/constants/membership';

const MEMBERS_SHOWN = 4;
const ROLES_FILTER_VALUES = {
  [ROLES.OWNER]: 1,
  [ROLES.ADMIN]: 2,
  [ROLES.MANAGER]: 3,
  [ROLES.REPORTING]: 4,
  [ROLES.EMPLOYEE]: 5,
};

export default class ShowMembersComponent extends Component {
  get remainingMembershipCount() {
    return this.args.memberships.length - MEMBERS_SHOWN;
  }

  get showRemainingMembershipCount() {
    return this.args.memberships.length > MEMBERS_SHOWN;
  }

  get sortedMemberships() {
    let memberships = [...this.args.memberships];
    memberships.sort((member1, member2) => {
      if (ROLES_FILTER_VALUES[member1.role] === ROLES_FILTER_VALUES[member2.role]) {
        if (member1.firstName < member2.firstName) {
          return -1;
        } else if (member1.firstName > member2.firstName) {
          return 1;
        } else {
          return 0;
        }
      }
      return ROLES_FILTER_VALUES[member1.role] - ROLES_FILTER_VALUES[member2.role];
    });
    return memberships.slice(0, MEMBERS_SHOWN);
  }
}
