export default {
  "row": "qar",
  "counterparty-name": "qab",
  "counterparty-status": "qay",
  "amount": "qaU",
  "next-date": "qaj",
  "end-date": "qaW",
  "frequency": "qau",
  "active": "qHS",
  "cell": "qHc body-2",
  "no-padding": "qHq",
  "cell-content": "qHZ",
  "counterparty": "qHR",
  "status-avatar": "qHQ",
  "beneficiary-name": "qHe",
  "empty": "qHB",
  "disabled": "qHE",
  "fadein-item": "qHd"
};
