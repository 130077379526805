export default {
  "display-block": "qzy",
  "pay-later-tooltip": "qzU",
  "disabled": "qzj",
  "stepper": "qzW",
  "installments": "qzu",
  "flex-items": "qaS",
  "day-label": "qac body-2",
  "installment-amount": "qaq body-1",
  "spacing": "qaZ",
  "info-icon": "qaR",
  "detail": "qaQ",
  "detail-value": "qae",
  "extra": "qaB",
  "extra-value": "qaE",
  "badge": "qad",
  "title": "qaI",
  "link": "qat"
};
