/* import __COLOCATED_TEMPLATE__ from './counter.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { cell, resource, resourceFactory } from 'ember-resources';

const DELAY_FACTOR = macroCondition(isTesting()) ? 1 : 10;

function calculateIncrement(start, end, animationDuration) {
  return (end - start) / (animationDuration / DELAY_FACTOR) || 1;
}

/*
  This function is used to prevent the counter from going over the end value
*/
function adjustIncrement(increment, limit, counter) {
  if (increment > limit - counter) {
    return limit - counter;
  }

  return increment;
}

const Counter = resourceFactory((start, end, timeout, animationDuration) =>
  resource(({ on }) => {
    let counter = cell(start);
    let increment = calculateIncrement(start, end, animationDuration);

    let intervalId;
    let timeoutId = setTimeout(() => {
      intervalId = setInterval(() => {
        if (start > end) {
          if (counter.current < end) {
            clearInterval(intervalId);
          } else {
            counter.current += adjustIncrement(increment, start, counter.current);
          }
        } else {
          if (counter.current >= end) {
            clearInterval(intervalId);
          } else {
            counter.current += adjustIncrement(increment, end, counter.current);
          }
        }
      }, DELAY_FACTOR);
    }, timeout);

    on.cleanup(() => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    });

    return counter;
  })
);

export default class SupplierInvoicesCounterComponent extends Component {
  @service intl;

  counter = resource(({ use }) => {
    let { previousValue = 0, currentValue, delay, animationDuration } = this.args;

    // This ensures we display the currentValue in case the currentValue is undefined
    // e.g. there is a network error
    if (currentValue === undefined) {
      currentValue = previousValue;
    }

    let counter = use(Counter(previousValue, currentValue, delay, animationDuration));

    return () => this.intl.formatNumber(counter.current, { currency: 'EUR', style: 'currency' });
  });

  get previousValue() {
    return this.args.previousValue || 0;
  }
}
