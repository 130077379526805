export default {
  "donut-chart-container": "cVv",
  "donut-chart-wrapper": "cVo",
  "chart": "cVn",
  "donut-chart-legend-container": "cVi caption",
  "donut-chart-legend-item-empty": "cVA",
  "donut-chart-legend-bullet-empty": "cVY",
  "donut-chart-legend-bullet": "cVh",
  "donut-chart-legend-text-empty": "cVf",
  "donut-chart-legend-text": "cVK",
  "donut-chart-custom-label-uncategorized": "cVG",
  "custom-label-uncategorized-button": "cVr body-1",
  "donut-chart-legend": "cVb",
  "donut-chart-legend-item": "cVy",
  "donut-chart-legend-other-item": "cVU",
  "donut-chart-legend-uncategorized-item": "cVj",
  "donut-chart-legend-text-name": "cVW",
  "tooltip": "cVu"
};
