/* import __COLOCATED_TEMPLATE__ from './theme-selector-item.hbs'; */
import Component from '@glimmer/component';

import APPEARANCE from 'qonto/constants/appearance-setting';

const TRACKING_EVENT_NAMES = {
  [APPEARANCE.LIGHT]: 'appearance_light_selected',
  [APPEARANCE.DARK]: 'appearance_dark_selected',
  [APPEARANCE.SYSTEM]: 'appearance_automatic_selected',
};

export default class ThemeSelectorItem extends Component {
  get trackingEventName() {
    let { value } = this.args;
    return TRACKING_EVENT_NAMES[value];
  }
}
