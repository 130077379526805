/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';
export default class TransactionsSidebarFooter extends Component {
  @service segment;

  get showHelpSection() {
    return !this.args.highlightedItem.isInstantTransfer;
  }

  @action
  onHelpButtonClick() {
    let event = variation('feature--prismic-zendesk-migration')
      ? 'transaction-helper_button_clicked'
      : 'navigation_transactionhelper_clicked';
    this.segment.track(event, { transaction_type: this.args.highlightedItem.operationType });
    this.args.handleShowHelpSection();
  }
}
