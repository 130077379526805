export default {
  "header-cell": "qLx",
  "empty": "qLw",
  "header-type": "qLN",
  "header-requester": "qLv",
  "header-amount": "qLo",
  "header-approval": "qLn",
  "header-content": "qLi caption-bold",
  "active": "qLA",
  "row-compact": "qLY",
  "row-sidebar": "qLh",
  "hidden": "qLf",
  "animated": "qLK",
  "fadein-item": "qLG",
  "animated-cell": "qLr"
};
