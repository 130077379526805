/* import __COLOCATED_TEMPLATE__ from './success-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class TransfersSuccessModalComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;

  animationReady = false;

  get title() {
    return this.intl.t('requests.transfers.modals.requester.success.title');
  }

  get description() {
    return this.intl.t('requests.transfers.modals.requester.success.subtitle');
  }

  @action
  redirectToDetails() {
    this.args.close(true);
  }
}
