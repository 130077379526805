export default {
  "container": "cnc",
  "form-container": "cnq",
  "content": "cnZ",
  "fields": "cnR",
  "input-settings": "cnQ",
  "current-year": "cne",
  "next-invoice-number": "cnB",
  "preview-container": "cnE",
  "actions": "cnd",
  "close-button": "cnI btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cnt",
  "preview-image": "cnM"
};
