export default {
  "row": "qcX",
  "active": "qcC",
  "animated": "qck",
  "fadein-item": "qcJ",
  "cell": "qcs body-2",
  "cell-content": "qcF",
  "subtitle": "qcg caption",
  "no-padding": "qcD",
  "note": "qcV",
  "cell-amount": "qcL",
  "cell-content-amount": "qcT body-1"
};
