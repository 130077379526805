/* import __COLOCATED_TEMPLATE__ from './dynamic-form.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { buildValidations } from 'ember-cp-validations';
import { TrackedObject } from 'tracked-built-ins';

import { VALIDATION_TRIGGER_TYPES } from 'qonto/constants/dynamic-form';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { deepMerge } from 'qonto/utils/deep-merge';
import {
  createDefaultMap,
  extendFieldProperties,
  extendFieldsetProperties,
  mergeAdjacentFieldsets,
  transformFieldsToFieldsets,
} from 'qonto/utils/dynamic-form';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { unflatten } from 'qonto/utils/unflatten';
import {
  extractCustomValidators,
  extractFieldsValidators,
  mergeValidators,
} from 'qonto/utils/validators';

export default class TransfersInternationalOutDynamicFormComponent extends Component {
  @service intl;

  @tracked groupType = this.args.formData[0].type;

  constructor() {
    super(...arguments);
    this.fieldValuesTracker = new TrackedObject();
    this.fieldValidationStatusTracker = new TrackedObject();
  }

  isValidationEnabledFor = fieldKey => {
    return Boolean(
      this.fieldValidationStatusTracker[this.#getIdentifier(this.currentGroup.type, fieldKey)]
    );
  };

  get allFields() {
    return this.flattenFields.map(extendFieldProperties);
  }

  get currentGroup() {
    return this.args.formData?.find(formDataGroup => formDataGroup.type === this.groupType);
  }

  get fields() {
    return this.omitOptional ? this.requiredFields : this.allFields;
  }

  get fieldsets() {
    let fieldsets = transformFieldsToFieldsets(this.fields);
    let mergedFieldsets = mergeAdjacentFieldsets(fieldsets);
    return mergedFieldsets.map(extendFieldsetProperties);
  }

  get flattenFields() {
    return this.currentGroup?.fields?.map(({ group }) => group).flat() || [];
  }

  get formData() {
    return Object.keys(this.formDataModel).reduce((accumulator, key) => {
      if (this.formDataModel.hasOwnProperty(key) && key !== '_super') {
        accumulator[key] = this.formDataModel.get(key);
      }
      return accumulator;
    }, {});
  }

  @cached
  get formDataModel() {
    return this.#createFormDataModel({
      fields: this.fields,
      validators: this.validators,
    });
  }

  get omitOptional() {
    return this.args.omitOptional ?? false;
  }

  get requiredFields() {
    return this.allFields.filter(({ required }) => required);
  }

  get submissionText() {
    return this.args.submissionText ?? this.intl.t('international-out.dynamic-form.submit');
  }

  get validationTrigger() {
    return this.args.validationTrigger ?? VALIDATION_TRIGGER_TYPES.INPUT;
  }

  get validators() {
    return this.args.validators ?? {};
  }

  #createDefaultFieldKeyValuesMap(fields) {
    return createDefaultMap({
      keys: fields.map(({ key }) => key),
      defaultValue: null,
    });
  }

  #createFormDataModel({ fields, validators }) {
    let FormValidationModel = this.#createFormValidationModel({ fields, validators });

    class FormDataModel extends EmberObject.extend(FormValidationModel) {
      @service intl;
      @service localeManager;
    }

    let initialFormData = deepMerge(
      this.#createDefaultFieldKeyValuesMap(fields),
      this.#getPreviousValues(fields)
    );

    return FormDataModel.create(getOwner(this).ownerInjection(), {
      ...unflatten(initialFormData),
    });
  }

  #createFormValidationModel({ fields, validators }) {
    let fieldsValidators = extractFieldsValidators(fields);
    let customValidators = extractCustomValidators(validators);
    return buildValidations(mergeValidators(fieldsValidators, customValidators));
  }

  #getIdentifier = (groupType, fieldKey) => {
    return `${groupType}.${fieldKey}`;
  };

  #getPreviousValues(fields) {
    let currentFormDataGroupKeys = fields.map(({ key }) =>
      this.#getIdentifier(this.currentGroup.type, key)
    );

    return Object.fromEntries(
      Object.entries(this.fieldValuesTracker)
        .filter(([key]) => currentFormDataGroupKeys.includes(key))
        .map(([key, value]) => {
          let [, ...destructuredFieldKey] = key.split('.');
          return [destructuredFieldKey.join('.'), value];
        })
    );
  }

  #scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  #updateFieldValidationStatus({ fieldKey, isValidationEnabled }) {
    this.fieldValidationStatusTracker[this.#getIdentifier(this.currentGroup.type, fieldKey)] =
      isValidationEnabled;
  }

  @action
  onClose(fieldKey) {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
  }

  @action
  onFocusIn(fieldKey) {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: false });
  }

  @action
  onFocusOut(fieldKey) {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
  }

  @action
  onRefresh() {
    this.args.onRefresh?.({
      type: this.groupType,
      data: this.formData,
    });
  }

  onSubmitTask = dropTask(async () => {
    let { onError, onSubmit } = this.args;

    let { validations } = await this.formDataModel.validate();

    this.fields.forEach(({ key: fieldKey }) => {
      this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
    });

    if (validations.isValid) {
      onSubmit?.({
        type: this.groupType,
        data: this.formData,
      });
    } else {
      let { error } = validations;
      onError?.({
        type: error.type,
        attribute: error.attribute,
      });
      this.#scrollToError();
    }
  });

  onUpdateTask = restartableTask(async (fieldKey, value) => {
    this.fieldValuesTracker[this.#getIdentifier(this.currentGroup.type, fieldKey)] = isEmpty(value)
      ? null
      : value;

    await timeout(DEBOUNCE_MS);

    if (this.validationTrigger === VALIDATION_TRIGGER_TYPES.INPUT) {
      this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
    }
  });
}
