export default {
  "review": "qRK",
  "review-content": "qRG",
  "review-parameters": "qRr",
  "row": "qRb",
  "row__border": "qRy",
  "label": "qRU",
  "value": "qRj",
  "error": "qRW",
  "tooltip-icon": "qRu",
  "terms-link": "qQS"
};
