/* import __COLOCATED_TEMPLATE__ from './radio-dropdown.hbs'; */
import Component from '@glimmer/component';

import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';

export default class RadioDropdownComponent extends Component {
  animationDuration = 240;

  // eslint-disable-next-line require-yield
  *transition({ insertedSprites, removedSprites }) {
    insertedSprites.forEach(sprite => {
      fadeIn(sprite);
    });
    removedSprites.forEach(sprite => {
      fadeOut(sprite);
    });
  }

  get isChecked() {
    return this.args.groupValue === this.args.value;
  }
}
