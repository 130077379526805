export default {
  "container": "cTL",
  "indicator-container": "cTT",
  "transactions-container": "cTz",
  "indicator": "cTa",
  "cashflow-container": "cTH",
  "cashflow": "cTO",
  "donut-container": "cTm",
  "donut": "cTp"
};
