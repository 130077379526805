export default {
  "container": "cav",
  "label": "cao",
  "disabled": "can",
  "small": "cai caption",
  "large": "caA body-2",
  "caption": "caY",
  "amount": "cah",
  "right-aligned": "caf",
  "expanded": "caK",
  "amount--current": "caG",
  "amount--total": "car",
  "amount--sep": "cab"
};
