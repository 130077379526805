/* import __COLOCATED_TEMPLATE__ from './blockers-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class BlockersModalComponent extends Component {
  @service intl;
  @service router;

  constructor() {
    super(...arguments);
    let { organization } = this.subscription;

    if (!organization.get('bankAccounts.length')) {
      organization.loadBankAccounts();
    }
  }

  get subscription() {
    return this.args.data.subscription;
  }

  get membershipsToRevokeCount() {
    return this.args.data.membershipsToRevokeCount;
  }

  get blockers() {
    let { intl } = this;
    let { pricePlan, organization } = this.subscription;
    let accountantsCountToDisable = organization.get('accountantsCountingTowardsPlanLimitCount');

    let blockersMap = {
      user_limit_reached: {
        link: 'members',
        title: intl.t('subscription.change.blockers.team.title', {
          count: this.membershipsToRevokeCount,
        }),
        linkText: intl.t('subscription.change.blockers.team.link'),
        description: intl.t('subscription.change.blockers.team.subtitle', {
          count1: pricePlan.get('userLimit'),
          count2: this.membershipsToRevokeCount,
        }),
      },
      accountant_access_disallowed: {
        link: 'members',
        title: intl.t('subscription.change.blockers.accountant.title', {
          count: accountantsCountToDisable,
        }),
        linkText: intl.t('subscription.change.blockers.accountant.link'),
        description: intl.t('subscription.change.blockers.accountant.subtitle', {
          count: accountantsCountToDisable,
        }),
      },
      bank_account_limit_reached: {
        link: 'accounts',
        title: intl.t('subscription.change.blockers.accounts.title', {
          count: this.bankAccountCountToDisable,
        }),
        linkText: intl.t('subscription.change.blockers.accounts.link'),
        description: intl.t('subscription.change.blockers.accounts.subtitle', {
          count1: pricePlan.get('bankAccountLimit'),
          count2: this.bankAccountCountToDisable,
        }),
      },
    };

    return this.args.data.errors.map(error => blockersMap[error.code]);
  }

  get bankAccountCountToDisable() {
    let { pricePlan, organization } = this.subscription;
    let bankAccounts = organization.get('bankAccounts');
    if (bankAccounts) {
      return (
        bankAccounts.filter(({ status }) => status === 'active' || status === 'pending').length -
        pricePlan.get('bankAccountLimit')
      );
    }
  }

  @action
  transitionTo(routeName) {
    this.args.close();
    this.router.transitionTo(routeName);
    let { pricePlan, organization, recurrence } = this.subscription;

    let data = {
      code: pricePlan.get('code'),
      orgId: organization.get('id'),
      recurrence,
    };
    safeLocalStorage.setItem('price-plan-change-in-progress', JSON.stringify(data));
  }
}
