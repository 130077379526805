export default {
  "cell": "qty",
  "cell-label-container": "qtU",
  "cell-label": "qtj",
  "overflow": "qtW",
  "sub-label": "qtu body-2",
  "overdue": "qMS",
  "file-name-warning": "qMc",
  "missing": "qMq",
  "warning-icon": "qMZ",
  "flex-label": "qMR",
  "with-separator": "qMQ",
  "self-invoice-icon": "qMe"
};
