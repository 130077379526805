export default {
  "button-wrapper": "qki",
  "btn-filter": "qkA",
  "name-wrapper": "qkY",
  "preset-name": "qkh",
  "cancel-placeholder": "qkf",
  "cancel-button": "qkK",
  "dropdown-opened": "qkG",
  "dropdown-closed": "qkr",
  "dropdown-menu": "qkb",
  "loading-item": "qky",
  "placeholder": "qkU",
  "list-body": "qkj",
  "list-item": "qkW",
  "error-state": "qku body-2",
  "warning": "qJS",
  "warning-icon": "qJc",
  "footer": "qJq",
  "add-icon": "qJZ",
  "empty-state": "qJR"
};
