export default {
  "card": "cHW",
  "card-primary": "cHu",
  "content": "cOS",
  "content-primary": "cOc",
  "disabled": "cOq",
  "illustration": "cOZ",
  "illustration-primary": "cOR",
  "illustration-disabled": "cOQ",
  "cta": "cOe",
  "dismiss-button": "cOB",
  "buttons": "cOE",
  "required-badge": "cOd"
};
