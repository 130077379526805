export default {
  "wrapper": "qDE",
  "ember-power-select-dropdown": "qDd",
  "status": "qDI body-1",
  "error": "qDt",
  "success": "qDM",
  "type-container": "qDP",
  "type-close": "qDl",
  "date": "qDX",
  "close-icon": "qDC",
  "container": "qDk",
  "container-picto": "qDJ",
  "avatar": "qDs",
  "container-picto-status": "qDF",
  "general": "qDg",
  "general-amount": "qDD",
  "unprocessed": "qDV",
  "fx-rate": "qDL",
  "general-counterparty": "qDT body-2",
  "infobox": "qDz",
  "account-infobox": "qDa qDz",
  "initiator-infobox": "qDH qDz",
  "transaction-infobox": "qDO qDz",
  "repeat-infobox": "qDm qDz",
  "item": "qDp body-2",
  "eye-icon": "qDx",
  "repeat-transfer-action": "qDw",
  "disclaimer": "qDN",
  "start-verification-link": "qDv"
};
