export default {
  "wrapper": "cYE",
  "tabs": "cYd",
  "tabs-nav": "cYI",
  "tabs-panel-wrapper": "cYt",
  "list-head": "cYM",
  "list": "cYP",
  "footer": "cYl",
  "reward-amount": "cYX",
  "earned": "cYC",
  "pending": "cYk",
  "empty-state-wrapper": "cYJ",
  "empty-state": "cYs",
  "illustration": "cYF",
  "error-wrapper": "cYg",
  "error-illustration": "cYD",
  "error-description": "cYV",
  "spinner": "cYL",
  "total-earned": "cYT"
};
