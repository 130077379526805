export default {
  "item-wrapper": "qEb",
  "title-sub-wrapper": "qEy",
  "icon-title-wrapper": "qEU",
  "icon": "qEj",
  "icon-wrapper": "qEW",
  "title": "qEu body-1",
  "subtitle": "qdS body-2",
  "amount": "qdc body-1"
};
