export default {
  "fileupload": "qHv",
  "file": "qHo",
  "label": "qHn",
  "zone": "qHi",
  "error": "qHA",
  "hide": "qHY",
  "dropzone": "qHh",
  "hidden": "qHf",
  "upload-icon": "qHK",
  "dropping": "qHG",
  "bounce2": "qHr",
  "error-message": "qHb"
};
