export default {
  "row": "crN",
  "active": "crv",
  "dot": "cro",
  "red": "crn",
  "green": "cri",
  "yellow": "crA",
  "status": "crY",
  "align-right": "crh",
  "animated": "crf",
  "fadein-item": "crK",
  "cell": "crG body-2",
  "cell-content": "crr",
  "amount": "crb body-2",
  "subtitle": "cry caption",
  "padding-left": "crU",
  "padding-right": "crj",
  "note": "crW"
};
