export default {
  "section-container": "chC",
  "disclaimer-section": "chk",
  "trip-title": "chJ caption-bold mb-16",
  "google-link-container": "chs",
  "link-icon": "chF",
  "trip-details": "chg body-2",
  "trip-details-icon": "chD",
  "trip-details-title": "chV",
  "subtitle": "chL caption-bold mb-16",
  "disclaimer": "chT",
  "visualizer": "chz"
};
