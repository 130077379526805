/* import __COLOCATED_TEMPLATE__ from './fees.hbs'; */
import Component from '@glimmer/component';

import { Date } from 'qonto/react/components/transactions/sidebar/header/date';

export default class TransactionsSidebarHeaderBaseFees extends Component {
  Date = Date;

  get showAccountName() {
    let { showAccountName, highlightedItem: transaction } = this.args;

    return showAccountName || transaction.subject.get('isSDDCollectionFee');
  }
}
