export default {
  "y-axis-placeholder": "cVg",
  "y-axis-navigable": "cVD",
  "y-axis-tick": "cVV",
  "x-axis-placeholder": "cVL",
  "x-axis-navigable": "cVT",
  "x-axis-tick-wrapper": "cVz",
  "x-axis-tick": "cVa",
  "chart-loading-renderer": "cVH"
};
