/* import __COLOCATED_TEMPLATE__ from './tile.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ProgressBar } from 'design-system-kit';

import { DEFAULT_ORIGIN_URL, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';

export default class RemuneratedAccountsTile extends Component {
  progressBar = ProgressBar;

  @service router;

  get origin() {
    let origin = DEFAULT_ORIGIN_URL;
    if (!this.router.currentRouteName?.includes(DEFAULT_ORIGIN_URL)) {
      origin = SAVINGS_DEFAULT_URL;
    }
    return origin;
  }

  get maxMaturityRate() {
    return this.args.remuneratedAccount.interestRates.at(-1).rate / 100;
  }
}
