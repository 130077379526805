export default {
  "card-header": "qsd",
  "counterparty-name": "qsI",
  "pan-section": "qst header-section body-2",
  "infobox": "qsM header-section",
  "see-card-button": "qsP",
  "card-identifier-section": "qsl",
  "card-icon": "qsX",
  "nickname": "qsC body-1",
  "pan": "qsk body-2"
};
