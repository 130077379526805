/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import Component from '@glimmer/component';

import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesTableCellComponent extends Component {
  get showTooltipOrEInvoiceLabel() {
    let { isEInvoice, hasSelfInvoice } = this.args.cell;
    return isEInvoice || hasSelfInvoice;
  }

  get isStatus() {
    return Object.values(INVOICE_STATUSES).includes(this.args.cell);
  }
}
