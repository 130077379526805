/* import __COLOCATED_TEMPLATE__ from './information.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class TransferMultiInformation extends Component {
  @service organizationManager;

  @reads('organizationManager.organization') organization;
  @reads('organization.hasMultipleActiveCurrentRemuneratedAccounts') showAccountSelect;
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts') activeAccounts;
}
