export default {
  "sidebar-header": "qsV",
  "sidebar-header__top": "qsL",
  "sidebar-header__body": "qsT",
  "sidebar-header__content": "qsz",
  "sidebar-header__date": "qsa",
  "infobox": "qsH",
  "infobox--link": "qsO",
  "ghost": "qsm",
  "fail": "qsp",
  "r-transaction-disclaimer": "qsx",
  "hold-disclaimer": "qsw",
  "transaction-link": "qsN body-2",
  "icon-link": "qsv"
};
