/* import __COLOCATED_TEMPLATE__ from './notes.hbs'; */
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class TransactionsSidebarNotesComponent extends Component {
  DEBOUNCE_MS = 500;

  @service segment;
  @service store;

  @action
  updateNoteValue({ value, id }) {
    let { transaction } = this.args;
    this.segment.track('transaction_analytic_additional_information_entered', {
      source: this.args.type,
    });
    if (id !== transaction.id) {
      transaction = this.store.peekRecord('transaction', id);
    }
    this.args.saveTransaction(transaction, 'note', value);
  }

  @action
  debouncedUpdateNoteValue(value) {
    debounce(this, this.updateNoteValue, { value, id: this.args.transaction.id }, this.DEBOUNCE_MS);
  }
}
