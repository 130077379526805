export default {
  "team-card": "qlW",
  "card-link": "qlu",
  "shadow": "qXS",
  "history-button": "qXc",
  "cards-button": "qXq",
  "menu-item": "qXZ",
  "subtitle": "qXR",
  "delete-team": "qXQ"
};
