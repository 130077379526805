export default {
  "container": "qJe",
  "item": "qJB caption-bold",
  "hover": "qJE",
  "active": "qJd",
  "name-wrapper": "qJI",
  "name": "qJt body-2",
  "count": "qJM",
  "actions-placeholder": "qJP",
  "actions": "qJl",
  "show": "qJX",
  "action-button": "qJC"
};
