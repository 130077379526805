export default {
  "header": "cGx",
  "header-type": "cGw",
  "close": "cGN",
  "close-icon": "cGv",
  "header-infos": "cGo",
  "title": "cGn body-1",
  "declined": "cGi",
  "canceled": "cGA",
  "approved": "cGY",
  "pending": "cGh",
  "date": "cGf body-2",
  "mileage-icon": "cGK",
  "distance-subtitle": "cGG body-2",
  "link-icon": "cGr",
  "container": "cGb",
  "picto": "cGy",
  "general": "cGU",
  "amount": "cGj",
  "calculation-container": "cGW",
  "counterparty": "cGu body-2",
  "infobox": "crS"
};
