export default {
  "row": "qFf",
  "item-bulk": "qFK",
  "active": "qFG",
  "disabled": "qFr",
  "cell": "qFb body-2",
  "not-disabled": "qFy",
  "item-amount-disabled": "qFU",
  "item-amount-credit": "qFj",
  "no-padding": "qFW",
  "cell-content": "qFu",
  "centered-cell": "qgS",
  "item-counterparty-icon": "qgc mr-16",
  "avatar": "qgq",
  "item-counterparty-name": "qgZ",
  "item-counterparty-name-status": "qgR",
  "disputing-chargeback": "qgQ",
  "item-counterparty-name-text": "qge",
  "item-amount": "qgB body-1",
  "item-amount-foreign": "qgE",
  "attachment-icon": "qgd",
  "item-method": "qgI",
  "bank-account-avatar": "qgt",
  "attachment-status-dot": "qgM",
  "attached": "qgP",
  "missing": "qgl",
  "item-attachment": "qgX",
  "fadein-item": "qgC"
};
