export default {
  "wrapper": "czx",
  "header": "czw",
  "fadeIn": "czN",
  "header-title": "czv title-4",
  "transactions-container": "czo",
  "placeholder-title": "czn",
  "transaction-item-list-container": "czi",
  "transaction-item-list": "czA",
  "item": "czY"
};
