/* import __COLOCATED_TEMPLATE__ from './period-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge } from 'design-system-kit';

import { PERIOD_KEYS } from 'qonto/constants/overview';

export default class PeriodSelectorComponent extends Component {
  badgeHighlight = Badge;

  @service flowLinkManager;
  @service intl;
  @service modals;
  @service segment;
  @service subscriptionManager;

  @tracked showCustomPeriodForm = false;
  total = 0;

  get description() {
    if (this.args.type !== 'date-picker') {
      return this.intl.t('overview.period-selection.custom-period-builder.body');
    }
  }

  get isCustomPeriodSelected() {
    return this.args.selectedPeriod?.key === PERIOD_KEYS.CUSTOM_PERIOD;
  }

  get selectedIndex() {
    if (this.isCustomPeriodSelected) {
      return this.args.periods.length;
    }

    let selectedPeriod = this.args.selectedPeriod;

    return this.args.periods.findIndex(({ interval, key }) => {
      if (selectedPeriod?.key) {
        return selectedPeriod.key === key;
      }
      return selectedPeriod.interval === interval;
    });
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('advanced_dashboard');
  }

  @action
  onOpen(dropdownApi) {
    this.dropdownApi = dropdownApi;
    this.total = 0;
  }

  @action
  getIndex() {
    let result = this.total++;
    return result;
  }

  @action onTrialClick() {
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  @action
  toggleCustomPeriodForm() {
    this.showCustomPeriodForm = !this.showCustomPeriodForm;
    if (!this.showCustomPeriodForm) {
      this.total = 0;
      document.querySelector(`[data-ebd-id="${this.dropdownApi.uniqueId}-trigger"]`).focus();
    }
  }

  @action
  openUpsellModal() {
    this.segment.track('upsell_dashboard_clicked', {
      widget_type: this.args.trackingWidgetType,
      upsell_type: 'period_selection',
    });

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'discover-overview',
      queryParams: { upsellTrigger: 'advancedDashboard' },
    });
  }
}
