/* import __COLOCATED_TEMPLATE__ from './category.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SUBJECT_TYPES } from 'qonto/constants/transactions';

export default class TransactionsSidebarCategoryComponent extends Component {
  @service abilities;

  get isActivityTagSelectDisabled() {
    let { isDisabled } = this.args;
    let { isBilling, isTax, isPayLater, credit, isDirectDebitHold } = this.args.highlightedItem;

    return (
      isDisabled ||
      isBilling ||
      isTax ||
      isDirectDebitHold ||
      (isPayLater && credit) ||
      this.abilities.cannot('update transaction')
    );
  }

  get transactionType() {
    let { operationType, side, subjectType } = this.args.highlightedItem;

    // for SDD R_transactions we need to return all available debit categories
    // so here we need to return a special subject type
    if (subjectType === SUBJECT_TYPES.DIRECT_DEBIT_COLLECTION && side === 'debit') {
      return SUBJECT_TYPES.DIRECT_DEBIT_COLLECTION_R_TRANSACTION;
    }

    if (operationType === 'other') {
      return 'Other';
    }

    return subjectType;
  }
}
