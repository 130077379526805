export default {
  "attachment-viewer": "cjR",
  "hide-sidebar": "cjQ",
  "sidebar": "cje",
  "header": "cjB",
  "header-top": "cjE",
  "header-icon": "cjd",
  "header-amount": "cjI",
  "infobox": "cjt",
  "vat": "cjM",
  "validation": "cjP"
};
