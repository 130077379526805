export default {
  "header": "cuk",
  "header-type": "cuJ",
  "close": "cus",
  "close-icon": "cuF",
  "header-infos": "cug",
  "title": "cuD body-1",
  "declined": "cuV",
  "canceled": "cuL",
  "approved": "cuT",
  "date": "cuz body-2",
  "container": "cua",
  "picto": "cuH",
  "request": "cuO",
  "picto-status": "cum",
  "check": "cup",
  "stop": "cux",
  "warning": "cuw",
  "general": "cuN",
  "amount": "cuv",
  "counterparty": "cuo body-2",
  "initiator": "cun",
  "initiator-avatar": "cui",
  "avatar-image": "cuA",
  "initiator-id": "cuY",
  "name": "cuh body-2",
  "role": "cuf caption-bold",
  "infobox": "cuK",
  "account-infobox": "cuG cuK",
  "beneficiary-warning": "cur caption-bold",
  "dates-wrapper": "cub",
  "dates": "cuy",
  "recurrence": "cuU caption-bold",
  "arrow": "cuj",
  "notify-checkbox": "cuW"
};
