export default {
  "modal": "qQK",
  "container": "qQG",
  "form-container": "qQr",
  "preview-container": "qQb",
  "preview-content": "qQy",
  "switch-container": "qQU",
  "supplier-invoice-preview": "qQj",
  "header": "qQW",
  "footer": "qQu",
  "form-content": "qeS",
  "form-content-section": "qec",
  "form-disclaimer": "qeq",
  "hidden": "qeZ"
};
