export default {
  "header-cell": "qPK",
  "header-content": "qPG caption-bold",
  "active": "qPr",
  "empty": "qPb",
  "type": "qPy",
  "requester": "qPU",
  "request-date": "qPj",
  "amount": "qPW",
  "receipt": "qPu",
  "quick-actions": "qlS"
};
