/* import __COLOCATED_TEMPLATE__ from './common.hbs'; */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeHighlight } from 'design-system-kit';
import { reads } from 'macro-decorators';

import { ACCOUNT_STATUS } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

const sortByDateAscending = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

export default class PageHeaderCommonComponent extends Component {
  @service abilities;
  @service intl;
  @service organizationManager;
  @service router;
  @service userManager;
  @service segment;

  @tracked showHistoryBalance = true;

  @reads('organizationManager.organization') organization;
  @reads('organization.primaryAccount.balanceCurrency') currency;
  @reads('organization.activeAccounts') activeAccounts;
  @reads('organization.sortedBankAccounts') sortedAccounts;
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts')
  sortedAscActiveRemuneratedAndCurrentAccounts;
  @reads('organization.activeSortedCurrentAccounts') activeSortedCurrentAccounts;
  @reads('organization.remuneratedAccounts') remuneratedAccounts;

  @reads('args.shouldHideClosedAccounts') shouldHideClosedAccounts;
  @reads('args.shouldHideClosedRemuneratedAccounts') shouldHideClosedRemuneratedAccounts;
  @reads('args.shouldShowWaitingRemuneratedAccounts') shouldShowWaitingRemuneratedAccounts;
  @reads('args.isRemuneratedAccountsHidden') isRemuneratedAccountsHidden;
  @reads('args.hideExternalAccounts') hideExternalAccounts;

  @tracked
  allAccountsOption = {
    id: '',
    icon: 'icon_layers_outlined',
    name: this.intl.t('bank_accounts.header.all-accounts'),
    safeName: this.intl.t('bank_accounts.header.all-accounts'),
    balance: this.organization.balance,
    authorizedBalance: this.organization.bankAccountsBalance,
    processingBalance: this.organization.processingBalance,
  };

  localStorageRevealBalanceKey = `reveal-history-balance-${this.userManager.currentUser.id}`;

  badgeHighlight = BadgeHighlight;

  constructor() {
    super(...arguments);

    let item = safeLocalStorage.getItem(this.localStorageRevealBalanceKey);
    if (item !== null) {
      this.showHistoryBalance = JSON.parse(item);
    }
  }

  get hasAllAccountsOption() {
    return this.args.hasAllAccountsOption ?? true;
  }

  get showAccountsFilter() {
    return (
      this.abilities.can('read bankAccount') &&
      this.args.showAccountsFilter &&
      this.sortedAccounts.filter(account =>
        this.args.shouldHideClosedAccounts ? account.status === ACCOUNT_STATUS.ACTIVE : true
      ).length > 1
    );
  }

  get tooltipMessage() {
    let { intl, currency } = this;
    let { balance, processingBalance } = this.selectedAccount;

    if (processingBalance !== 0) {
      processingBalance = -processingBalance;
    }

    let settled = intl.formatNumber(balance, { style: 'currency', currency });
    let processing = intl.formatNumber(processingBalance, { style: 'currency', currency });

    return intl.t('bank_accounts.header.balance.tooltip', { settled, processing, htmlSafe: true });
  }

  get selectedAccount() {
    let bankAccountId = this.router.currentRoute?.queryParams['bank-accounts'];
    let filteredAccount = this.sortedAccounts.find(
      it => it.id === bankAccountId || it.slug === this.router.currentRoute?.params?.slug
    );

    return filteredAccount || this.allAccountsOption;
  }

  get isBalanceDefined() {
    return this.selectedAccount.authorizedBalance !== undefined;
  }

  get closedSortedCurrentAccounts() {
    if (this.args.shouldHideClosedAccounts) return [];

    return this.organization.closedSortedCurrentAccounts;
  }

  get closedSortedDescCurrentAccounts() {
    return this.closedSortedCurrentAccounts.sort((a, b) => b.createdAt - a.createdAt);
  }

  get sortedDescClosedRemuneratedAndCurrentAccounts() {
    if (this.args.shouldHideClosedAccounts) return [];

    let currentAccounts = [];
    let remuneratedAccounts = [];
    let closedAccounts = this.sortedAccounts
      .filter(({ status }) => status === ACCOUNT_STATUS.CLOSED)
      .sort((a, b) => b.createdAt - a.createdAt);

    for (let account of closedAccounts) {
      if (!account.isRemunerated) {
        currentAccounts.push(account);
      } else {
        remuneratedAccounts.push(account);
      }
    }
    return currentAccounts.concat(remuneratedAccounts);
  }

  get options() {
    let allAccountsOption = this.hasAllAccountsOption ? [this.allAccountsOption] : [];

    let sortedAccounts = [
      ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
      ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
    ];

    if (this.isRemuneratedAccountsHidden) {
      sortedAccounts = [...this.activeSortedCurrentAccounts, ...this.closedSortedCurrentAccounts];
    } else if (this.shouldHideClosedRemuneratedAccounts) {
      sortedAccounts = [
        ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
        ...this.closedSortedDescCurrentAccounts,
      ];
    } else if (this.shouldShowWaitingRemuneratedAccounts) {
      let waitingRemuneratedAccounts = this.remuneratedAccounts
        .filter(({ status }) => status === ACCOUNT_STATUS.WAITING)
        .sort(sortByDateAscending);

      sortedAccounts = [
        ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
        ...waitingRemuneratedAccounts,
        ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
      ];
    } else {
      sortedAccounts = [
        ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
        ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
      ];
    }

    return [...allAccountsOption, ...sortedAccounts];
  }

  get waitingRemuneratedAccounts() {
    if (!this.shouldShowWaitingRemuneratedAccounts) return [];
    return this.remuneratedAccounts
      .filter(({ status }) => status === ACCOUNT_STATUS.WAITING)
      .sort(sortByDateAscending);
  }

  get aggregatedAccounts() {
    let accounts = [
      ...this.activeAccounts,
      ...this.waitingRemuneratedAccounts,
      ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
    ];

    if (this.isRemuneratedAccountsHidden) {
      accounts = accounts.filter(({ isRemunerated }) => !isRemunerated);
    } else if (this.shouldHideClosedRemuneratedAccounts) {
      accounts = accounts.filter(
        ({ status, isRemunerated }) => !(status === ACCOUNT_STATUS.CLOSED && isRemunerated)
      );
    }

    if (this.hideExternalAccounts) {
      accounts = accounts.filter(({ isExternalAccount }) => !isExternalAccount);
    }

    return [...(this.hasAllAccountsOption ? [this.allAccountsOption] : []), ...accounts];
  }

  get showFooter() {
    return this.args.showAccountsFooter && this.abilities.can('import external-account');
  }

  @action
  updateAccount({ id, createdAt, slug }) {
    let index = this.options.map(item => get(item, 'id')).indexOf(id);

    this.segment.track('bank_account_picker_account_selected', { id, index, createdAt });

    if (this.args.updateAccount) {
      return this.args.updateAccount(slug);
    }

    this.router.transitionTo({
      queryParams: {
        'bank-accounts': id,
        page: 1,
      },
    });
  }

  @action
  toggleBalanceVisibility() {
    this.showHistoryBalance = !this.showHistoryBalance;

    safeLocalStorage.setItem(this.localStorageRevealBalanceKey, this.showHistoryBalance);

    let trackingEventName = this.showHistoryBalance
      ? 'history_balance_reveal'
      : 'history_balance_hide';
    this.segment.track(trackingEventName);
  }
}
