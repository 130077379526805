export default {
  "account-container": "caB",
  "badge": "caE",
  "subtitle": "cad title-4",
  "accounts-filter": "caI",
  "account-filter-label": "cat",
  "status": "caM",
  "account-info": "caP",
  "balance": "cal",
  "revealed-balance": "caX",
  "hidden-balance": "caC"
};
