/* import __COLOCATED_TEMPLATE__ from './bookkeeping-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

export default class BookkeepingSelector extends Component {
  @service abilities;
  @service intl;

  @tracked checked = this.isNotEmpty;

  constructor() {
    super(...arguments);
    this._onCheckedStatusUpdate();
  }

  get isActive() {
    return this.checked || this.isNotEmpty;
  }

  get displayCustomLabels() {
    let accessCustomLabels = this.abilities.can('access custom-label');
    let readCustomLabels = this.abilities.can('read custom-label');
    return accessCustomLabels && readCustomLabels;
  }

  get displayVat() {
    let canViewVat = this.abilities.can('viewVat bookkeeping');
    return canViewVat && !this.args.transfer.fx;
  }

  get title() {
    return this.displayVat || this.displayCustomLabels
      ? this.intl.t('transfers.new.optional-settings.bookkeeping.title')
      : this.intl.t('transfers.new.optional-settings.additional-note.title');
  }

  get subtitle() {
    return this.displayVat || this.displayCustomLabels
      ? this.intl.t('transfers.new.optional-settings.bookkeeping.subtitle')
      : this.intl.t('transfers.new.optional-settings.additional-note.subtitle');
  }

  get isNotEmpty() {
    let { note, labels, vatRate } = this.args.transfer;
    let hasNote = Boolean(note);
    let hasCustomLabel = Boolean(labels.length > 0);
    let hasVat = vatRate !== null && vatRate !== undefined;

    return hasNote || hasCustomLabel || hasVat;
  }

  @action
  handleLabelSelect(labelList, label) {
    let { transfer } = this.args;
    transfer.labels = getLabelsToUpdate(transfer.labels, labelList, label);
    return transfer;
  }

  // Disabled rule because using .then in `handleSaveVatAmount`
  // eslint-disable-next-line require-await
  async handleSelectVat(model, value) {
    model.setProperties(value);
    return model;
  }

  @action
  toggleSelector() {
    this.checked = !this.checked;

    if (!this.checked) {
      let { transfer } = this.args;
      transfer.setProperties({ note: undefined, vatAmount: undefined, vatRate: undefined });
      transfer.labels = [];
    }

    this._onCheckedStatusUpdate();
  }

  _onCheckedStatusUpdate() {
    this.args.onSelect?.(this.isActive);
  }
}
