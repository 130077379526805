export default {
  "details-box": "cgm",
  "picto": "cgp",
  "picto-status": "cgx",
  "ghost": "cgw",
  "primary-info": "cgN",
  "date": "cgv",
  "account-infobox": "cgo",
  "declined-disclaimer": "cgn",
  "nrc-download": "cgi"
};
