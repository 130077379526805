export default {
  "header-cell": "qTp",
  "empty": "qTx",
  "header-content": "qTw caption-bold",
  "active": "qTN",
  "header-type": "qTv",
  "header-reason": "qTo",
  "header-status": "qTn",
  "header-amount": "qTi",
  "row-sidebar": "qTA",
  "hidden": "qTY",
  "animated": "qTh",
  "fadein-item": "qTf",
  "animated-cell": "qTK"
};
