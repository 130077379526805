export default {
  "refunds-total": "qdD",
  "total-plan-description": "qdV",
  "total-vat": "qdL",
  "switch-recurrence": "qdT",
  "flex-wrapper": "qdz",
  "promo-code": "qda",
  "promo-code-total": "qdH",
  "others": "qdO",
  "others-total": "qdm",
  "fee-description": "qdp"
};
