export default {
  "bank-account-option": "qlC",
  "row": "qlk",
  "active": "qlJ",
  "animated": "qls",
  "fadein-item": "qlF",
  "cell": "qlg body-2",
  "cell-content": "qlD",
  "subtitle": "qlV caption",
  "no-padding": "qlL",
  "empty": "qlT",
  "note": "qlz",
  "cell-content-amount": "qla body-1",
  "cell-content-receipt": "qlH body-1",
  "cell-quick-actions": "qlO",
  "account-selector": "qlm",
  "cell-type": "qlp"
};
