export default {
  "header-cell": "qSO",
  "header-content": "qSm caption-bold",
  "active": "qSp",
  "empty": "qSx",
  "align-right": "qSw",
  "request-type": "qSN",
  "requester": "qSv",
  "amount": "qSo"
};
