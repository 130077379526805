export default {
  "progress-container": "qXC",
  "dashed-line": "qXk",
  "solid-line": "qXJ",
  "circle-container": "qXs",
  "circle": "qXF",
  "current-step-dot": "qXg",
  "last-step-dot": "qXD",
  "success-icon": "qXV",
  "rejected-icon": "qXL"
};
