/* import __COLOCATED_TEMPLATE__ from './decline-request.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

export default class RequestSidebarModalsDeclineRequestComponent extends Component {
  @service errors;
  @tracked hasEmptyDeclinedNoteError = false;

  confirmTask = dropTask(async () => {
    this.hasEmptyDeclinedNoteError = false;
    let {
      close,
      data: { confirmTask, request },
    } = this.args;

    if (!request.declinedNote) {
      return (this.hasEmptyDeclinedNoteError = true);
    }

    try {
      if (confirmTask) {
        await confirmTask.linked().perform(close, request);
      } else {
        close('confirm');
      }
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}
