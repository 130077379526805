/* import __COLOCATED_TEMPLATE__ from './state.hbs'; */
import Component from '@glimmer/component';

export const LAYOUT = {
  default: 'default',
  standalone: 'standalone',
};

export default class TransfersInternationalOutStateComponent extends Component {
  get layout() {
    if (Object.values(LAYOUT).includes(this.args.layout)) {
      return this.args.layout;
    }

    return LAYOUT.default;
  }

  get isDefault() {
    return this.layout === LAYOUT.default;
  }

  get isStandalone() {
    return this.layout === LAYOUT.standalone;
  }
}
