export default {
  "row": "qTW body-1",
  "active": "qTu",
  "cell": "qzS",
  "empty": "qzc",
  "ellipsis": "qzq",
  "text-secondary": "qzZ",
  "cell-type": "qzR",
  "cell-reason": "qzQ",
  "cell-amount": "qze",
  "row-sidebar": "qzB",
  "animated": "qzE",
  "fadein-item": "qzd",
  "animated-cell": "qzI"
};
