/* import __COLOCATED_TEMPLATE__ from './amount-with-reference.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TransferFormAmountWithReference extends Component {
  @service intl;

  @tracked isAmountFieldDirty = false;

  @action updateAmount(amount) {
    this.args.transfer.set('amount', amount);
    this.isAmountFieldDirty = true;
  }

  get shouldDisplayAmountValidationErrorMessage() {
    return this.isAmountFieldDirty || this.args.enableTransferValidations;
  }

  @action
  disableStepKeyAndScroll(event) {
    event.target.addEventListener('keydown', this.shouldDisable);
    event.target.addEventListener('wheel', this.shouldDisable);
  }

  @action
  enableStepKeyAndScroll(event) {
    event.target.removeEventListener('keydown', this.shouldDisable);
    event.target.removeEventListener('wheel', this.shouldDisable);
  }

  @action
  shouldDisable(event) {
    // Prevent user from entering a decimal point
    if (event.keyCode === 38 || event.keyCode === 40 || event.type === 'wheel') {
      event.preventDefault();
    }
  }
}
