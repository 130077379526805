export default {
  "header-cell": "ciH",
  "empty": "ciO",
  "header-content": "cim caption-bold",
  "active": "cip",
  "align-right": "cix",
  "col-8": "ciw",
  "col-7": "ciN",
  "col-5": "civ",
  "col-4": "cio"
};
