export default {
  "header": "cWL",
  "header-type": "cWT",
  "close": "cWz",
  "close-icon": "cWa",
  "header-infos": "cWH",
  "title": "cWO body-1",
  "declined": "cWm",
  "canceled": "cWp",
  "approved": "cWx",
  "pending": "cWw",
  "date": "cWN body-2",
  "mileage-icon": "cWv",
  "distance-subtitle": "cWo body-2",
  "link-icon": "cWn",
  "container": "cWi",
  "picto": "cWA",
  "general": "cWY",
  "amount": "cWh",
  "calculation-container": "cWf",
  "counterparty": "cWK body-2",
  "infobox": "cWG"
};
