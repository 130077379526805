/* import __COLOCATED_TEMPLATE__ from './total-fees.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';

export default class SubscriptionTotalFeesComponent extends Component {
  @service intl;
  @service subscriptionManager;
  @service organizationManager;

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  get currentMonthYear() {
    return dateToken({
      date: new Date(),
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
    });
  }

  get switchAnnualEnabled() {
    return (
      this.organizationManager.organization.status === ORGA_STATUS.ACTIVATED &&
      !this.args.hasDeactivationInProgress
    );
  }

  get isAnnual() {
    return (
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get currentPricePlanCode() {
    return `${this.subscriptionManager.currentPricePlan.groupCode}_2023`;
  }
}
