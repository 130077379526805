/* import __COLOCATED_TEMPLATE__ from './client-information.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { alias } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import {
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
} from 'qonto/constants/receivable-invoice';
import { getDefaultClientValues } from 'qonto/models/client-hub';

export default class ClientInformationComponent extends Component {
  @service segment;
  @service store;
  @service modals;
  @service intl;

  @alias('args.document.customer') customer;

  @tracked isCustomerInvalid = false;
  @tracked modalDidClose = false;

  constructor() {
    super(...arguments);

    this.#validateCustomer();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.clientFormModal?.close();
  }

  get isFromQuote() {
    return this.args.isFromQuote;
  }

  get isCustomerChangeable() {
    if (this.selectedCustomer) {
      return !this.isFromQuote;
    }
    return true;
  }

  get hasCustomerRequiredError() {
    return this.args.document.errors.has('customer/data/id');
  }

  get hasCustomerInvalidError() {
    // eslint-disable-next-line ember/no-array-prototype-extensions
    let errorsArray = this.args.document.errors.toArray();
    return (
      !this.hasCustomerRequiredError &&
      errorsArray?.some(error => error.attribute.startsWith('customer'))
    );
  }

  get selectedCustomer() {
    return this.customer?.content;
  }

  get customerError() {
    if (this.args.customers.length > 0) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.errors.missing-customer.has-customer'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.missing-customer.no-customer');
  }

  @action updateCustomer(customer) {
    this.isCustomerInvalid = false;
    this.customer = customer;

    if (customer.currency !== CURRENCIES.default) {
      this.args.document.directDebitEnabled = false;
      if (this.args.document.payment?.method && this.args.document.payment?.conditions) {
        this.args.document.payment.method = DEFAULT_PAYMENT_METHOD;
        this.args.document.payment.conditions = DEFAULT_PAYMENT_CONDITION;
      }
    }

    if (customer.constructor.modelName === 'client-hub') {
      this.#validateCustomer();
    }

    if (customer) {
      this.segment.track('invoice-creation_client_added');
      this.args.document.errors.remove('customer/data/id');

      if (this.hasCustomerInvalidError) {
        // eslint-disable-next-line ember/no-array-prototype-extensions
        this.args.document.errors
          .toArray()
          .map(item => item.attribute)
          .filter(key => key.startsWith('customer'))
          .forEach(key => this.args.document.errors.remove(key));
      }

      this.args.document.currency = customer.currency || CURRENCIES.default;
    }

    this.args.onCustomerUpdate?.(customer);
  }

  enableTooltip = task(async () => {
    if (!this.modalDidClose) return;

    await timeout(200);
    this.modalDidClose = false;
  });

  @action openClientForm() {
    let client = this.store.createRecord('client-hub', getDefaultClientValues(this));
    this.segment.track('invoice-creation_client-creation_started');
    this.clientFormModal = this.modals.open(
      'receivable-invoices/client-form',
      {
        onClientSave: this.updateCustomer,
        isFullScreenModal: true,
        client,
        onClose: () => {
          this.modalDidClose = true;
        },
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: true,
        },
      }
    );
  }

  @action openEditClientForm() {
    let clientId = this.args.document.customer.get('id');
    let client = this.store.peekRecord('client-hub', clientId);

    if (!client.currency) {
      client.currency = CURRENCIES.default;
    }

    this.segment.track('invoice-creation_client-options_edit');

    this.clientFormModal = this.modals.open(
      'receivable-invoices/client-form',
      {
        onClientSave: this.updateCustomer,
        isEdit: true,
        isFullScreenModal: true,
        showDeleteButton: !this.isFromQuote,
        client,
        onClose: () => {
          this.modalDidClose = true;
        },
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          initialFocus: false,
        },
      }
    );
  }

  #validateCustomer() {
    if (variation('feature--boolean-client-hub') && this.customer.id) {
      let customerRecord = this.store.peekRecord('client-hub', this.customer.id);
      customerRecord?.validate();
      this.isCustomerInvalid = customerRecord.validations.isInvalid;
    }
  }
}
