export default {
  "banner": "cDF",
  "inner": "cDg",
  "header": "cDD title-3",
  "content": "cDV",
  "subtitle": "cDL body-1",
  "list": "cDT",
  "item": "cDz body-1",
  "icon": "cDa",
  "cta": "cDH btn btn--primary",
  "spinner": "cDO",
  "illustration": "cDm",
  "image": "cDp"
};
