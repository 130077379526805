export default {
  "row": "ciA",
  "cell": "ciY",
  "greyed-out": "cih",
  "struck-through": "cif",
  "flex-cell": "ciK",
  "overdue": "ciG",
  "align-right": "cir",
  "customer-name-container": "cib",
  "icon": "ciy",
  "warning": "ciU",
  "with-separator": "cij",
  "align-center": "ciW"
};
