export default {
  "notifications-settings": "cgQ",
  "opened": "cge",
  "icon": "cgB",
  "header": "cgE",
  "header-toggle": "cgd",
  "avatar-image": "cgI",
  "title": "cgt",
  "toggle": "cgM"
};
