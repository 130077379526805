export default {
  "transaction": "qkm",
  "transaction-details": "qkp",
  "transaction-details-amount": "qkx body-1",
  "credit": "qkw",
  "negative-amount": "qkN",
  "transaction-details-description": "qkv body-2",
  "transaction-remove-cta": "qko",
  "avatar": "qkn mr-16"
};
