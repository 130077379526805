/* import __COLOCATED_TEMPLATE__ from './virtual-renew.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class TopbarCardsVirtualRenewComponent extends Component {
  @service organizationManager;
  @service segment;

  @reads('organizationManager.organization') organization;

  @action
  close() {
    safeLocalStorage.setItem('virtual-renewed-banner-dismissed', true);
    this.args.close();
  }

  @action
  handleRenewLinkClick() {
    this.segment.track('virtual_cards_renewal_top_banner_redirection');
    this.close();
  }

  @action
  handleCloseClick() {
    this.segment.track('virtual_cards_renewal_top_banner_close');
    this.close();
  }
}
