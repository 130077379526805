export default {
  "referral-invite-new": "cAe",
  "referral": "cAB",
  "center": "cAE",
  "illustration": "cAd",
  "share-referral": "cAI",
  "choice": "cAt",
  "line": "cAM",
  "email": "cAP",
  "email-input": "cAl",
  "input-field--error": "cAX",
  "error-message": "cAC",
  "external-link-icon": "cAk"
};
