export default {
  "item-status": "czC",
  "item-date": "czk",
  "item-amount": "czJ body-1",
  "item-amount-credit": "czs",
  "row": "czF",
  "icon": "czg",
  "disabled": "czD",
  "icon-container": "czV",
  "text-container": "czL",
  "text-row": "czT",
  "item-details": "czz",
  "counterparty-name": "cza",
  "column": "czH",
  "item-amount-disabled": "czO",
  "attachment-tooltip": "czm",
  "bank-account-avatar": "czp"
};
