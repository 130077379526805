export default {
  "label-select": "qBo",
  "puce": "qBn",
  "puce-wrapper": "qBi",
  "puce-container": "qBA",
  "readonly": "qBY",
  "label-display": "qBh",
  "label-list-name": "qBf body-1",
  "label-list-value": "qBK body-2"
};
