export default {
  "selector": "cNB",
  "pension-selector-content": "cNE",
  "withholding-tax-selector-content": "cNd",
  "withholding-tax-selector-content-row": "cNI",
  "power-select-field": "cNt",
  "rate-field": "cNM",
  "stamp-revenue-field": "cNP",
  "dropdown-option": "cNl",
  "dropdown-content": "cNX"
};
