export default {
  "header": "cKx",
  "header-type": "cKw",
  "close": "cKN",
  "close-icon": "cKv",
  "header-infos": "cKo",
  "title": "cKn body-1",
  "declined": "cKi",
  "canceled": "cKA",
  "approved": "cKY",
  "pending": "cKh",
  "date": "cKf body-2",
  "container": "cKK",
  "picto": "cKG",
  "picto-status": "cKr",
  "general": "cKb",
  "amount": "cKy",
  "counterparty": "cKU body-2",
  "initiator": "cKj",
  "initiator-avatar": "cKW",
  "avatar-image": "cKu",
  "initiator-id": "cGS",
  "name": "cGc body-2",
  "role": "cGq caption-bold",
  "infobox": "cGZ",
  "account-infobox": "cGR cGZ",
  "beneficiary-warning": "cGQ caption-bold",
  "dates-wrapper": "cGe",
  "dates": "cGB",
  "recurrence": "cGE caption-bold",
  "arrow": "cGd",
  "notify-checkbox": "cGI"
};
