export default {
  "navigation-dropdown": "cFO",
  "expanded": "cFm",
  "trigger": "cFp",
  "trigger-title-icon": "cFx",
  "collapsed": "cFw",
  "responsive": "cFN",
  "logo": "cFv",
  "active": "cFo",
  "trigger-title": "cFn",
  "content": "cFi",
  "content-container": "cFA",
  "dropdown-icon": "cFY",
  "link": "cFh"
};
