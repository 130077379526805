/* import __COLOCATED_TEMPLATE__ from './notify-beneficiary-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class NotifyBeneficarySelectorComponent extends Component {
  @action
  toggleNotifyEmail() {
    this.args.transfer.notifyByEmail = !this.args.transfer.notifyByEmail;
    this.args.onToggleSelector();
  }
}
