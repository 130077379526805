/* import __COLOCATED_TEMPLATE__ from './edit-imported-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

class Form {
  @tracked errors;
  @tracked values;

  constructor(schema) {
    this.schema = schema;
    this.values = { ...schema.initialValues };
    this.validations = { ...schema.validationsSchema };
  }

  async submit({ partial = false } = {}) {
    try {
      await this.schema.onSubmit(this.values, partial);
    } catch (error) {
      if (error.isAdapterError) throw error;
    }
  }

  updateField(prop, value, hasInvalidDate, dateRangeError) {
    this.values = { ...this.values, [prop]: value };
    let error = dateRangeError || (typeof hasInvalidDate === 'boolean' && hasInvalidDate) || false;
    this.errors = error ? { ...this.errors, [prop]: error } : null;
  }
}

export default class ReceivableInvoicesEditImportedFormComponent extends Component {
  @service sentry;
  @service segment;
  @service toastFlashMessages;
  @service intl;

  @tracked amountDue;

  constructor() {
    super(...arguments);
    this.amountDue = this.args.invoice?.amountDue;
    this.form = new Form({
      onSubmit: values => this.saveInvoiceTask.perform(values),
      initialValues: this.defaultValues,
      validationsSchema: this.validationsSchema,
    });
    this.args.onCreate?.(this.form);
  }

  get defaultValues() {
    let { invoice } = this.args;
    return {
      name: invoice?.customerSnapshot?.name,
      invoiceNumber: invoice?.number,
      dueDate: invoice?.dueDate,
      issueDate: invoice?.issueDate,
      amountDue: invoice.amountDue?.value,
      subtotal: invoice?.subtotal,
      vatAmount: invoice?.vatAmount,
      paymentDate: invoice?.paymentDate,
      currency: invoice?.currency || 'EUR',
    };
  }

  get customer() {
    let { invoice } = this.args;

    return { ...invoice?.customerSnapshot, currency: invoice?.currency };
  }

  @action
  updateAmount(field, value) {
    this.updateField(field, this.formatValueToTwoDecimals(value));
    this.calculateAmountDue();
  }

  calculateAmountDue() {
    this.amountDue = this.formatAmountDue;
    this.updateField('amountDue', this.amountDue);
  }

  get formatAmountDue() {
    let { subtotal = '0', vatAmount = '0' } = this.form.values;
    return (Number(subtotal) + Number(vatAmount)).toFixed(2);
  }

  formatValueToTwoDecimals(value) {
    return value.includes('.') ? value.replace(/(\.\d{2})\d+/, '$1') : value;
  }

  @action
  updateField(prop, value, hasInvalidDate) {
    let dateRangeError =
      (prop === 'dueDate' || prop === 'issueDate') &&
      this.displayInvalidDateRangeError(prop, value, this.form.values);
    this.form.updateField(prop, value, hasInvalidDate, dateRangeError);
  }

  displayInvalidDateRangeError(prop, value, values) {
    let dueDate = new Date(prop === 'dueDate' ? value : values.dueDate);
    let issueDate = new Date(prop === 'issueDate' ? value : values.issueDate);

    return issueDate > dueDate
      ? this.intl.t('receivable-invoices.import.edit.modal.invoice-details.issue-date-error')
      : undefined;
  }

  submitFormTask = restartableTask(async () => {
    try {
      await this.form.submit();
    } catch (error) {
      this.sendErrorToSentry(error);
    }
  });

  saveInvoiceTask = restartableTask(async values => {
    await this._saveInvoiceTask.perform(values);
  });

  _saveInvoiceTask = restartableTask(async values => {
    this.updateInvoiceValues(values);

    try {
      await this.args.invoice.updateImportedInvoice();
      this.segment.track('invoice_imported_edit-info_save');
      this.form.schema.initialValues = this.defaultValues;
      this.args.cancelEdit();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.edit.modal.success-toast.save-changes')
      );
    } catch (error) {
      this.handleSaveError(error);
    }
  });

  updateInvoiceValues({
    amount,
    currency,
    name,
    invoiceNumber,
    issueDate,
    dueDate,
    subtotal,
    vatAmount,
  }) {
    let invoice = this.args.invoice;
    if (amount !== undefined) invoice.totalAmount = { value: amount, currency };

    vatAmount = vatAmount || '0.00';
    subtotal = subtotal || '0.00';

    Object.assign(invoice, {
      customerSnapshot: { ...invoice.customerSnapshot, name },
      number: invoiceNumber,
      issueDate,
      dueDate,
      amountDue: this.amountDue,
      subtotal,
      vatAmount,
      currency,
    });
  }

  handleSaveError(error) {
    this.args.invoice.rollbackAttributes();
    this.toastFlashMessages.toastError(
      this.intl.t('receivable-invoices.edit.modal.error-toast.save-changes')
    );
    this.sendErrorToSentry(error);
  }

  sendErrorToSentry(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && error.status !== 400) {
      this.sentry.captureException(error);
    }
  }
}
