export default {
  "modal-wrapper": "cnr",
  "spinner-container": "cnb",
  "spinner": "cny",
  "container": "cnU",
  "tabs-item": "cnj",
  "form-container": "cnW",
  "preview-container": "cnu",
  "switch-container": "ciS",
  "pdf-preview-container": "cic",
  "form-content": "ciq",
  "row": "ciZ",
  "numbering-format": "ciR",
  "numbering-fields": "ciQ",
  "input-settings": "cie",
  "next-invoice-number": "ciB",
  "spacing": "ciE",
  "label-tooltip": "cid",
  "tooltip-icon": "ciI",
  "customize-field": "cit",
  "button-container-footer": "ciM"
};
