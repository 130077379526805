export default {
  "modal": "qCl",
  "section": "qCX",
  "header": "qCC",
  "title": "qCk",
  "icon": "qCJ",
  "content": "qCs",
  "export-template-wrapper": "qCF",
  "export-template-radio": "qCg",
  "export-template-content": "qCD",
  "export-template-label": "qCV",
  "export-template-button": "qCL",
  "export-template-list": "qCT",
  "inactive": "qCz",
  "export-template-title": "qCa",
  "export-template-description": "qCH",
  "export-template-item-content": "qCO",
  "permission-disclaimer-container": "qCm",
  "export-template-footer": "qCp",
  "export-template-cta-container": "qCx",
  "upsell": "qCw",
  "upsell-section": "qCN",
  "_content": "qCv",
  "_cta": "qCo",
  "upsell-title": "qCn",
  "upsell-description": "qCi"
};
