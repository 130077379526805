export default {
  "beneficiary-details": "qVZ",
  "beneficiary-country-disclaimer": "qVR",
  "beneficiary-account-types": "qVQ",
  "divider": "qVe",
  "error-disclaimer": "qVB",
  "label": "qVE",
  "edition-info-disclaimer": "qVd",
  "sticky-panel": "qVI",
  "spinner": "qVt"
};
