/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

import { STATUS } from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class RequestSidebarTransferDetailsComponent extends Component {
  @service modals;
  @service abilities;
  @service segment;

  @tracked showTeamBudgetPromotionalCard = true;

  constructor() {
    super(...arguments);

    let { request, allocatedBudget } = this.args;

    let teamBudgetPromotionalCardDismissed = false;

    teamBudgetPromotionalCardDismissed = safeLocalStorage.getItem(
      'team-budget-promotional-card-dismissed'
    );

    this.showTeamBudgetPromotionalCard =
      !teamBudgetPromotionalCardDismissed &&
      request.pending &&
      this.abilities.can('create budget') &&
      !allocatedBudget;

    if (this.showTeamBudgetPromotionalCard) {
      this.segment.track('request_sidebar_budget_banner_view');
    }
  }

  get showDisclaimer() {
    let { request, spendLimitsWarning } = this.args;
    let userId = request.get('initiator.userId');

    let isPending = request.status === STATUS.PENDING;
    let isSpendLimitCase =
      spendLimitsWarning &&
      this.abilities.can('view own request info disclaimer transfer-limit', request);

    return (
      isPending &&
      (this.abilities.can('cancel transfer request', userId) || isSpendLimitCase) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  downloadAttachment(model, file) {
    let attachment = model.attachments.find(a =>
      file.id ? a.file.id === file.id : file.fileUrl.includes(a.id)
    );
    window.open(attachment.downloadUrl);
  }

  @action
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
      model: this.args.request,
      download: this.downloadAttachment,
    });
  }

  @action
  closeTeamBudgetPromotionalCard() {
    safeLocalStorage.setItem('team-budget-promotional-card-dismissed', true);
    this.showTeamBudgetPromotionalCard = false;
    this.segment.track('request_sidebar_budget_banner_close_clicked');
  }

  get shouldDisplayApprovalWorkflowState() {
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}
