/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';

export default class ReimbursementsPendingSidebarMileageHeaderComponent extends Component {
  @service intl;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.state = this.args.state || STATUS.PENDING;
  }

  get distance() {
    let distanceKm = (this.args.request.mileageCalculation.distanceMeters / 1000).toFixed(2);
    let rate = this.args.request.mileageCalculation.rate.value;
    return {
      distanceKm,
      rate,
    };
  }

  get lastActionDate() {
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get requestDate() {
    let formattedDate = this.intl.formatDateRelative(this.lastActionDate);

    return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
  }
}
