/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

import { dropTask, task } from 'ember-concurrency';
import { and, not, reads } from 'macro-decorators';

const MAX_ATTACHMENTS = 5;

export default class TransactionsSiderbarDetailsAttachment extends Component {
  @service abilities;
  @service organizationManager;
  @service segment;

  @not('args.highlightedItem.isTax') isNotTax;
  @not('args.highlightedItem.isFee') isNotFee;
  @not('args.highlightedItem.isPagoPaPayment') isNotPagoPaPayment;
  @and('isNotFee', 'isNotTax', 'isNotPagoPaPayment') showMenu;
  @reads('args.highlightedItem.debit') isOutgoingTransfer;

  get hasNoAttachments() {
    return !this.args.highlightedItem.attachmentIds?.length;
  }

  get showReceiptRemindersDisclaimer() {
    return (
      this.abilities.can('view ReceiptReminder') &&
      this.isNotFee &&
      this.isNotPagoPaPayment &&
      this.isOutgoingTransfer
    );
  }

  get hasAutoAttachments() {
    let { attachmentIds, automaticallyAddedAttachmentIds } = this.args.highlightedItem;
    return (
      this.abilities.can('access email forward attachment') &&
      automaticallyAddedAttachmentIds?.some(id => attachmentIds?.includes(id))
    );
  }

  get showAttachmentRequestButton() {
    return (
      this.abilities.can('request attachments in transaction', this.args.highlightedItem) &&
      this.hasNoAttachments &&
      this.isNotPagoPaPayment
    );
  }

  get hasSuggestedAttachmentsButton() {
    let { attachmentSuggestionIds, attachmentIds } = this.args.highlightedItem;

    return (
      this.abilities.can('link suggested attachments') &&
      attachmentSuggestionIds?.length &&
      attachmentIds.length < MAX_ATTACHMENTS &&
      this.isNotPagoPaPayment
    );
  }

  @action
  handleViewSuggestedAttachments() {
    this.segment.track('transaction_panel_attachment_suggestions_button_clicked');
    this.args.handleShowSuggestedAttachments();
  }

  markAttachmentAsLostTask = dropTask(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentLost = true;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.markAttachmentAsLost();
      } catch {
        this.args.highlightedItem.attachmentLost = false;
      }
    })
  );

  markAttachmentAsFoundTask = dropTask(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentLost = false;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.unmarkAttachmentAsLost();
      } catch {
        this.args.highlightedItem.attachmentLost = true;
      }
    })
  );

  markAttachmentAsRequiredTask = task(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentRequired = true;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.markAttachmentAsRequired();
      } catch {
        this.args.highlightedItem.attachmentRequired = false;
      }
    })
  );

  markAttachmentAsNotRequiredTask = task(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentRequired = false;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.unmarkAttachmentAsRequired();
      } catch {
        this.args.highlightedItem.attachmentRequired = true;
      }
    })
  );
}
