export default {
  "row": "qHI",
  "col": "qHt",
  "counterparty": "qHM",
  "header": "qHP",
  "icon": "qHl",
  "col-12": "qHX",
  "col-8": "qHC",
  "col-6": "qHk",
  "col-4": "qHJ",
  "col-2": "qHs",
  "hide": "qHF"
};
