/* import __COLOCATED_TEMPLATE__ from './transactions-modal.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask, task, timeout } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SEARCH_INCLUDES, LINKED_ATTACHMENTS_LIMIT } from 'qonto/constants/transactions';
import { Transaction } from 'qonto/react/components/supplier-invoices/transactions-modal/transaction/transaction';

export default class TransactionsModalComponent extends Component {
  transaction = Transaction;

  @service intl;
  @service toastFlashMessages;
  @service router;
  @service store;
  @service organizationManager;

  filterGroup = {
    conditional: 'and',
    expressions: [
      {
        property: 'attachment_ids',
        values: [this.args.data.attachment.id],
        operator: 'not_in',
      },
      {
        property: 'status',
        values: ['completed'],
        operator: 'in',
      },
      {
        property: 'amount',
        values: ['0.00'],
        operator: 'lt',
      },
      {
        property: 'operation_method',
        values: ['pagopa_payment'],
        operator: 'not_in',
      },
    ],
  };

  linkTransactionTask = dropTask(async transaction => {
    try {
      await transaction.linkAttachment([this.args.data.attachment]);
      await this.args.data.invoice.markAsPaid(
        dayjs(transaction.settledAt).format(DATE_PICKER_FIELD_FORMAT)
      );
      this.args.close();
      this.args.data.onCloseModal();
    } catch (error) {
      this._handleError(error);
    }
  });

  markAsPaidWithoutAttachmentTask = dropTask(async () => {
    try {
      await this.args.data.invoice.markAsPaid(dayjs().format(DATE_PICKER_FIELD_FORMAT));
      this.args.close();
      this.args.data.onCloseModal();
    } catch {
      this._handleError();
    }
  });

  fetchTransactionsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    let { transactions } = await this.store.adapterFor('transaction').search({
      includes: DEFAULT_SEARCH_INCLUDES,
      filter_group: this.filterGroup,
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 20 },
      search: searchQuery,
      organization_id: this.organizationManager.organization.id,
    });

    return transactions;
  });

  _handleError(error) {
    this.args.close();
    if (error?.status === 422) {
      this.toastFlashMessages.toastError(
        this.intl.t('receivable-invoices.match-transaction.error-toast', {
          invoiceLimit: LINKED_ATTACHMENTS_LIMIT,
        })
      );
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    if (this.args.data.invoice.status !== INVOICE_STATUSES.paid) {
      this.router.transitionTo('supplier-invoices.index');
    }
  }
}
