/* import __COLOCATED_TEMPLATE__ from './cashflow-jumbo.hbs'; */
import { action } from '@ember/object';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

import CashflowComponent from './cashflow';

export default class CashflowJumboComponent extends CashflowComponent {
  get maxDisplayedMonths() {
    return this.args.maxDisplayedMonths;
  }

  get indexOffset() {
    return this.args.indexOffset;
  }

  get balance() {
    if (!this.timeseries) return;

    return this.timeseries.at(-1).data.endingBalance;
  }

  /**
   * Displayed timeseries
   */
  get chartTimeseries() {
    if (!this.timeseries) return;

    let offset = this.indexOffset;
    let cutoff = offset + this.maxDisplayedMonths;
    let chartTimeseries = [...this.timeseries].slice(offset, cutoff);

    return chartTimeseries;
  }

  /**
   * Chart timeseries + next available timevalue
   */
  get balanceTimeseries() {
    if (!this.timeseries) return;

    let timeseriesLastIndex = this.timeseries.length - 1;
    let cutoff = this.indexOffset + this.maxDisplayedMonths;
    let lastBalanceIndex = cutoff < timeseriesLastIndex ? cutoff + 1 : timeseriesLastIndex;
    let lastBalancePoint = this.timeseries.at(lastBalanceIndex);

    let balanceTimeseries = [...this.chartTimeseries, lastBalancePoint];

    return balanceTimeseries;
  }

  constructor() {
    super(...arguments);
    this.storageKey = `cashflow-jumbo-period-${this.membershipId}`;
  }

  @action
  onSavePeriod(period, closeDropdown) {
    this.args.onResetOffset();

    closeDropdown?.();
    if (!period) return;

    this._selectedPeriod = this._formatPeriod(period);
    this.persistPeriod(period);

    this.segment.track('cash-flow_filter_clicked', {
      filtertype: 'period',
    });
    this.refreshWidgetTask.perform(period).catch(ignoreCancelation);
  }
}
