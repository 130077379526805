export default {
  "document": "cnJ",
  "document-details": "cns",
  "document-details-amount": "cnF body-2",
  "negative": "cng",
  "positive": "cnD",
  "rejected": "cnV",
  "document-icon": "cnL",
  "document-details-link": "cnT body-link",
  "canceled": "cnz"
};
