export default {
  "cell-content": "cyj",
  "icon": "cyW",
  "status_check-m": "cyu",
  "description": "cUS body-1",
  "from": "cUc body-2",
  "from--abort": "cUq",
  "description--abort": "cUZ",
  "title": "cUR body-2"
};
