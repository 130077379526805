export default {
  "header-cell": "qaN",
  "align-right": "qav",
  "header-content": "qao caption-bold",
  "active": "qan",
  "empty": "qai",
  "col-12": "qaA",
  "col-8": "qaY",
  "col-6": "qah",
  "col-4": "qaf",
  "col-2": "qaK",
  "hide": "qaG"
};
