/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { INSIGHT_LABELS, INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesTabsComponent extends Component {
  @service abilities;
  @service intl;

  get displayPendingRequestsLink() {
    return (
      this.abilities.can('review transfer request') && this.args.status === INVOICE_STATUSES.pending
    );
  }

  get pendingAriaLabel() {
    let { insights } = this.args;
    let count = insights[INSIGHT_LABELS.pendingCounter];

    if (this.abilities.can('review transfer request')) {
      return count > 0
        ? this.intl.t('supplier-invoices.section.tabs.tasks.pending.aria-label')
        : this.intl.t('supplier-invoices.section.tabs.tasks.no-pending.aria-label');
    }

    return count > 0
      ? this.intl.t('supplier-invoices.section.tabs.requests.pending.aria-label')
      : this.intl.t('supplier-invoices.section.tabs.requests.no-pending.aria-label');
  }

  get tabs() {
    let { insights, enableAllTabs } = this.args;

    let dualNavTabs = [
      ...(this.abilities.can('read request') || enableAllTabs
        ? [
            {
              icon:
                this.abilities.can('review transfer request') || enableAllTabs
                  ? 'icon_nav_tasks'
                  : 'icon_nav_requests',
              title:
                this.abilities.can('review transfer request') || enableAllTabs
                  ? this.intl.t('supplier-invoices.section.tabs.tasks.title')
                  : this.intl.t('supplier-invoices.section.tabs.requests.title'),
              label: this.pendingAriaLabel,
              status: INVOICE_STATUSES.pending,
              count: insights[INSIGHT_LABELS.pendingCounter],
              sortBy: 'due_date:asc',
              isSelected: this.args.status === INVOICE_STATUSES.pending,
            },
          ]
        : []),
      {
        title: this.intl.t('supplier-invoices.section.tabs.imported.title'),
        status: INVOICE_STATUSES.toReview,
        count: insights[INSIGHT_LABELS.inboxCounter],
        sortBy: 'due_date:desc',
        event: 'supplier-invoices_imported-tab_clicked',
        isSelected: this.args.status === INVOICE_STATUSES.toReview,
      },
      {
        title: this.intl.t('supplier-invoices.section.tabs.processing.title'),
        status: INVOICE_STATUSES.scheduled,
        count: insights[INSIGHT_LABELS.scheduledCounter],
        sortBy: 'scheduled_date:desc',
        event: 'supplier-invoices_processing-tab_clicked',
        isSelected: this.args.status === INVOICE_STATUSES.scheduled,
      },
      {
        title: this.intl.t('supplier-invoices.section.tabs.completed.title'),
        status: variation('feature-invoices-prioritize-payments')
          ? `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`
          : INVOICE_STATUSES.paid,
        sortBy: 'payment_date:desc',
        event: 'supplier-invoices_paid-tab_clicked',
        isSelected: [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived].includes(
          this.args.status?.split(',')[0]
        ),
      },
    ];

    return dualNavTabs;
  }
}
