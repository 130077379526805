export default {
  "invoice-sidebar": "qem",
  "body": "qep",
  "box": "qex",
  "box-header": "qew",
  "sidebar-box": "qeN",
  "border-top": "qev",
  "row": "qeo",
  "greyed-out": "qen",
  "strike-through": "qei"
};
