export default {
  "header": "cha",
  "header-type": "chH",
  "close": "chO",
  "close-icon": "chm",
  "header-infos": "chp",
  "title": "chx body-1",
  "declined": "chw",
  "canceled": "chN",
  "approved": "chv",
  "pending": "cho",
  "date": "chn body-2",
  "mileage-icon": "chi",
  "distance-subtitle": "chA body-2",
  "link-icon": "chY",
  "container": "chh",
  "picto": "chf",
  "general": "chK",
  "amount": "chG",
  "calculation-container": "chr",
  "counterparty": "chb body-2",
  "infobox": "chy"
};
