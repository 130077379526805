export default {
  "card": "cDb",
  "header": "cDy",
  "avatar": "cDU",
  "title": "cDj",
  "subtitle": "cDW",
  "label": "cDu",
  "item": "cVS body-2",
  "header-icon": "cVc",
  "mt-8": "cVq"
};
