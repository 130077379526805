export default {
  "header-cell": "crg",
  "empty": "crD",
  "header-content": "crV caption-bold",
  "active": "crL",
  "header-type": "crT",
  "header-reason": "crz",
  "header-status": "cra",
  "header-amount": "crH",
  "row-sidebar": "crO",
  "hidden": "crm",
  "animated": "crp",
  "fadein-item": "crx",
  "animated-cell": "crw"
};
