export default {
  "row": "qEZ",
  "disabled": "qER",
  "cell": "qEQ body-2",
  "cell-link": "qEe",
  "cell-content": "qEB",
  "item-truncate-text": "qEE",
  "item-name-cell": "qEd",
  "item-account-cell": "qEI",
  "icon": "qEt",
  "icon-download": "qEM"
};
