/* import __COLOCATED_TEMPLATE__ from './beneficiary-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RequestTransferBeneficiaryFormComponent extends Component {
  @service intl;
  @service segment;

  get clearOptions() {
    let canClearBeneficiary = this.args.requestTransfer.isIBANObfuscated;

    if (canClearBeneficiary) {
      return {
        text: this.intl.t('labels.clear_beneficiary'),
      };
    }

    return {};
  }

  @action
  updateBeneficiaryName(name) {
    this.args.requestTransfer.set('name', name);
    this.args.onBeneficiaryNameFilled?.();
  }

  @action
  toggleNotifyEmail() {
    this.args.requestTransfer.notifyByEmail = !this.args.requestTransfer.notifyByEmail;
    this.segment.track('transfer_notify_beneficiary_checkbox');
  }
}
