/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeHighlight } from 'design-system-kit';
import { reads } from 'macro-decorators';

import { registerJsURL } from 'qonto/constants/hosts';
import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { SubscriptionHeaderDeactivated } from 'qonto/react/components/subscription/header/deactivated';

export default class SubscriptionHeaderComponent extends Component {
  badgeHighlight = BadgeHighlight;
  subscriptionHeaderDeactivated = SubscriptionHeaderDeactivated;

  @service organizationManager;
  @service subscriptionManager;
  @service intl;

  @tracked deactivationRequester = this.args.deactivationRequester;
  @tracked deactivated = this.args.deactivated;

  @reads('subscriptionManager.currentSubscription') currentSubscription;
  @reads('subscriptionManager.currentPricePlan') currentPlan;
  @reads('organizationManager.membership.role') role;
  @reads('organizationManager.organization') organization;

  registerLink = `${registerJsURL}/signup/success`;

  get planOverviewDescription() {
    let isAnnual =
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL;
    if (isAnnual) {
      return {
        part1: this.intl.t(
          'subscription.consumption-table.plan-overview.plan.description.annual.part-1',
          {
            planName: this.currentPlan.localName,
            htmlSafe: true,
          }
        ),
        part2: this.intl.t(
          'subscription.consumption-table.plan-overview.plan.description.annual.part-2'
        ),
      };
    }

    return {
      part1: this.intl.t('subscription.consumption-table.plan-overview.plan.description.part-1', {
        planName: this.currentPlan.localName,
        htmlSafe: true,
      }),
      part2: this.intl.t('subscription.consumption-table.plan-overview.plan.description.part-2'),
    };
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.availableTrials[0];
  }

  get monthDuration() {
    return this.trialInfo.monthDuration;
  }

  get nextSubscriptionBillingDateFormatted() {
    return dateToken({
      date: this.currentSubscription?.nextSubscriptionBillingDate,
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  get isActiveTrial() {
    return Boolean(this.currentSubscription?.activeTrial?.previous_plan_id);
  }

  get activeTrial() {
    return this.currentSubscription?.activeTrial;
  }

  get defaultLineup() {
    return this.currentPlan.lineup || PRICE_PLAN_LINEUPS.SOLO;
  }

  @action onDeactivationCanceled() {
    this.deactivationRequester = null;
  }
}
