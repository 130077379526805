/* import __COLOCATED_TEMPLATE__ from './donut-chart.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { interpolate, interpolateNumber } from 'd3-interpolate';
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import { modifier } from 'ember-modifier';

import styles from 'qonto/components/overview/chart/donut-chart.module.css';
import { canAnimate } from 'qonto/utils/chart';

import 'd3-transition';

const OUTER_DIAMETER = 244;
const OUTER_RADIUS = OUTER_DIAMETER / 2;
const INNER_RADIUS = (OUTER_DIAMETER * 44) / OUTER_RADIUS;

const ANIMATION_DURATION = macroCondition(!isTesting()) ? 1200 : 0;

export default class OverviewChartDonutChartComponent extends Component {
  @service('intl') intl;

  drawGraph = modifier(element => {
    this.drawPlaceholder(element);

    if (this.args.data) {
      let svg = select(element);

      this._drawDonutSlices(svg);

      this._drawDonutSum(svg);
    }
  });

  drawPlaceholder(element) {
    let svg = select(element);

    svg?.selectAll('*').remove();

    this._drawDonutBackground(svg);
  }

  _drawDonutBackground(svg) {
    let sliceGenenerator = arc()
      .outerRadius(OUTER_RADIUS)
      .innerRadius(INNER_RADIUS)
      .startAngle(0)
      .endAngle(2 * Math.PI);

    svg
      .append('path')
      .attr('data-donut-chart-background', '')
      .attr('d', sliceGenenerator)
      .attr('transform', `translate(${OUTER_RADIUS},${OUTER_RADIUS})`);
  }

  _drawDonutSlices(svg) {
    let pieGenerator = pie()
      .value(d => d.value)
      .sort(null);

    let sliceGenenerator = arc().outerRadius(OUTER_RADIUS).innerRadius(INNER_RADIUS);

    let slices = svg
      .append('g')
      .selectAll('[data-donut-chart-slice]')
      .data(pieGenerator(this.args.data))
      .enter()
      .append('path')
      .attr('d', sliceGenenerator)
      .attr('transform', `translate(${OUTER_RADIUS},${OUTER_RADIUS})`)
      .attr('data-donut-chart-slice', (d, i) => i)
      .attr('data-donut-chart-slice-type', d => d.data.name)
      .attr('aria-label', 'donut-chart')
      .attr('aria-describedby', (d, i) => `caption-${i}`)
      .on('mouseover focus', ({ target }, { data, index }) => {
        this.args.onHighlightChange(target, { ...data, index });
        this._highlightSlice(svg, index);
      })
      .on('mouseout focusout', () => {
        this.args.onHighlightChange();
        this._unhighlightSlices(svg);
      });

    if (canAnimate()) {
      slices
        .transition()
        .attrTween('d', function (d) {
          let interpolator = interpolate({ startAngle: 0, endAngle: 0 }, d);
          let arcGenerator = arc().outerRadius(OUTER_RADIUS).innerRadius(INNER_RADIUS);
          return function (t) {
            return arcGenerator(interpolator(t));
          };
        })
        .duration(ANIMATION_DURATION)
        .waitFor();
    }
  }

  _drawDonutSum(svg) {
    let { bounds, sum } = this.args;

    if (bounds?.current.min) {
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('y', OUTER_RADIUS + 14)
        .attr('x', OUTER_RADIUS)
        .attr('data-donut-chart-sum-from', '')
        .attr('class', styles['donut-chart-sum-title'])
        .text(
          `${this.intl.t('overview.donut-chart.from')} ${dateToken({
            date: bounds.current.min,
            locale: this.intl.locale,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          })}`
        );
    }

    if (bounds?.current.max) {
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('y', OUTER_RADIUS + 30)
        .attr('x', OUTER_RADIUS)
        .attr('data-donut-chart-sum-to', '')
        .attr('class', styles['donut-chart-sum-title'])
        .text(
          `${this.intl.t('overview.donut-chart.to')} ${dateToken({
            date: bounds.current.max,
            locale: this.intl.locale,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          })}`
        );
    }

    let chartSum = svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('y', OUTER_RADIUS - 11)
      .attr('x', OUTER_RADIUS)
      .attr('data-donut-chart-sum', '')
      .attr('class', styles['donut-chart-sum'])
      .text(this.intl.formatNumber(sum, { currency: 'EUR', style: 'currency' }));

    if (canAnimate()) {
      this._animateSum(chartSum, sum);
    }
  }

  _animateSum(chartSum, sum) {
    chartSum
      .transition()
      .tween('text', () => {
        let interpolator = interpolateNumber(0, sum);
        let formatNumber = this.intl.formatNumber.bind(this.intl);
        return function (t) {
          select(this).text(
            formatNumber(interpolator(t).toFixed(2), {
              currency: 'EUR',
              style: 'currency',
            })
          );
        };
      })
      .duration(ANIMATION_DURATION)
      .waitFor();
  }

  _highlightSlice(svg, currentSliceIndex) {
    svg
      .selectAll('[data-donut-chart-slice]')
      .nodes()
      .forEach((node, index) => {
        if (index !== currentSliceIndex) {
          node.style['opacity'] = 0.32;
        } else {
          node.style['opacity'] = 1;
        }
      });
  }

  _unhighlightSlices(svg) {
    svg
      .selectAll('[data-donut-chart-slice]')
      .nodes()
      .forEach(node => (node.style['opacity'] = 1));
  }
}
