export default {
  "invoice-sidebar": "coL",
  "body": "coT",
  "box": "coz",
  "box-header": "coa",
  "footer": "coH",
  "footer-button": "coO",
  "sidebar-box": "com",
  "border-top": "cop",
  "row": "cox",
  "status-container": "cow",
  "due-date-container": "coN",
  "mark-as-btn": "cov",
  "primary-actions": "coo com",
  "primary-action": "con body-1",
  "button-icon": "coi"
};
