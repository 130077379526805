/* import __COLOCATED_TEMPLATE__ from './past.hbs'; */
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { Tag } from 'design-system-kit';

import { Member } from 'qonto/react/components/tasks/table/cell/member';

export default class RequestTableItemPastComponent extends Component {
  tag = Tag;
  member = Member;

  get requestComponentName() {
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`tasks/table/cell/${type}`, this);
  }
}
