export default {
  "row": "qcz",
  "active": "qca",
  "dot": "qcH",
  "red": "qcO",
  "green": "qcm",
  "yellow": "qcp",
  "status": "qcx",
  "align-right": "qcw",
  "animated": "qcN",
  "fadein-item": "qcv",
  "cell": "qco body-2",
  "cell-content": "qcn",
  "amount": "qci body-2",
  "subtitle": "qcA caption",
  "padding-left": "qcY",
  "padding-right": "qch",
  "note": "qcf"
};
