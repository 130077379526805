/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class EmailPreviewComponent extends Component {
  @service intl;

  get relatedQuoteNumber() {
    return this.args.invoice.quote?.get('number');
  }

  get currency() {
    return this.args.invoice.currency || 'EUR';
  }

  get footerDisclaimer() {
    return this.intl.t('receivable-invoices.invoicing-sending.preview.invoicing-disclaimer', {
      language: this.args.displayLanguage,
      htmlSafe: true,
    });
  }
}
