export default {
  "mileage-validation": "cfc",
  "actions": "cfq",
  "action-btn": "cfZ",
  "action-btn-success": "cfR",
  "action-btn-right": "cfQ",
  "request-mileage-validation": "cfe",
  "account-selector": "cfB",
  "has-error": "cfE",
  "funds-disclaimer": "cfd",
  "account-options": "cfI",
  "account-balance": "cft",
  "warnings": "cfM"
};
