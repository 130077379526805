export default {
  "row": "qlx",
  "icon": "qlw",
  "cell": "qlN",
  "cell-date": "qlv",
  "cell-amount": "qlo",
  "cell-status": "qln",
  "cell-content": "qli",
  "align-right": "qlA"
};
