export default {
  "row": "cYo",
  "active": "cYn",
  "animated": "cYi",
  "fadein-item": "cYA",
  "cell": "cYY",
  "cell-content": "cYh",
  "row-sidebar": "cYf",
  "empty": "cYK",
  "cell-amount": "cYG",
  "subtitle": "cYr caption",
  "hidden": "cYb",
  "cell-approve": "cYy",
  "quick-actions": "cYU",
  "account-select": "cYj",
  "note": "cYW",
  "cell-content-amount": "cYu body-1",
  "cell-status": "chS"
};
