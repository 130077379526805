/* import __COLOCATED_TEMPLATE__ from './fullpage-empty-state.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';
import { variation } from 'ember-launch-darkly';

import { FULLPAGE_EMPTY_STATE_FF } from 'qonto/constants/receivable-invoice';

export default class ReceivableInvoicesFullpageEmptyStateComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service localeManager;
  @service receivableInvoicesUploadManager;
  @service router;
  @service segment;

  get emptyStateVariation() {
    return variation('feature--string-receivable-invoices-es-revamp');
  }

  get lottieLoop() {
    return false;
  }

  get lottiePath() {
    switch (this.localeManager.locale) {
      case 'de':
        return '/lotties/receivable-invoices/fullpage-empty-state/de.json';
      case 'es':
        return '/lotties/receivable-invoices/fullpage-empty-state/es.json';
      case 'fr':
        return '/lotties/receivable-invoices/fullpage-empty-state/fr.json';
      case 'it':
        return '/lotties/receivable-invoices/fullpage-empty-state/it.json';
      case 'en':
      default:
        return '/lotties/receivable-invoices/fullpage-empty-state/en.json';
    }
  }

  get shouldShowOldCopies() {
    return (
      variation('feature--string-receivable-invoices-es-revamp') ===
      FULLPAGE_EMPTY_STATE_FF.NEW_DESIGN_OLD_COPIES
    );
  }

  get subtitle() {
    return this.shouldShowOldCopies
      ? this.intl.t('receivable-invoices.invoices-list.empty-state.a3.unlimited.subtitle')
      : this.intl.t('receivable-invoices.invoices-list.empty-state.fullpage.subtitle');
  }

  get title() {
    return this.shouldShowOldCopies
      ? this.intl.t('receivable-invoices.invoices-list.empty-state.a3.title', {
          htmlSafe: true,
        })
      : this.intl.t('receivable-invoices.invoices-list.empty-state.fullpage.title');
  }

  @action
  openNewInvoiceForm() {
    this.segment.track('client-invoices_cta_clicked', {
      cta_type: 'create',
      empty_state: true,
      'invoicing-test_origin': this.emptyStateVariation,
      origin: 'empty_state_primary',
    });

    this.router.transitionTo('receivable-invoices.new');
  }

  @action
  trackFeatureTourLink() {
    this.segment.track('client-invoices_tour_clicked', {
      'invoicing-test_origin': this.emptyStateVariation,
    });
  }

  @action
  trackInvoicesUploader() {
    this.segment.track('invoice_imported_get-started_clicked', {
      'invoicing-test_origin': this.emptyStateVariation,
    });
  }
}
