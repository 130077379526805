export default {
  "wrapper": "qQF",
  "content": "qQg",
  "title": "qQD",
  "aside": "qQV",
  "description": "qQL",
  "faq-link": "qQT",
  "close-btn": "qQz",
  "container": "qQa",
  "mandate-field-list": "qQH",
  "mandate-field-item": "qQO",
  "mandate-field-item__dot": "qQm",
  "mandate-field-item__text": "qQp"
};
