/* import __COLOCATED_TEMPLATE__ from './status-tag.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from 'design-system-kit';

import { INVOICE_STATUSES, STATUS_COLORS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesStatusTagComponent extends Component {
  tag = Tag;

  @service intl;

  get status() {
    switch (this.args.status) {
      case INVOICE_STATUSES.scheduled:
        return {
          display: this.intl.t('supplier-invoices.preview.status.scheduled-payment'),
          color: STATUS_COLORS.scheduled,
        };
      case INVOICE_STATUSES.pending:
        return {
          display: this.intl.t('supplier-invoices.preview.status.pending-request'),
          color: STATUS_COLORS.pending,
        };
      case INVOICE_STATUSES.archived:
        return {
          display: this.intl.t('supplier-invoices.preview.status.archived'),
          color: STATUS_COLORS.archived,
        };
      case INVOICE_STATUSES.paid:
        return {
          display: this.intl.t('supplier-invoices.preview.status.paid'),
          color: STATUS_COLORS.paid,
        };
      case INVOICE_STATUSES.toReview:
        return {
          display: this.intl.t('supplier-invoices.preview.status.imported'),
          color: STATUS_COLORS.toReview,
        };
      default:
        return {
          display: this.intl.t('supplier-invoices.section.tab.inbox'),
          color: STATUS_COLORS.inbox,
        };
    }
  }
}
