export default {
  "header-cell": "qSG",
  "header-content": "qSr caption-bold",
  "active": "qSb",
  "empty": "qSy",
  "request-type": "qSU",
  "status": "qSj",
  "note": "qSW",
  "amount": "qSu"
};
