/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from 'design-system-kit';

import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';
import { MileageTableCell } from 'qonto/react/components/mileage/table/cell';

export default class ReimbursementsTableItemCompletedComponent extends Component {
  tag = Tag;

  mileageTableCell = MileageTableCell;

  @service organizationManager;
  @service intl;

  get requestAmount() {
    let { request } = this.args;
    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
    }
  }

  get displayedStatus() {
    switch (this.args.request.status) {
      case 'approved':
        return this.intl.t('request.status.approved');
      case 'declined':
        return this.intl.t('request.status.rejected');
      case 'canceled':
        return this.intl.t('request.status.canceled');
      case 'pending':
        return this.intl.t('request.status.pending');
      default:
        return null;
    }
  }

  get statusColor() {
    return REQUEST_STATUS_COLORS[this.args.request.status];
  }
}
