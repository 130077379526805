export default {
  "sidebar": "col",
  "body": "coX",
  "form": "coC",
  "form-section": "cok",
  "form-title": "coJ title2",
  "row": "cos",
  "payment-details": "coF",
  "col": "cog",
  "footer": "coD",
  "footer-button": "coV"
};
