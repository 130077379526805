export default {
  "numbering-container": "cNC",
  "automatic-number-label": "cNk",
  "icon-tooltip": "cNJ",
  "fix-width": "cNs",
  "dates-container": "cNF",
  "with-performance-date": "cNg",
  "date-picker": "cND",
  "label": "cNV",
  "header-text-field-container": "cNL",
  "mt-16": "cNT",
  "header-text-field": "cNz",
  "header-text-field-close-button": "cNa"
};
