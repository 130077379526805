/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { STATUS } from 'qonto/constants/requests';

export default class RequestSidebarExpenseReportHeaderComponent extends Component {
  @service intl;
  @service organizationManager;

  @reads('args.request.approver') approver;
  @reads('args.request.initiator') initiator;

  @reads('args.request.isApproved') showAccountName;

  constructor() {
    super(...arguments);
    this.state = this.args.state || STATUS.PENDING;
  }

  get lastActionDate() {
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get isUserReviewer() {
    let currentUserId = this.organizationManager.membership.id;
    let initiatorId = this.args.request.initiator.get('id');

    return currentUserId !== initiatorId;
  }

  get titleStatus() {
    let { approver, initiator, status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    if (pastStatuses.includes(status)) {
      let user = approver || initiator;
      let { firstName, lastName } = user.getProperties('firstName', 'lastName');
      let fullName = `${firstName.charAt(0)}. ${lastName}`;

      let statuses = {
        [STATUS.DECLINED]: this.intl.t('requests.sidebar.header.status.declined', {
          approver_name: fullName,
        }),
        [STATUS.APPROVED]: this.intl.t('requests.sidebar.header.status.reimbursed', {
          approver_name: fullName,
        }),
        [STATUS.CANCELED]: this.intl.t('requests.transfers.final_status.canceled', {
          name: fullName,
        }),
      };

      return statuses[status];
    }

    return this.isUserReviewer
      ? this.intl.t('requests.reimbursement.sidebar.title')
      : this.intl.t('requests.sidebar.header.status.pending');
  }

  get titleClass() {
    return this.isUserReviewer && this.args.request.status === STATUS.PENDING
      ? ''
      : this.args.request.status;
  }

  get subtitle() {
    let { status } = this.args.request;
    let formattedDate = this.intl.formatDateRelative(this.lastActionDate);

    if (status === 'pending') {
      return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
    } else if (['declined', 'approved'].includes(status)) {
      return this.intl.t('requests.sidebar.header.updated-date', { updated_at: formattedDate });
    } else {
      return formattedDate;
    }
  }
}
