/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { apiBaseURL } from 'qonto/constants/hosts';
import isFunction from 'qonto/utils/is-function';
import pushPayload from 'qonto/utils/store-push-payload';

const DEFAULT_MAX_SIZE = 5 * 1e6; // 5 MB
const DEFAULT_UPLOAD_URL = 'v3/files';

export default class UploaderComponent extends Component {
  @service intl;
  @service store;
  @service sentry;

  @tracked hiddenDropZone = false;
  @tracked errors = this.args.errors ?? new TrackedArray();

  constructor() {
    super(...arguments);

    if (this.args.errors) {
      assert(
        'if provided, @errors must be a TrackedArray',
        this.args.errors instanceof TrackedArray
      );
    }

    assert('You must pass @files with a valid array of Files', Array.isArray(this.args.files));
    assert(
      'You must pass @onFileUploaded with a callback function called when each file is uploaded',
      isFunction(this.args.onFileUploaded)
    );
    if (this.args.uploadOptions?.model) {
      if (!['attachment', 'file'].includes(this.args.uploadOptions?.model)) {
        throw new Error('The @uploadOptions.model accepts only `file` or `attachment` models');
      }
    }
  }

  get queueName() {
    return this.args.queueName ?? guidFor(this);
  }

  get maxSize() {
    return this.args.maxSize ?? DEFAULT_MAX_SIZE;
  }

  get text() {
    return this.args.dropZoneLabel ?? this.defaultUploaderText;
  }

  get defaultUploaderText() {
    return this.args.filesLimit === 1
      ? this.intl.t('join-team.about-you.add-poi.drop-zone.label', {
          maxSize: formatFileSize(this.intl, this.maxSize),
        })
      : this.intl.t('labels.upload-message', {
          maxSize: formatFileSize(this.intl, this.maxSize),
        });
  }

  get accept() {
    return this.args.extensions ?? '.pdf,.jpg,.jpeg,.png,.gif';
  }

  get uploadUrl() {
    return this.uploadOptions?.url
      ? `${apiBaseURL}/${this.uploadOptions.url}`
      : `${apiBaseURL}/${DEFAULT_UPLOAD_URL}`;
  }

  get onFileUploadedHideDropzone() {
    return this.args.onFileUploadedHideDropzone;
  }

  get callEndpoint() {
    // if callEndpoint is explicitly set, use it, otherwise default to true
    if (this.args?.uploadOptions?.callEndpoint === undefined) {
      return true;
    } else {
      return this.args?.uploadOptions?.callEndpoint;
    }
  }

  get uploadOptions() {
    return this.args.uploadOptions;
  }

  @action onCancelFile(file) {
    this.uploadTask.cancelAll();
    file?.queue?.remove(file);
    this.hiddenDropZone = false;
    this.args.onCancelFile?.(file);
  }

  handleLocalFileUpload(file) {
    let documentModel = this.store.createRecord('document', { filename: file.name });
    this.args.onFileUploaded(file, documentModel);
    file?.queue?.remove(file);
  }

  async handleRemoteFileUpload(file) {
    let newFileResponse = await file.upload(this.uploadUrl, {
      withCredentials: true,
      ...this.uploadOptions?.payload,
    });
    let payload = await newFileResponse.json();

    let fileModel, attachment;

    if (this.uploadOptions?.model === 'attachment') {
      attachment = pushPayload(this.store, 'attachment', {
        attachments: payload.attachment,
      });
      fileModel = file;
    } else {
      fileModel = pushPayload(this.store, 'file', payload);
    }

    this.args.onFileUploaded(fileModel, attachment);
  }

  uploadTask = task(
    waitFor(async file => {
      this.hiddenDropZone = this.onFileUploadedHideDropzone;

      this.errors.length = 0;

      if (this.tooManyFilesToUpload(file)) {
        this.errors.push({
          message: this.intl.t('errors.files_limit', { filesLimit: this.args.filesLimit }),
        });

        this.hiddenDropZone = false;
        file.queue.remove(file);
        this.args.onFileUploadedErrors?.(this.errors);
        return;
      }

      if (!this._validateFileSize(file)) {
        this.errors.push({
          file: file.name,
          message: this.intl.t('uploader.file_too_big', {
            maxSize: formatFileSize(this.intl, this.maxSize),
          }),
        });

        this.hiddenDropZone = false;
        file.queue.remove(file);
        this.args.onFileUploadedErrors?.(this.errors);
        return;
      }

      try {
        if (!this.callEndpoint) {
          this.handleLocalFileUpload(file);
        } else {
          await this.handleRemoteFileUpload(file);
        }
        // TODO we should probably filter error messages
      } catch (error) {
        this.sentry.captureException(error);
        this.errors.push({ file: file.name, message: this.intl.t('uploader.server_error') });
        file?.queue?.remove(file);
        this.hiddenDropZone = false;
        this.args.onFileUploadedErrors?.(error);
      }
    })
  );

  _validateFileSize(file) {
    return file.size < this.maxSize;
  }

  tooManyFilesToUpload(file) {
    let { files, filesLimit } = this.args;

    let shouldUseFilesLimit = Boolean(filesLimit);
    if (!shouldUseFilesLimit) {
      return false;
    }

    let toManyFiles = files.length + file.queue.files.length > filesLimit;

    return toManyFiles;
  }
}
