export default {
  "mileage-validation": "cWW",
  "actions": "cWu",
  "action-btn": "cuS",
  "approve-btn": "cuc",
  "request-mileage-validation": "cuq",
  "account-selector": "cuZ",
  "has-error": "cuR",
  "funds-disclaimer": "cuQ",
  "account-options": "cue",
  "account-balance": "cuB",
  "warnings": "cuE"
};
