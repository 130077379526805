export default {
  "card": "cDx",
  "header": "cDw",
  "avatar": "cDN",
  "title": "cDv",
  "subtitle": "cDo",
  "label": "cDn",
  "item": "cDi body-2",
  "header-icon": "cDA",
  "dropdown-menu": "cDY",
  "menu-item": "cDh body-2",
  "delete": "cDf"
};
