export default {
  "row": "qcS",
  "active": "qcc",
  "dot": "qcq",
  "red": "qcZ",
  "green": "qcR",
  "yellow": "qcQ",
  "status": "qce",
  "align-right": "qcB",
  "animated": "qcE",
  "fadein-item": "qcd",
  "cell": "qcI body-2",
  "cell-content": "qct",
  "amount": "qcM body-1",
  "subtitle": "qcP caption",
  "no-padding": "qcl"
};
