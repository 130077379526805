export default {
  "bullet": "cTS",
  "slice-0": "cTc",
  "slice-1": "cTq",
  "slice-2": "cTZ",
  "slice-3": "cTR",
  "slice-4": "cTQ",
  "slice-5": "cTe",
  "slice-6": "cTB",
  "slice-other": "cTE",
  "slice-uncategorized": "cTd",
  "label": "cTI",
  "ellipsis": "cTt",
  "rest": "cTM",
  "previous-date-range": "cTP caption"
};
