/* import __COLOCATED_TEMPLATE__ from './past.hbs'; */
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { Tag } from 'design-system-kit';

import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';

export default class RequestTableItemPastComponent extends Component {
  tag = Tag;

  get requestComponentName() {
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }

  get requestAmount() {
    let { request } = this.args;
    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
      case 'transfer':
        return { value: request.amount, currency: request.amountCurrency };
      case 'multi_transfer':
        return {
          value: request.totalTransfersAmount,
          currency: request.totalTransfersAmountCurrency,
        };
      case 'virtual_card':
        return { value: request.paymentMonthlyLimit, currency: request.currency };
      case 'flash_card':
        return { value: request.paymentLifespanLimit, currency: request.currency };
    }
  }

  get status() {
    let { status, displayedStatus } = this.args.request;

    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status],
    };
  }
}
