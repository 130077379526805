export default {
  "savings-account": "qRR",
  "closed": "qRQ",
  "badge": "qRe",
  "subtitle": "qRB",
  "amount": "qRE",
  "processing": "qRd",
  "gain": "qRI",
  "active": "qRt",
  "progress": "qRM",
  "progress-bar": "qRP",
  "progress-text": "qRl",
  "info": "qRX",
  "menu-item": "qRC body-2",
  "close-account": "qRk"
};
