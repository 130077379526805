export default {
  "wrapper": "cyO",
  "request-header": "cym",
  "request": "cyp",
  "scheduled": "cyx",
  "scheduled-label": "cyw",
  "note": "cyN",
  "note-label": "cyv",
  "note-label-danger": "cyo",
  "note-content": "cyn",
  "status": "cyi",
  "transfer-amount": "cyA"
};
