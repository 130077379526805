/* import __COLOCATED_TEMPLATE__ from './list-item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { REWARDS_STATUS } from 'qonto/constants/referral';

export default class ReferralListItemComponent extends Component {
  @service intl;

  REWARDS_STATUS = REWARDS_STATUS;

  get dateText() {
    let { reward } = this.args;

    let date;

    if (reward.status.date) {
      date = dateToken({
        date: reward.status.date,
        locale: this.intl.locale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      });
    }

    if (reward.status.value === REWARDS_STATUS.EARNED) {
      return this.intl.t('referral.tiered-program.sidebar.earn.label', {
        date,
      });
    } else if (reward.status.value === REWARDS_STATUS.PENDING) {
      if (reward.waiting_for_kyb) {
        return this.intl.t('referral.tiered-program.sidebar.pending.pending-kyb-label');
      } else if (reward.status.date) {
        return this.intl.t('referral.tiered-program.sidebar.pending.expiration-label', {
          date,
        });
      } else {
        return this.intl.t('referral.tiered-program.sidebar.pending.no-expiration-label');
      }
    } else if (reward.status.value === REWARDS_STATUS.EXPIRED) {
      return this.intl.t('referral.tiered-program.sidebar.expiration.label', {
        date,
      });
    }
  }
}
