export default {
  "modal": "cna",
  "action-container": "cnH",
  "similar-label": "cnO",
  "info-icon": "cnm",
  "container": "cnp",
  "subtitle": "cnx caption-bold",
  "error": "cnw",
  "item-container": "cnN"
};
