/* import __COLOCATED_TEMPLATE__ from './single.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const EXTERNAL_ACCOUNTS_EXCLUDED_DISCLAIMER = {
  isExternalAccountsDisclaimer: true,
  disabled: true,
};

export default class PowerSelectAccountsSingle extends Component {
  @tracked state;
  @tracked lastHighlighted;

  constructor() {
    super(...arguments);
    assert(
      'You must pass an `onUpdateAccount` action to <PowerSelect::Accounts::Single>',
      this.args.onUpdateAccount
    );
    assert(
      'You must pass a `selectedAccount` to <PowerSelect::Accounts::Single>',
      this.args.selectedAccount
    );
    assert(
      'You must pass an `accounts` array to <PowerSelect::Accounts::Single>',
      this.args.accounts
    );
  }

  @action
  registerAPI(state) {
    if (state) {
      this.state = state;
    }
  }

  @action
  hoverActions(e) {
    if (!this.state) return;

    if (e.type === 'mouseenter') {
      this.lastHighlighted = this.state.highlighted;
      this.state.actions.highlight(null);
    }

    if (e.type === 'mouseleave') {
      this.state.actions.highlight(this.lastHighlighted);
    }
  }

  get accounts() {
    let accounts = this.args.accounts;

    if (this.args.showExternalAccountsDisclaimer) {
      accounts.push(EXTERNAL_ACCOUNTS_EXCLUDED_DISCLAIMER);
    }

    return accounts;
  }
}
