/* import __COLOCATED_TEMPLATE__ from './navigable.hbs'; */
import { action } from '@ember/object';

import { getExtrema } from 'qonto/utils/chart/scale';

import CashflowChartComponent from '../cashflow';

export default class CashflowNavigableChartComponent extends CashflowChartComponent {
  get balanceTimeseries() {
    return this.args.balanceTimeseries || this.timeseries;
  }

  get fullTimeseries() {
    return this.args.fullTimeseries || this.timeseries;
  }

  @action
  drawGraph() {
    /**
     * Core graph
     */
    this._setRenderer(this.chartContainer);
    this.yScale = this._getYScale(this.fullTimeseries);
    this.xScale = this._getXScale(this.timeseries);

    /**
     * Axis
     */
    this._setYAxis(this.yScale);
    this._setYGridlines(this.yScale);
    this._setXAxis(this.xScale, this.tickFormatter);
    /**
     * Bars
     */
    this._drawMonthContainers(this.timeseries, this.xScale);

    if (this.hasData) {
      this._drawBars(this.timeseries, this.xScale, this.yScale);
      this._drawBalance(this.balanceTimeseries, this.xScale, this.yScale);
    }

    this._linkFlowTooltips();
    this._linkMonthTooltips();

    this.isFirstDraw = false;
  }

  @action onPreviousMonth() {
    this.args.onPreviousMonth?.();
    this.onResize();
  }

  @action onNextMonth() {
    this.args.onNextMonth?.();
    this.onResize();
  }

  _getYExtrema() {
    let series = this.fullTimeseries.map(({ data }) => Object.values(data)).flat();
    return getExtrema(series, this.defaultYMax, this.headroom);
  }
}
