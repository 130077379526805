/* import __COLOCATED_TEMPLATE__ from './payment-methods.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { AUTHORIZED_USD_PAYMENT_METHODS } from 'qonto/constants/international-out/payment-method';
import { DEFAULT_TARGET_CURRENCY } from 'qonto/constants/international-out/quote';

const DISCLAIMER_OPTIONS = {
  level: 'info',
  type: 'inline',
};

export default class TransfersInternationalOutProvidersPaymentMethods extends Component {
  @service intl;
  @service zendeskLocalization;

  get disclaimer() {
    let { currency, paymentMethods } = this.args;

    let authorizedPaymentMethods = Object.values(AUTHORIZED_USD_PAYMENT_METHODS);

    if (
      currency === DEFAULT_TARGET_CURRENCY.currencyCode &&
      paymentMethods?.length === authorizedPaymentMethods.length &&
      paymentMethods?.every(method => authorizedPaymentMethods.includes(method))
    ) {
      return {
        ...DISCLAIMER_OPTIONS,
        content: this.intl.t('international-out.transfer-methods.disclaimer.usd.text', {
          link: htmlSafe(
            `<a
              href=${this.intl.t('international-out.transfer-methods.disclaimer.usd.link', {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(9105540),
              })}
              target="_blank"
              rel="noopener noreferrer"
              class="body-link"
              data-test-payment-methods-faq-link
            >
              ${this.intl.t('international-out.transfer-methods.disclaimer.usd.linkText')}
            </a>`
          ),
          htmlSafe: true,
        }),
      };
    }

    if (paymentMethods?.length > 1) {
      return {
        ...DISCLAIMER_OPTIONS,
        content: this.intl.t('international-out.transfer-methods.disclaimer.generic.text', {
          link: htmlSafe(
            `<a
              href=${this.intl.t('international-out.transfer-methods.disclaimer.generic.link', {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(9105540),
              })}
              target="_blank"
              rel="noopener noreferrer"
              class="body-link"
              data-test-payment-methods-faq-link
            >
              ${this.intl.t('international-out.transfer-methods.disclaimer.generic.linkText')}
            </a>`
          ),
          htmlSafe: true,
        }),
      };
    }

    return null;
  }
}
