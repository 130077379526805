/* import __COLOCATED_TEMPLATE__ from './schedule-transfer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { REQUEST_TRANSFER_SCHEDULE_OPERATION_TYPES } from 'qonto/constants/requests';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { getCurrentParisDateString } from 'qonto/utils/date';

export default class RequestTransferSchedule extends Component {
  @service intl;
  @service segment;
  @service featuresManager;

  operationTypes = [
    ...new Set([
      ...REQUEST_TRANSFER_SCHEDULE_OPERATION_TYPES,
      SCHEDULE_OPERATION_TYPES.WEEKLY,
      SCHEDULE_OPERATION_TYPES.MONTHLY,
      SCHEDULE_OPERATION_TYPES.QUARTERLY,
      SCHEDULE_OPERATION_TYPES.HALF_YEARLY,
      SCHEDULE_OPERATION_TYPES.YEARLY,
    ]),
  ];

  operationTypeTranslations = {
    scheduled_later: this.intl.t('transfers.frequency.scheduled_later'),
    fx_scheduled: this.intl.t('transfers.frequency.fx_scheduled'),
    standing_monthly_gender: this.intl.t('transfers.frequency.standing_monthly_gender'),
    scheduled: this.intl.t('transfers.frequency.scheduled'),
    standing_weekly_short: this.intl.t('transfers.frequency.standing_weekly_short'),
    [SCHEDULE_OPERATION_TYPES.WEEKLY]: this.intl.t('transfers.frequency.standing_weekly'),
    [SCHEDULE_OPERATION_TYPES.MONTHLY]: this.intl.t('transfers.frequency.standing_monthly'),
    [SCHEDULE_OPERATION_TYPES.QUARTERLY]: this.intl.t('transfers.frequency.standing_quarterly'),
    [SCHEDULE_OPERATION_TYPES.HALF_YEARLY]: this.intl.t('transfers.frequency.standing_half_yearly'),
    [SCHEDULE_OPERATION_TYPES.YEARLY]: this.intl.t('transfers.frequency.standing_yearly'),
  };

  get isScheduledLater() {
    return this.args.requestTransfer.operationType !== 'scheduled';
  }

  get scheduledDate() {
    return this.args.requestTransfer.scheduledDate;
  }

  get minDate() {
    let { requestTransfer } = this.args;

    let tomorrow = dayjs().add(1, 'day').startOf('day');
    let isDateValid =
      dayjs(requestTransfer.scheduledDate).isBefore(tomorrow) || this.isScheduledLater;

    let relevantDate = isDateValid ? tomorrow : requestTransfer.scheduledDate;
    return dayjs(relevantDate).format(DATE_PICKER_FIELD_FORMAT);
  }

  get endMinDate() {
    let { requestTransfer } = this.args;

    return requestTransfer.scheduledDate
      ? dayjs(requestTransfer.scheduledDate).add(1, 'day').format(DATE_PICKER_FIELD_FORMAT)
      : this.minDate;
  }

  get disclaimer() {
    let {
      weekly,
      monthly,
      quarterly,
      halfYearly,
      standing,
      standingEndingDate,
      scheduledDate,
      occurrences,
    } = this.args.requestTransfer;

    if (!standing || !scheduledDate) {
      return null;
    }

    let disclaimerOptions = {
      startDate: dayjs(scheduledDate).toDate(),
      endDate: dayjs(standingEndingDate).toDate(),
      transferOccurences: occurrences,
    };

    if (weekly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_weekly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_weekly_frequency_unlimited', disclaimerOptions);
    } else if (monthly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_monthly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_monthly_frequency_unlimited', disclaimerOptions);
    } else if (quarterly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_quarterly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_quarterly_frequency_unlimited', disclaimerOptions);
    } else if (halfYearly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_half_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_half_yearly_frequency_unlimited', disclaimerOptions);
    } else {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_yearly_frequency_unlimited', disclaimerOptions);
    }
  }

  @action handleOperationType(operationType) {
    let { trackingData, requestTransfer } = this.args;

    requestTransfer.changeOperationType(operationType);
    requestTransfer.standingOn = null;

    this.segment.track('single_transfer_request_frequency_filled', {
      frequency_type: operationType,
      member_id: trackingData.memberId,
      user_id: trackingData.userId,
    });
  }

  @action setScheduledDate(date) {
    let { requestTransfer } = this.args;

    requestTransfer.scheduledDate = date;

    if (dayjs(requestTransfer.standingEndingDate).isBefore(this.endMinDate)) {
      requestTransfer.standingEndingDate = this.endMinDate;
    }
  }

  @action handleToggleScheduled() {
    let { requestTransfer } = this.args;

    let isScheduledLater = !this.isScheduledLater;
    let today = getCurrentParisDateString();

    if (!requestTransfer.scheduledDate) {
      requestTransfer.scheduledDate = today;
    }

    this._toggleScheduledState(isScheduledLater);
    this.segment.track('transfer_schedule_checkbox');
  }

  _toggleScheduledState(isScheduledLater) {
    let { requestTransfer } = this.args;

    if (isScheduledLater) {
      requestTransfer.changeOperationType('scheduled_later');
      let minDate = this.minDate;

      let minDateinDateFormat = dayjs(minDate).toDate();
      if (dayjs(requestTransfer.scheduledDate).toDate() < minDateinDateFormat) {
        requestTransfer.scheduledDate = this.minDate;
      }
    } else {
      requestTransfer.changeOperationType('scheduled');
    }
  }
}
