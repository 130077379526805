export default {
  "modal": "qtI",
  "action-container": "qtt",
  "similar-label": "qtM",
  "info-icon": "qtP",
  "container": "qtl",
  "subtitle": "qtX caption-bold",
  "error": "qtC",
  "item-container": "qtk"
};
