export default {
  "row": "qLb",
  "cell": "qLy",
  "empty": "qLU",
  "icon": "qLj",
  "type": "qLW",
  "amount-content": "qLu",
  "cell-approve": "qTS",
  "approve-content": "qTc",
  "row-compact": "qTq"
};
