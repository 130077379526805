export default {
  "sidebar-content": "qgj",
  "section": "qgW",
  "header-wrapper": "qgu",
  "transfer-id": "qDS caption",
  "small-padding": "qDc",
  "title": "qDq",
  "subtitle": "qDZ",
  "actions": "qDR"
};
