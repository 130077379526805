export default {
  "row": "cUQ",
  "empty": "cUe",
  "active": "cUB",
  "animated": "cUE",
  "fadein-item": "cUd",
  "cell": "cUI body-2",
  "cell-content": "cUt",
  "cell-amount": "cUM",
  "cell-content-amount": "cUP body-1"
};
