export default {
  "header-cell": "cfV",
  "empty": "cfL",
  "header-type": "cfT",
  "header-requester": "cfz",
  "header-date": "cfa",
  "header-amount": "cfH",
  "header-approval": "cfO",
  "header-content": "cfm caption-bold",
  "active": "cfp",
  "header-receipt": "cfx",
  "row-compact": "cfw",
  "row-sidebar": "cfN",
  "hidden": "cfv",
  "animated": "cfo",
  "fadein-item": "cfn",
  "animated-cell": "cfi"
};
