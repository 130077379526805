/* import __COLOCATED_TEMPLATE__ from './kyc-kyb-pending-update.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const KYCB_UPDATE_BANNER_STORAGE_KEY = 'PERIODIC_KYBC_UPDATE_TOPBANNER_DISMISSED';

export default class KycKybPendingUpdateTopBar extends Component {
  @action close() {
    let { updateID } = this.args.content;

    safeLocalStorage.setItem(KYCB_UPDATE_BANNER_STORAGE_KEY, updateID);
    this.args.close();
  }
}
