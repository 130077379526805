/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { getRequestMileageVehiclesTypes, STATUS } from 'qonto/constants/requests';
import { generateGoogleMapsLink } from 'qonto/utils/generate-google-maps-link';

export default class ReimbursementsRequestsSidebarMileageDetailsComponent extends Component {
  @service intl;
  @service abilities;

  get googleMapsLink() {
    let { departure, arrival, roundTrip } = this.args.request;
    return generateGoogleMapsLink(departure, arrival, roundTrip);
  }

  get distanceInKm() {
    return (this.args.request.mileageCalculation.distanceMeters / 1000).toFixed(2);
  }

  get vehicleFiscalPower() {
    let vehicles = getRequestMileageVehiclesTypes(this.intl);
    let { type, power } = this.args.request.vehicle;

    let vehicleLabel = vehicles[type].label;

    if (!power) {
      return vehicleLabel;
    }

    let powerOption = vehicles[type].powerOptions.find(option => option.value === power);

    let powerLabel = powerOption.label;
    let fiscalPowerLabel = this.intl.t('requests.mileage.steps.request-details.fiscal-power', {
      fiscal_power: powerLabel,
    });

    return `${vehicleLabel} (${fiscalPowerLabel})`;
  }

  get showDisclaimer() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');

    return (
      request.status === STATUS.PENDING &&
      this.abilities.can('cancel mileage request', userId) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  get shouldDisplayApprovalWorkflowState() {
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');
    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}
