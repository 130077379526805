/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import dayjs from 'dayjs';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { GENERIC_IBAN, INVOICE_STATUSES, SOURCE_TYPES } from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import removeKey from 'qonto/utils/remove-key';

class FormValidationError extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'FormValidationError';
  }
}

class Form {
  @tracked errors;
  @tracked values;

  constructor(schema) {
    this.schema = schema;
    this.values = { ...schema.initialValues };
    this.validations = { ...schema.validationsSchema };
  }

  get isDirty() {
    return Object.entries(this.values).some(([key, value]) => {
      if (value instanceof Date) {
        return !dayjs(this.schema.initialValues[key]).isSame(dayjs(value), 'day');
      }
      if (key === 'amount') {
        return this.isAmountDirty;
      }

      return this.schema.initialValues[key] !== value;
    });
  }

  get isAmountDirty() {
    return (
      this.schema.initialValues.amount !== this.values.amount &&
      Number(this.schema.initialValues.amount) !== Number(this.values.amount)
    );
  }

  get isValid() {
    if (this.errors) {
      return Object.values(this.errors).every(value => !value);
    }

    return true;
  }

  get isIBANObfuscated() {
    return /^(?:••••|xxxx)/i.test(this.values.iban);
  }

  async submit({ partial = false } = {}) {
    if (this.isIBANObfuscated) {
      this.values.iban = null;
    }

    try {
      if (this.isValid) {
        await this.schema.onSubmit(this.values, partial);
      } else {
        throw new FormValidationError();
      }
    } catch (error) {
      if (error.isAdapterError) {
        this.errors = normalizeErrors(error.errors, this.validations);
      }
      throw error;
    }
  }

  reset() {
    this.values = { ...this.schema.initialValues };
    this.errors = undefined;
    this.schema.onReset();
  }

  updateField(prop, value, hasInvalidDate) {
    this.values = { ...this.values, [prop]: value };
    let error = (typeof hasInvalidDate === 'boolean' && hasInvalidDate) || false;
    this.errors = { ...this.errors, [prop]: error };
    if (prop === 'amount') {
      this.errors = { ...this.errors, currency: false };
    }
  }
}

function normalizeErrors(errors = [], validations) {
  let errorsObject = {};

  errors.forEach(it => {
    let pathElements = it.source.pointer.split('/');
    let errorKey = pathElements.at(-1);
    errorKey = errorKey === 'value' ? 'amount' : errorKey;
    let errorCode = it.code;

    errorsObject[camelize(errorKey)] =
      errorCode === 'required' ? validations[errorCode] : validations[errorKey];
  });
  return errorsObject;
}

export default class SupplierInvoicesEditFormComponent extends Component {
  @service abilities;
  @service intl;
  @service modals;
  @service errors;
  @service toastFlashMessages;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;

  @reads('organizationManager.organization') organization;

  @action
  redirectToReviewDuplicatesModal(event) {
    event.preventDefault();
    this.modal = this.modals.open('supplier-invoices/review-duplicates-modal', {
      invoiceId: this.args.invoice.id,
      isFullScreenModal: true,
    });
    this.segment.track('supplier-invoices_duplicates-disclaimer_clicked');
  }

  form = new Form({
    onSubmit: (values, partial) =>
      partial || this.args.partial
        ? this.savePartialInvoiceTask.perform(values)
        : this.saveInvoiceTask.perform(values),
    onReset: () => this.args.onClose?.(),

    initialValues: this.defaultValues,

    validationsSchema: this.validationsSchema,
  });

  constructor() {
    super(...arguments);
    // send the form instance to the parent
    this.args.onCreate?.(this.form);
    this.checkTransferLimitsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.withScope(scope => {
            if (errorInfo.httpStatus === 422 && error.errors?.length) {
              error.errors.forEach((e, i) => {
                scope.setContext(`error_${i}`, e);
              });
            }

            this.sentry.captureException(error);
          });
        }
      });
  }

  get defaultValues() {
    return {
      supplierName: this.args.invoice.supplierName,
      iban: this.args.invoice.iban,
      invoiceNumber: this.args.invoice.invoiceNumber,
      dueDate: this.args.invoice.dueDate,
      paymentDate: this.args.invoice.paymentDate,
      amount: this.args.invoice.totalAmount?.value,
      currency: this.args.invoice.totalAmount?.currency || 'EUR',
      description: this.args.invoice.description,
    };
  }

  get validationsSchema() {
    return {
      amount: this.intl.t('supplier-invoices.edit.modal.supplier-details.amount-error'),
      iban: this.intl.t('supplier-invoices.edit.modal.supplier-details.iban-error'),
      required: this.intl.t('validations.errors.blank'),
    };
  }

  get isReadonly() {
    return [INVOICE_STATUSES.paid, INVOICE_STATUSES.scheduled].includes(this.args.invoice.status);
  }

  get isInbox() {
    return [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice.status);
  }

  get transferLimits() {
    return this.checkTransferLimitsTask.lastSuccessful?.value;
  }

  get isRunning() {
    return this.saveInvoiceTask.isRunning || this.checkTransferLimitsTask.isRunning;
  }

  get isSwiftInvoice() {
    return this.form.values.currency !== CURRENCIES.default;
  }

  get showSelfInvoiceSection() {
    let isEInvoice = this.args.invoice?.source === SOURCE_TYPES.E_INVOICING;
    return this.abilities.can('read self-invoice') && !isEInvoice;
  }

  get showDisclaimer() {
    let { hasDuplicates, selfInvoiceId, isAttachmentNonFinancial } = this.args.invoice;

    return (
      this.abilities.can('review supplier-invoice') &&
      ((hasDuplicates && !selfInvoiceId) ||
        (variation('feature-non-valid-documents') && isAttachmentNonFinancial))
    );
  }

  submitFormTask = restartableTask(async () => {
    let submitted = true;
    try {
      await this.form.submit();
    } catch (error) {
      submitted = false;
      let errorInfo = ErrorInfo.for(error);
      if (
        errorInfo.shouldSendToSentry &&
        error.status !== 400 &&
        !(error instanceof FormValidationError)
      ) {
        this.sentry.captureException(error);
      }
    }
    return submitted;
  });

  @action
  updateField(prop, value, hasInvalidDate) {
    this.form.updateField(prop, value, hasInvalidDate);

    if (prop === 'amount') {
      this.submitAmountTask.perform().catch(ignoreCancelation);
    }

    if (['amount', 'currency'].includes(prop)) {
      this.checkTransferLimitsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.withScope(scope => {
              if (errorInfo.httpStatus === 422 && error.errors?.length) {
                error.errors.forEach((e, i) => {
                  scope.setContext(`error_${i}`, e);
                });
              }

              this.sentry.captureException(error);
            });
          }
        });
    }
  }

  @action
  selectSupplier(item) {
    this.form.updateField('supplierName', item.name);

    /**
     * Saving the beneficiaryId to find back the related beneficiary in the Request Form
     * https://gitlab.qonto.co/front/qonto-js/-/merge_requests/25321
     */
    this.beneficiaryId = item.id;

    if (item.customOptionMessage) {
      this.segment.track('supplier-invoices_edit_use-as-supplier-name_clicked');
    } else {
      this.segment.track('supplier-invoices_edit_select-existing-supplier_clicked');
      if (item.iban) {
        this.form.updateField('iban', item.iban);
      }
    }
  }

  @action
  cancelEdit() {
    this.form.reset();
  }

  submitAmountTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);
    if (this.form.isAmountDirty && !this.form.errors.amount) {
      try {
        await this.form.submit({ partial: true });
      } catch (error) {
        this.errors.handleError(error);
      }
    }
  });

  savePartialInvoiceTask = dropTask(
    async values => await this._saveInvoiceTask.perform(values, { partial: true })
  );

  saveInvoiceTask = restartableTask(
    async values => await this._saveInvoiceTask.perform(values, {})
  );

  _saveInvoiceTask = restartableTask(async (values, adapterOptions) => {
    let { iban, invoiceNumber, supplierName, dueDate, paymentDate, description } = values;

    if (typeof values.amount !== 'undefined') {
      this.args.invoice.totalAmount = {
        value: values.amount,
        currency: values.currency,
      };
    }

    this.args.invoice.supplierName = supplierName;
    this.args.invoice.invoiceNumber = invoiceNumber;
    this.args.invoice.dueDate = dueDate;
    this.args.invoice.paymentDate = paymentDate;
    this.args.invoice.description = description;
    this.args.invoice.beneficiaryId = this.beneficiaryId;
    this.args.invoice.iban = this.form.isIBANObfuscated ? null : iban;

    try {
      await this.args.invoice.save({ adapterOptions });

      let initialStateTrackPayload = {
        ...this.form.schema.initialValues,
      };

      let finalStateTrackPayload = {
        ...this.form.values,
      };

      this.segment.track('supplier-invoices_edit-save_clicked', {
        initial_state: removeKey(initialStateTrackPayload, 'iban'),
        final_state: removeKey(finalStateTrackPayload, 'iban'),
      });

      this.form.schema.initialValues = this.defaultValues;

      if (!adapterOptions.partial) {
        this.args.onClose?.();
      }
    } catch (error) {
      let { status } = error;

      this.args.invoice.rollbackAttributes();

      if (status === 400) {
        if (error.errors.some(e => e.code === 'required')) {
          this.segment.track('supplier-invoices_edit_mandatory-fields');
        }
        throw error;
      } else if (status === 0 || status === 500) {
        let message = this.intl.t('supplier-invoices.edit.modal.error-toast.save-changes');
        this.toastFlashMessages.toastError(message);
        throw error;
      } else {
        this.errors.handleError(error);
      }
    }
  });

  checkTransferLimitsTask = restartableTask(async () => {
    let { amount, currency, invoiceNumber, supplierName } = this.form.values;

    if (
      this.abilities.cannot('create transfer') ||
      this.args.invoice.status === INVOICE_STATUSES.paid ||
      !parseFloat(amount) > 0
    )
      return;

    let debounce = macroCondition(isTesting()) ? 0 : 500;
    await timeout(debounce);

    let fx = currency !== CURRENCIES.default;
    let amountDetails = fx
      ? {
          localAmountCurrency: currency,
          localAmount: amount,
        }
      : {
          amountCurrency: currency,
          amount,
        };

    if (this.transfer) {
      this.transfer.setProperties({ ...amountDetails, fx });
    } else {
      this.transfer = this.store.createRecord('transfer', {
        bankAccount: this.organization.mainAccount,
        organization: this.organization,
        fx,
        iban: GENERIC_IBAN,
        name: supplierName || 'name',
        activityTag: 'other_expense',
        reference: invoiceNumber || 'reference',
        ...amountDetails,
      });
    }

    let response = await (fx ? this.transfer.confirmFx() : this.transfer.confirm());

    this._manageAmountValidation(response.warnings, fx);

    return response;
  });

  _manageAmountValidation(warnings, fx) {
    let hasTransferLimitWarnings =
      fx && warnings.some(warning => SPEND_LIMITS_WARNINGS.includes(warning));

    let amount = hasTransferLimitWarnings
      ? this.intl.t('validations.errors.supplier-invoices.transfer-limit-exceeded')
      : null;
    this.form.errors = { ...this.form.errors, amount };
  }
}
