export default {
  "row": "chc",
  "icon": "chq",
  "cell": "chZ",
  "cell-amount": "chR",
  "cell-status": "chQ",
  "cell-date": "che",
  "cell-content": "chB",
  "align-right": "chE"
};
