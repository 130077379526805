export default {
  "invoice-sidebar": "cvG",
  "body": "cvr",
  "box": "cvb",
  "box-header": "cvy",
  "footer": "cvU",
  "footer-button": "cvj",
  "footer-button-tooltip": "cvW",
  "sidebar-box": "cvu",
  "border-top": "coS",
  "row": "coc",
  "status-container": "coq",
  "due-date-container": "coZ",
  "greyed-out": "coR",
  "struck-through": "coQ",
  "overdue": "coe",
  "primary-actions": "coB cvu",
  "primary-action": "coE",
  "danger-action": "cod",
  "button-icon": "coI",
  "button-label": "cot body-2",
  "related-credit-note-title": "coM caption-bold",
  "paid-at-placeholder": "coP"
};
