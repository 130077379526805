export default {
  "card-details-container": "cUV",
  "title": "cUL caption-bold mb-16",
  "section": "cUT",
  "divider": "cUz",
  "top-divider": "cUa",
  "content": "cUH body-2",
  "description": "cUO",
  "justify-end": "cUm",
  "icon": "cUp ml-8"
};
