export default {
  "header-cell": "cYa",
  "header-content": "cYH caption-bold",
  "active": "cYO",
  "empty": "cYm",
  "request-type": "cYp",
  "date": "cYx",
  "requester": "cYw",
  "amount": "cYN",
  "header-status": "cYv"
};
