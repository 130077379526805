/* import __COLOCATED_TEMPLATE__ from './nrc-payment.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Date } from 'qonto/react/components/transactions/sidebar/header/date';

export default class NrcPaymentHeader extends Component {
  Date = Date;

  @service intl;

  get subject() {
    return this.args.highlightedItem.subject;
  }

  get nrc() {
    return this.subject.get('nrc');
  }

  get modeloDescription() {
    let taxCode = this.subject.get('taxCode');
    let taxDescription = this.subject.get('taxDescription');
    return `${this.intl.t('aeat.sidepanel.labels.modelo')} ${taxCode} - ${taxDescription}`;
  }
}
