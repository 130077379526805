export default {
  "container": "qJa",
  "header": "qJH",
  "title": "qJO caption-bold",
  "text": "qJm body-2",
  "trigger": "qJp",
  "loading-state": "qJx",
  "dropzone-placeholder-wrapper": "qJw",
  "dropzone-placeholder": "qJN",
  "file-loading": "qJv"
};
