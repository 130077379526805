/* import __COLOCATED_TEMPLATE__ from './internal-note-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class InternalNoteFieldComponent extends Component {
  @tracked showInput = false;

  @action deleteInternalNote() {
    this.args.model.internalNote = undefined;
    this.showInput = false;
  }
}
