export default {
  "wrapper": "cTW",
  "header": "cTu",
  "fadeIn": "czS",
  "header-content": "czc",
  "with-cta": "czq",
  "header-title": "czZ title-4",
  "advanced-filters-trigger": "czR",
  "filters-button": "czQ",
  "filters-button-upsell": "cze",
  "filters-upsell-item": "czB",
  "filters-badge": "czE",
  "chart-container": "czd",
  "chart-legend-container": "czI mb-24",
  "chart-watermark-container": "czt",
  "chart-watermark": "czM",
  "placeholder-title": "czP",
  "placeholder-subtitle": "czl",
  "advanced-filters": "czX"
};
