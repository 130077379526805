/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { KYC_STATUS } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const ERRORS = {
  TOP_UP_REQUIRED: 'top_up_required',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  BILLER_INSUFFICIENT_FUNDS: 'biller_insufficient_funds',
  IBAN_SEPA_ERROR: 'iban_sepa_error',
  INSUFFICIENT_PER_TRANSFER_LIMITS: 'insufficient_per_transfer_limits',
  INSUFFICIENT_MONTHLY_LIMITS: 'insufficient_monthly_limits',
};

export default class MileageRequestValidation extends Component {
  @service abilities;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service organizationManager;
  @service segment;
  @service sensitiveActions;
  @service linkManager;
  @service zendeskLocalization;

  @reads('organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts')
  activeAccounts;
  @reads('organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts')
  hasMultipleActiveCurrentRemuneratedAccounts;
  @reads('organizationManager.membership.kycSubmitted') kycSubmitted;
  @reads('organizationManager.membership.personalInfoProvided') personalInfoProvided;

  get isDeclineCTADisabled() {
    return this.kycNotApproved;
  }

  get kycNotApproved() {
    let { kycStatus } = this.organizationManager.membership;

    return kycStatus !== KYC_STATUS.ACCEPTED;
  }

  get canShowAccountSelector() {
    let { request, spendLimitsWarning } = this.args;

    return (
      !this.kycNotApproved &&
      this.abilities.can('review mileage request') &&
      this.hasMultipleActiveCurrentRemuneratedAccounts &&
      !spendLimitsWarning &&
      request.lastStep
    );
  }

  get confirmWarnings() {
    let { confirmWarnings, confirmErrors } = this.args;
    let hasInsufficientFundsErrors = confirmErrors?.includes(ERRORS.BILLER_INSUFFICIENT_FUNDS);

    if (hasInsufficientFundsErrors) {
      confirmWarnings = confirmWarnings?.filter(
        warning => warning.warningType !== ERRORS.INSUFFICIENT_FUNDS
      );
    }

    return confirmWarnings ?? [];
  }

  get confirmErrors() {
    let { confirmErrors = [] } = this.args;
    return confirmErrors.map(error => this.confirmErrorToMessage(error));
  }

  get isApproveCTADisabled() {
    let { loadingRequest, request, confirmErrors, spendLimitsWarning } = this.args;

    return (
      this.kycNotApproved ||
      loadingRequest ||
      request.hasPastEndingDate ||
      confirmErrors?.length > 0 ||
      spendLimitsWarning?.length > 0
    );
  }

  get isManager() {
    return this.organizationManager.membership.manager;
  }

  get spendLimitWarningTranslation() {
    let { spendLimitsWarning, spendLimits } = this.args;
    let canReadSpendLimitWarnings = this.isManager && spendLimitsWarning;

    if (!canReadSpendLimitWarnings) return null;

    return this.spendLimitWarningToMessage(spendLimitsWarning, spendLimits);
  }

  approveRequestTask = dropTask(async () => {
    try {
      let message = this.intl.t('requests.mileage.toasts.request-approved', {
        count: 1,
      });

      if (!this.args.request.lastStep) {
        message = this.intl.t('approval-workflows.requests.approved.toast.success');
      }

      await this.args.approve.linked().perform(this.args.request);
      this.segment.track('request_approved', { request_type: 'mileage' });

      this.toastFlashMessages.toastSuccess(message);
      this.refreshRequestList();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  cancelRequestModalTask = dropTask(async () => {
    this.segment.track('request_cancel_clicked', {
      request_type: 'mileage',
      origin: this.args.origin,
      method: 'sidepanel',
    });

    await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.modals.cancel-modal.title'),
      description: this.intl.t('requests.modals.cancel-modal.description'),
      cancel: this.intl.t('btn.close'),
      confirm: this.intl.t('btn.cancel'),
      confirmTask: this.cancelRequestTask,
    });
  });

  cancelRequestTask = dropTask(async closeModal => {
    let { request } = this.args;
    await request.cancelRequest();
    this.args.cancel?.(request);

    this.segment.track('request_canceled', {
      request_type: 'mileage',
    });
    this.toastFlashMessages.toastInfo(this.intl.t('requests.cards.toasts.request-cancelled'));
    closeModal();
    this.refreshRequestList();
  });

  declineTask = dropTask(async close => {
    await this.args.decline(this.args.request);
    close();
    this.segment.track('request_declined', {
      request_type: 'mileage',
    });

    this.toastFlashMessages.toastInfo(this.intl.t('requests.mileage.toasts.request-rejected'));
    this.refreshRequestList();
  });

  @action
  approveRequest() {
    this.segment.track('request_approved_clicked', {
      outcome: this.args.request.lastStep ? 'payment' : 'approval',
      request_type: 'mileage',
    });
    this.sensitiveActions.runTask.perform(this.approveRequestTask).catch(ignoreCancelation);
  }

  @action
  declineRequest() {
    this.segment.track('request_declined_clicked', { request_type: 'mileage' });
    this.modals.open('request/sidebar/modals/decline-request', {
      request: this.args.request,
      confirmTask: this.declineTask,
    });
  }

  @action
  handleSetAccount(account) {
    this.args.setAccount(this.args.request, account);
  }

  confirmErrorToMessage(error) {
    switch (error) {
      case ERRORS.BILLER_INSUFFICIENT_FUNDS:
        return this.intl.t('validations.errors.insufficient_funds');
      case ERRORS.IBAN_SEPA_ERROR:
        return this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.body', {
          transferFaqLink: htmlSafe(
            `<a
              href="${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.url', {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(4359546),
              })}"
              target="_blank"
              rel="noopener noreferrer"
              data-test-beneficiary-not-sepa-link
            >${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.link-text')}</a>`
          ),
          htmlSafe: true,
        });
    }
  }

  refreshRequestList() {
    this.args.close?.();
  }

  spendLimitWarningToMessage(warning, limits) {
    switch (warning) {
      case ERRORS.INSUFFICIENT_PER_TRANSFER_LIMITS:
        return this.intl.t('requests.transfers.sidebar.disclaimers.per-transfer-limit', {
          per_transfer_limit: this.intl.formatNumber(limits.per_transfer_limit.value),
        });
      case ERRORS.INSUFFICIENT_MONTHLY_LIMITS:
        return this.intl.t('requests.transfers.sidebar.disclaimers.monthly-transfer-limit', {
          balance_monthly_transfer_limit: this.intl.formatNumber(
            Number(limits.monthly_transfer_limit.value) -
              Number(limits.current_month_spendings.value)
          ),
        });
    }
  }
}
