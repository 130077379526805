export default {
  "container": "cNx",
  "title-and-trash": "cNw",
  "trash": "cNN",
  "quantity-amount": "cNv",
  "flex-row": "cNo",
  "quantity": "cNn",
  "quantity-input": "cNi",
  "border-left": "cNA",
  "dropdown": "cNY",
  "item-row": "cNh",
  "unit-price": "cNf",
  "price-input": "cNK",
  "hidden": "cNG",
  "d-none": "cNr",
  "dropdown-option": "cNb",
  "list-row": "cNy",
  "row-title": "cNU",
  "row-price": "cNj body-2",
  "row-internal-note": "cNW",
  "with-internal-note": "cNu",
  "dropdown-content": "cvS",
  "select-item": "cvc"
};
