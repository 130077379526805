export default {
  "card-sidebar-header": "cUx",
  "content": "cUw",
  "header-type": "cUN",
  "close": "cUv",
  "close-icon": "cUo",
  "header-infos": "cUn",
  "title": "cUi body-1",
  "danger": "cUA",
  "success": "cUY",
  "warning": "cUh",
  "date": "cUf body-2",
  "card-image-container": "cUK",
  "card-image": "cUG",
  "info-container": "cUr",
  "spending-reason": "cUb",
  "spending-reason-label": "cUy body-2 mr-8",
  "spending-reason-note": "cUU body-2"
};
