export default {
  "sections": "qqL",
  "actions": "qqT",
  "search-input": "qqz",
  "no-upload": "qqa",
  "new-link": "qqH body-2",
  "between-links": "qqO",
  "action-bar": "qqm",
  "empty-state": "qqp"
};
