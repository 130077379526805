/* import __COLOCATED_TEMPLATE__ from './searchable-list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SearchableListComponent extends Component {
  @service sentry;

  @tracked searchQuery;
  @tracked items = [];

  constructor() {
    super(...arguments);
    this.fetchItems();
  }

  get error() {
    return this.fetchItemsTask.last?.error;
  }

  get isLoading() {
    return this.fetchItemsTask.isRunning;
  }

  @action
  updateQuery(searchQuery) {
    this.searchQuery = searchQuery;
    return this.fetchItems();
  }

  @action
  fetchItems() {
    this.fetchItemsTask
      .perform(this.searchQuery)
      .catch(ignoreCancelation)
      .catch(error => {
        // We don't need a toast here because the error is handled in the template
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  fetchItemsTask = restartableTask(async (searchQuery = '') => {
    this.items = await this.args.fetchItemsTask.linked().perform(searchQuery);
  });
}
