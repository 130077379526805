/* import __COLOCATED_TEMPLATE__ from './analytic-labels.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LabelCategorization } from 'qonto/react/components/transactions/table/labels/categorization/label-categorization';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TransactionsSidebarAnalyticLabelsComponent extends Component {
  @service categorizationRulesManager;

  @tracked labelIds = [
    ...this.allTransactionsLabels.map(label => ({
      labelListId: label.labelList.id,
      labelId: label.id,
    })),
  ];

  LabelCategorization = LabelCategorization;

  get transactions() {
    return this.args.transactions || [this.args.transaction];
  }

  get categorizationCriterias() {
    return this.transactions.map(transaction => ({
      rawCounterpartyName: transaction.rawCounterpartyName,
      counterpartyName: transaction.counterpartyName,
    }));
  }

  get allTransactionsLabels() {
    let labelsTransactions = [];
    this.transactions.forEach(transaction => {
      transaction.labels.forEach(label => labelsTransactions.push(label));
    });

    return labelsTransactions;
  }

  @action
  onSelectLabel(labelList, label) {
    this.labelIds = [
      ...this.labelIds,
      {
        labelListId: labelList.id,
        labelId: label?.id,
      },
    ];

    this.args.saveLabel(labelList, label);
  }

  @action
  onGenerateRules(rules) {
    if (this.args.onGenerateRules) {
      this.args.onGenerateRules(rules);
    } else {
      this.categorizationRulesManager.onCreateRulesTask.perform(rules).catch(ignoreCancelation);
    }
  }
}
