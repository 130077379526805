/* import __COLOCATED_TEMPLATE__ from './invoice-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { SENTRY_IGNORE_HTTP_STATUSES } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReceivableInvoicesInvoiceModalComponent extends Component {
  spinner = Spinner;

  @service router;
  @service organizationManager;
  @service intl;
  @service segment;
  @service modals;
  @service sentry;
  @service toastFlashMessages;

  @tracked showImportedEditForm = false;

  get isQuote() {
    return this.args.data.isQuote;
  }

  get isPdfPreviewWithNoToolbar() {
    return this.invoicingDocument?.pdfPreviewIframeUrl && this.args.data.isPdfPreviewWithNoToolbar;
  }

  get title() {
    if (this.isQuote) {
      let title = this.intl.t(
        'receivable-invoices.quote-creation.invoice-details.quote-number.label'
      );
      return title + `: ${this.invoicingDocument?.number}`;
    } else if (this.invoicingDocument?.status === 'draft') {
      if (this.numberingMode === 'automatic')
        return this.intl.t('receivable-invoices.invoice-modal.draft-title.automatic');

      return this.intl.t('receivable-invoices.invoice-modal.draft-title.manual', {
        invoiceNumber: this.invoicingDocument?.number,
      });
    }

    return this.intl.t('receivable-invoices.invoice-modal.title', {
      number: this.invoicingDocument?.number,
    });
  }

  get invoicingDocument() {
    if (this.isQuote) {
      return this.args.data.fetchTask.lastSuccessful?.value.quote;
    }
    return this.args.data.fetchTask.lastSuccessful?.value.invoice;
  }

  get settings() {
    return this.args.data.fetchSettingsTask.lastSuccessful?.value;
  }

  get numberingMode() {
    return this.settings?.numberingMode;
  }

  get logo() {
    let { isDefaultAvatar, picture } = this.organizationManager.organization;
    return isDefaultAvatar ? null : picture;
  }

  get duplicateTooltip() {
    if (this.invoicingDocument?.status === 'draft') {
      return this.intl.t('receivable-invoices.invoice-modal.actions.duplicate-draft.tooltip');
    }
    if (this.isQuote) {
      return this.args.data.isDuplicateDisabled
        ? this.intl.t(
            'receivable-invoices.invoice-modal.actions.duplicate-quote.basic-plan-tooltip'
          )
        : this.intl.t('receivable-invoices.invoice-modal.actions.duplicate-quote.tooltip');
    }
    return this.intl.t('receivable-invoices.invoice-modal.actions.duplicate-invoice.tooltip');
  }

  get downloadTooltip() {
    return this.isQuote
      ? this.intl.t('receivable-invoices.invoice-modal.actions.download-quote.tooltip')
      : this.invoicingDocument?.status === 'draft'
        ? this.intl.t('receivable-invoices.invoice-modal.actions.download-draft.tooltip')
        : this.intl.t('receivable-invoices.invoice-modal.actions.download-invoice.tooltip');
  }

  get isDeletable() {
    let deletableQuoteStatus = ['pending_approval', 'canceled'];
    if (
      (this.isQuote && deletableQuoteStatus.includes(this.invoicingDocument?.status)) ||
      this.invoicingDocument?.status === 'draft'
    ) {
      return true;
    }
  }

  @action toggleEditForm() {
    this.showImportedEditForm = !this.showImportedEditForm;
  }

  @action
  updateForm(form) {
    this.form = form;
  }

  handleClose = dropTask(async () => {
    if (this.showImportedEditForm && this.invoicingDocument.imported) {
      let result = await this.modals.open('popup/confirmation', {
        title: this.intl.t('receivable-invoices.edit.modal.save-modal.title'),
        description: this.intl.t('receivable-invoices.edit.modal.save-modal.description'),
        cancel: this.intl.t('btn.discard'),
        confirm: this.intl.t('btn.save_settings'),
      });

      if (result === 'confirm') {
        try {
          await this.form.submit();
          this.toastFlashMessages.toastSuccess(
            this.intl.t('receivable-invoices.edit.modal.success-toast.save-changes')
          );

          this.args.close();
        } catch (error) {
          this.handleError(error);
        }
      } else if (result === 'cancel') {
        this.args.close();
      }
    } else {
      this.args.close();
    }
  });

  @action duplicateDocument() {
    if (this.isQuote) {
      return this.duplicateQuote();
    }
    return this.duplicateInvoice();
  }

  @action duplicateInvoice() {
    this.segment.track('invoice_options_duplicate');
    this.router.transitionTo('receivable-invoices.duplicate', this.invoicingDocument.id);
  }

  @action duplicateQuote() {
    this.segment.track('quote_options_duplicate');
    this.router.transitionTo('quotes.duplicate', this.invoicingDocument.id);
  }

  @action openDeleteModal() {
    if (this.isQuote) {
      return this.openDeleteQuoteModal();
    }
    return this.openDeleteDraftModal();
  }

  @action openDeleteDraftModal() {
    this.segment.track('invoice-draft_options_delete');
    this.modals.open('receivable-invoices/delete-draft-modal', {
      title: this.intl.t('receivable-invoices.invoice-modal.delete-draft-modal.title'),
      description: this.intl.t('receivable-invoices.invoice-modal.delete-draft-modal.description', {
        invoiceNumber: this.invoicingDocument.number,
      }),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('receivable-invoices.invoice-modal.delete-draft-modal.delete'),
      confirmTask: this.deleteDraftTask,
    });
  }

  deleteDraftTask = dropTask(async closeModal => {
    try {
      await this.invoicingDocument.destroyRecord();
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  @action openDeleteQuoteModal() {
    this.segment.track('quote_options_delete');
    this.modals.open('receivable-invoices/delete-draft-modal', {
      title: this.intl.t('receivable-invoices.delete-quote.exit-modal.title'),
      description: this.intl.t('receivable-invoices.delete-quote.exit-modal.description'),
      cancel: this.intl.t('receivable-invoices.delete-quote.exit-modal.delete-quote-return'),
      confirm: this.intl.t('receivable-invoices.delete-quote.exit-modal.delete-quote-cta'),
      confirmTask: this.deleteQuoteTask,
    });
  }

  deleteQuoteTask = dropTask(async closeModal => {
    this.segment.track('quote_delete_confirmed');
    try {
      await this.invoicingDocument.destroyRecord();
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal();
      this.router.transitionTo('quotes.index');
    }
  });

  @action
  handleDownload() {
    if (this.isQuote) {
      this.segment.track('quote_options_download');
    } else {
      this.segment.track('invoice_options_download');
    }
  }

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
