export default {
  "banner": "cVZ",
  "content": "cVR",
  "title-row": "cVQ",
  "title": "cVe title-3",
  "badge": "cVB",
  "text": "cVE body-2",
  "missing-info-disclaimer": "cVd",
  "button": "cVI btn btn--primary",
  "spinner": "cVt",
  "image": "cVM"
};
