/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { STATUS } from 'qonto/constants/transfers';

export default class RequestSidebarCardDetailsComponent extends Component {
  @service localeManager;
  @service abilities;

  CARD_LEVELS = CARD_LEVELS;

  get showRequesterDisclaimer() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    return this.abilities.can('cancel card request', userId) && request.status === STATUS.PENDING;
  }

  get expirationDate() {
    let { expiry } = this.args;

    if (!expiry) {
      return null;
    }

    let preExpiresAt = new Date(expiry);

    return dateToken({
      date: preExpiresAt,
      token: DATE_FORMAT_TOKENS.DATE_TIME_S,
      locale: this.localeManager.locale,
    });
  }
}
